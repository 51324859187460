import { UUID } from "crypto";

import {
  AccountUser,
  CompanyData,
  CreditUseSelected,
  LoanApplication,
  PersonalData,
  ShareHolderData,
} from "@interfaces/store";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type RegisterScreen = "solicitud" | "crear_cuenta" | "datos_personales" | "validacion_telefono";

export interface RegisterState {
  application: LoanApplication;
  account_user: AccountUser;
  personal_data: PersonalData;
  company: CompanyData;
  credit_use: CreditUseSelected;
  shareholder: ShareHolderData;
}

const initialState: RegisterState = {
  application: {
    id: "00000000-0000-0000-0000-000000000000",
    type: "",
    loan_amount: 0,
    loan_term: 0,
    amount_monthly: 0,
    tax_system_id: undefined,
    product_id: undefined,
    id_application: 0,
    approved_amount: 0,
    approved_term: 0,
    interest_rate: 0,
    application_condition_id: "",
    client_details_id: "00000000-0000-0000-0000-000000000000",
    completed: false
  },
  account_user: {
    id: 0,
    email: "",
    password: "",
  },
  personal_data: {
    id: "00000000-0000-0000-0000-000000000000",
    name: "",
    first_lastname: "",
    second_lastname: "",
    country_code: "",
    phone: "",
    person_type_id: "",
    rol_detail: "",
    addressId: ""
  },
  company: {
    rfc: "",
    name: "",
    legal_name: "",
    role: "",
    ciec_pass: "",
    authorize_sat: false,
  },
  credit_use: {
    selected: [],
    reason: "",
  },
  shareholder: {
    id: "",
    name: "",
    first_lastname: "",
    second_lastname: "",
    date_birth: "",
    birth_state: "",
    sex: "",
    email: "",
    rfc: "",
    rfc_homoclave: "",
    curp: "",
    addressId: "",
    person_type: {
      id: "0-0-0-0-0",
      code: ""
    },
    // validation_data: false,
    bureau_inquiry: {
      prestamo_hipotecario: false,
      credito_auto: false,
      tarjeta_credito: false,
      tarjeta: "",
      autorizacion_buro: false,
    },
  },
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setTypePerson: (state, action: PayloadAction<string>) => {
      state.application.type = action.payload;
    },
    setConditionLoan: (
      state,
      action: PayloadAction<{ loanTerm: number; loanAmount: number; amountMonthly: number }>,
    ) => {
      state.application.loan_term = action.payload.loanTerm;
      state.application.loan_amount = action.payload.loanAmount;
      state.application.amount_monthly = action.payload.amountMonthly;
    },
    setNewConditionLoan: (
      state,
      action: PayloadAction<{ loanTerm: number; loanAmount: number; amountMonthly: number, approved_amount: number, approved_term: number, interest_rate: number }>,
    ) => {
      state.application.loan_term = action.payload.loanTerm;
      state.application.loan_amount = action.payload.loanAmount;
      state.application.amount_monthly = action.payload.amountMonthly;
      state.application.approved_amount = action.payload.approved_amount;
      state.application.approved_term = action.payload.approved_term;
      state.application.interest_rate = action.payload.interest_rate;
    },
    setLoanId: (state, action: PayloadAction<string>) => {
      state.application.id = action.payload;
    },
    setClientDetailsId: (state, action: PayloadAction<string>) => {
      state.application.client_details_id = action.payload;
    },
    setTaxProductId: (state, action: PayloadAction<{ tax_system_id: UUID; product_id: UUID }>) => {
      state.application.tax_system_id = action.payload.tax_system_id;
      state.application.product_id = action.payload.product_id;
    },
    setLoanApplication: (state, action: PayloadAction<LoanApplication>) => {
      state.application = action.payload;
    },
    setId_application: (state, action: PayloadAction<number>) => {
      state.application.id_application = action.payload;
    },
    set_Id_applicationCondition: (state, action: PayloadAction<string>) => {
      state.application.application_condition_id = action.payload;
    },
    setAccountUser: (state, action: PayloadAction<RegisterState["account_user"]>) => {
      state.account_user.id = action.payload.id;
      state.account_user.email = action.payload.email;
      state.account_user.password = action.payload.password;
    },
    setAccountUserId: (state, action: PayloadAction<RegisterState["account_user"]["id"]>) => {
      state.account_user.id = action.payload;
    },
    setPersonalData: (state, action: PayloadAction<RegisterState["personal_data"]>) => {
      state.personal_data.id = action.payload.id;
      state.personal_data.name = action.payload.name;
      state.personal_data.first_lastname = action.payload.first_lastname;
      state.personal_data.second_lastname = action.payload.second_lastname;
      state.personal_data.country_code = action.payload.country_code;
      state.personal_data.phone = action.payload.phone;
      state.personal_data.person_type_id = action.payload.person_type_id;
      state.personal_data.rol_detail = action.payload.rol_detail;
    },
    setPersonalDataIdAddress: (state, action: PayloadAction<{ id_address: string }>) => {
      state.personal_data.addressId = action.payload.id_address;

    },
    setPersonalNewPhone: (state, action: PayloadAction<{ phone: string, country_code: string }>) => {
      state.personal_data.phone = action.payload.phone;
      state.personal_data.country_code = action.payload.country_code;

    },
    setCompanyData: (state, action: PayloadAction<RegisterState["company"]>) => {
      state.company.rfc = action.payload.rfc;
      state.company.name = action.payload.name;
      state.company.legal_name = action.payload.legal_name;
    },
    setShareHolderData: (state, action: PayloadAction<RegisterState["shareholder"]>) => {
      state.shareholder.id = action.payload.id;
      state.shareholder.name = action.payload.name;
      state.shareholder.first_lastname = action.payload.first_lastname;
      state.shareholder.second_lastname = action.payload.second_lastname;
      state.shareholder.date_birth = action.payload.date_birth;
      state.shareholder.birth_state = action.payload.birth_state;
      state.shareholder.sex = action.payload.sex;
      state.shareholder.email = action.payload.email;
      state.shareholder.rfc = action.payload.rfc;

      state.shareholder.email = action.payload.email;
      state.shareholder.person_type = action.payload.person_type;


      const rfcPattern = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/;
      const fullRfc = action.payload.rfc;

      if (rfcPattern.test(fullRfc)) {
        state.shareholder.rfc = fullRfc.slice(0, 10);
        state.shareholder.rfc_homoclave = fullRfc.slice(10);
      } else {

        state.shareholder.rfc = fullRfc;
        state.shareholder.rfc_homoclave = action.payload.rfc_homoclave || '';
      }

    },
    setShareHolderRfcCurp: (state, action: PayloadAction<{ rfc: string; rfc_homoclave: string; curp: string }>) => {
      state.shareholder.rfc = action.payload.rfc;
      state.shareholder.rfc_homoclave = action.payload.rfc_homoclave;
      state.shareholder.curp = action.payload.curp;
    },
    setShareHolderDataBureauInquiry: (state, action: PayloadAction<RegisterState["shareholder"]["bureau_inquiry"]>) => {
      state.shareholder.bureau_inquiry.prestamo_hipotecario = action.payload.prestamo_hipotecario;
      state.shareholder.bureau_inquiry.credito_auto = action.payload.credito_auto;
      state.shareholder.bureau_inquiry.tarjeta_credito = action.payload.tarjeta_credito;
      state.shareholder.bureau_inquiry.tarjeta = action.payload.tarjeta;
      state.shareholder.bureau_inquiry.autorizacion_buro = action.payload.autorizacion_buro;
    },
    setShareHolderIdAddress: (state, action: PayloadAction<{ id_address: string }>) => {
      state.shareholder.addressId = action.payload.id_address;

    },
    updateApplicationCompleted: (state, action: PayloadAction<boolean>) => {
      state.application.completed = action.payload;
    },
  },
});

export const {
  setTypePerson,
  setConditionLoan,
  setLoanId,
  setClientDetailsId,
  setId_application,
  setLoanApplication,
  setAccountUser,
  setAccountUserId,
  setPersonalData,
  setCompanyData,
  setShareHolderData,
  setTaxProductId,
  setShareHolderRfcCurp,
  set_Id_applicationCondition,
  setShareHolderIdAddress,
  setPersonalDataIdAddress,
  updateApplicationCompleted,
  setNewConditionLoan,
  setPersonalNewPhone
} = registerSlice.actions;
export default registerSlice.reducer;
