import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useSelector } from "react-redux";

import { signOut } from "aws-amplify/auth";

import { RootState } from "@store/store";

import imgSpeed from "@assets/img/speed.svg";
import img from "@assets/img/img_tofu/img_tofu_6.svg";
import imgFinal from "@assets/img/img_tofu/img_tofu_19.svg";
import imgVisita from "@assets/img/img_tofu/img_tofu_20.svg";
import icon from "@assets/img/icon_rectangulo.svg";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { InactivityHandler } from "@components/InactivityHandler";

interface SalidaTemporalProps {
  type: "ValExp" | "ValDic" | "ConfDom" | "ConfBofuComp" | "ConfVisOcCon" | "ConfValVisOc";
}

export const SalidaTemporal = (props: SalidaTemporalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const userData = useSelector((state: RootState) => state.register.application.type);

  const getTitle = () => {
    if (props.type === "ConfDom") {
      return "Agendando tu visita ocular";
    } else if (props.type === "ConfVisOcCon") {
      return "Validando visita y preparando documentos contractuales";
    } else if (props.type === "ConfValVisOc") {
      return "Tu visita ocular fue validada exitosamente";
    } else if (props.type === "ConfBofuComp") {
      return "¡Felicidades!";
    }
  };

  const getSubtitle = () => {
    if (props.type === "ConfDom") {
      return "Gracias por confirmar tu domicilio, tu visista está en proceso de ser agendada";
    } else if (props.type === "ConfVisOcCon") {
      return "Tu visita concluyo, validaremos el resultado y prepararemos los documentos para firma, volveremos contigo a la brevedad";
    } else if (props.type === "ConfValVisOc") {
      return "¡Tu visita fue validada correctamente! Estamos preparando tus documentos para firma, volveremos contigo a la brevedad.";
    } else if (props.type === "ConfBofuComp") {
      return "Una vez que hayamos revisado que todo este correcto te depositaremos el dinero a tu cuenta, esto puede tardar hasta # horas";
    } else if (props.type === "ValDic") {
      return "¡Estamos validando tu dictamen, podrás continuar con tu proceso a la brevedad!";
    }
  };

  const handleOnClickNext = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          backgroundColor: props.type === "ConfBofuComp" ? "#002652" : "inherit",
        }}
      >
        <InactivityHandler />
        {props.type !== "ValExp" && (
          <>
            <Typography
              mt={5}
              px={2}
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              color={props.type === "ConfBofuComp" ? "#fff" : "#002652"}
              fontWeight={800}
            >
              {getTitle()}
            </Typography>

            {["ConfDom", "ConfVisOcCon", "ConfValVisOc"].includes(props.type) && (
              <NavBarOcularVisit
                currentStep={
                  props.type === "ConfDom"
                    ? 1
                    : props.type === "ConfVisOcCon"
                      ? 3
                      : props.type === "ConfValVisOc"
                        ? 4
                        : 0
                }
              />
            )}
            {props.type === "ConfBofuComp" ? (
              <>
                <Typography
                  px={5}
                  textAlign={"center"}
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color="#FFFFFF"
                  fontWeight={800}
                >
                  {personalData.name} {personalData.first_lastname} {personalData.second_lastname}
                </Typography>
                <Typography
                  mt={4}
                  px={5}
                  textAlign={"center"}
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  fontWeight={700}
                  color="#FFFFFF"
                >
                  {getSubtitle()}
                </Typography>
              </>
            ) : (
              <Typography
                mt={5}
                px={5}
                textAlign={"center"}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color="#528CD6"
                fontWeight={600}
              >
                {getSubtitle()}
              </Typography>
            )}
          </>
        )}
        <Box textAlign={"center"} mx={4}>
          {props.type !== "ConfDom" && (
            <Box mt={8} mb={7}>
              <img
                src={props.type === "ConfVisOcCon" ? imgVisita : props.type === "ConfBofuComp" ? imgFinal : imgSpeed}
                width={"25%"} height={"40%"}
              />
            </Box>
          )}
          {props.type == "ConfDom" && (
            <Box
              sx={{
                backgroundColor: "#EAF3FF", // Light blue background
                borderRadius: "10px",
                padding: "20px",
                maxWidth: "98%",
              }}
              mt={3}
            >
              <Typography fontWeight={600} color={"#002652"} fontSize={"14px"} align="center">
                Recuerda que el día de la visita deberás presentar los siguientes documentos:
              </Typography>
              <List>
                {(() => {
                  if (userData === import.meta.env.VITE_CODE_TAXS_CS_PM) {
                    return [
                      "Caratula",
                      "Acta constitutiva",
                      "Actas adicionales",
                      "Comprobante de domicilio operativo",
                      "Estados de cuenta bancarios",
                      "Identificación oficial vigente del representante legal",
                    ];
                  } else if (userData === import.meta.env.VITE_CODE_TAXS_CS_PFAE) {
                    return [
                      "Comprobante de domicilio operativo",
                      "Estados de cuenta bancarios",
                      "Identificación oficial vigente del representante legal",
                    ];
                  }
                  return [];
                })().map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon sx={{ minWidth: "25px" }}>
                      <img src={icon} alt="Document Icon" style={{ width: "15px", height: "15x" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={item}
                      sx={{
                        fontSize: "14px",
                        color: "#002652",
                        fontWeight: 300,
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {props.type === "ValExp" && (
            <>
              <Typography
                mb={3}
                px={2}
                textAlign={"center"}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color="#528CD6"
              >
                ¡Estamos validando tu expendiente, volveremos contigo a la brevedad!
              </Typography>
            </>
          )}

          {props.type !== "ConfBofuComp" ? (
            <>
              <Typography
                mt={4}
                px={2}
                textAlign={"center"}
                variant={isMobileOrTablet ? "body2" : "body1"}
                fontWeight={300}
                color="#002652"
              >
                ¡Despreocúpate!
              </Typography>

              <Typography
                px={5}
                textAlign={"center"}
                variant={isMobileOrTablet ? "body2" : "body1"}
                fontWeight={300}
                color="#002652"
              >
                Puedes salir de esta pantalla, tan pronto terminemos, te contactaremos via WhatsApp y puedes volver a
                iniciar sesión después
              </Typography>
            </>
          ) : (
            <Typography
              mt={4}
              px={5}
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={300}
              color="#528CD6"
            >
              Nos pondremos en contacto para validar que se realizó tu deposito
            </Typography>
          )}

          <Box mt={10} mb={3}>
            {["ValExp", "ValDic"].includes(props.type) && (
              <Button variant="default" onClick={handleOnClickNext} sx={{ minWidth: "40%" }}>
                Salir
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
