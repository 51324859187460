/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from "@components/index";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PHONE_NUMBER } from "@utils/expreciones";

import { useFormik } from "formik";
import * as yup from "yup";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { customSelect, customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";

import { useEffect, useState } from "react";
import { useLazyGetCivilStatusQuery, useLazyGetMatrimonialRegimesQuery } from "@api/catalogs";
import { CivilStatus, MatrimonialRegimesData, PayloadPersonPatch } from "@interfaces/index";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetPersonByIdQuery, usePatchPersonDataMutation } from "@api/personasApi";
import { useNavigate } from "react-router-dom";
import img from "@assets/img/img_tofu/img_tofu_17.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

const validationSchema = yup.object().shape({
  country_code: yup.string().required("Campo requerido"),
  phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
  ocupacion: yup.string().required("Campo requerido"),
  esado_civil: yup.string().required("Campo requerido"),
  matrimonial_regimes_id: yup.string().when("esado_civil", {
    is: "Casado",
    then: (schema) => schema.required("Campo requerido"),
  }),
});

export const InformacionAval = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [getEstadoCivil, { data: listEstado, isLoading }] = useLazyGetCivilStatusQuery();

  const [getEstadoMatrimonial, { data: listEstadoMtrimonial }] = useLazyGetMatrimonialRegimesQuery();

  const [getPersona, { data: dataPerson }] = useLazyGetPersonByIdQuery();
  const [estadoCivil, setEstadoCivil] = useState<CivilStatus[]>([]);
  const [estadoMatrimonila, setEstadoMatrimonila] = useState<MatrimonialRegimesData[]>([]);

  const [showCasado, setShowCasado] = useState(false);

  const [patchPersonData] = usePatchPersonDataMutation();

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const aval = useSelector((state: RootState) => state.register.shareholder);

  const initialValues = {
    ocupacion: "",
    country_code: "+52",
    phone: "",
    esado_civil: "",
    matrimonial_regimes_id: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        const selectedCivilStatus: any = estadoCivil.find((item) => item.name === values.esado_civil);
        const selectedMatrimonialRegime: any = estadoMatrimonila.find(
          (item) => item.name === values.matrimonial_regimes_id,
        );

        const dataAval: PayloadPersonPatch = {
          id: aval.id,
          occupation: values.ocupacion,
          code_phone_number: values.country_code,
          phone_number: values.phone,
          civil_status_id: String(selectedCivilStatus.id),
          matrimonial_regimes_id: selectedMatrimonialRegime ? String(selectedMatrimonialRegime.id) : null,
        };

        try {
          await patchPersonData(dataAval).unwrap();
          navigate("/producto/creditosimple/informacion_datos_aval_2");
        } catch (error: any) {
          if (error?.status === 500 || error?.status === 404 || error?.status === 502) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
            return;
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
            return;
          }
        }
      }
    },
  });

  const getPersonaById = async (value: string) => {
    try {
      await getPersona(value);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (listEstado) {
      setEstadoCivil(listEstado.data.data);
    }
  }, [listEstado]);

  useEffect(() => {
    if (listEstadoMtrimonial) {
      setEstadoMatrimonila(listEstadoMtrimonial);
    }
  }, [listEstadoMtrimonial]);

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      try {
        await getEstadoCivil();
        await getEstadoMatrimonial();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (aval.id) {
      getPersonaById(aval.id);
    }
  }, [aval.id]);

  useEffect(() => {
    if (
      dataPerson?.data.civil_status_id &&
      dataPerson?.data.code_phone_number &&
      dataPerson?.data.occupation &&
      dataPerson?.data.phone_number
    ) {
      const selectedCivilStatus = estadoCivil.find((item) => item.id === dataPerson.data.civil_status_id);

      formik.setFieldValue("ocupacion", dataPerson.data.occupation || "");
      formik.setFieldValue("country_code", dataPerson.data.code_phone_number || "");
      formik.setFieldValue("phone", dataPerson.data.phone_number || "");
      formik.setFieldValue("esado_civil", selectedCivilStatus?.name || "");

      const selectedMatrimonio = estadoMatrimonila.find((item) => item.id === dataPerson.data.matrimonial_regimes_id);
      if (selectedMatrimonio) {
        formik.setFieldValue("matrimonial_regimes_id", selectedMatrimonio?.name || "");
      } else {
        formik.setFieldValue("matrimonial_regimes_id", "");
      }
    }
  }, [dataPerson, listEstado, estadoCivil, estadoMatrimonila]);

  useEffect(() => {
    const selectedItem = estadoCivil.find((item) => item.name === formik.values.esado_civil);
    if (selectedItem && selectedItem.name === "Casado") {
      setShowCasado(true);
    } else {
      setShowCasado(false);
      formik.setFieldValue("matrimonial_regimes_id", "");
    }
  }, [formik.values.esado_civil, estadoCivil]);

  return (
    <>
      <Spinner open={loading} />
      <Grid container>
        {/* Form Section */}
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={1}>
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={800} color={"#002652"}>
                Información del aval
              </Typography>
            </Box>

            <Box my={1}>
              <Typography textAlign={"center"} fontSize={"14px"} fontWeight={400} color={"#528CD6"}>
                {aval.name + " " + aval.first_lastname + " " + aval.second_lastname}
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={4} sm={4} md={4}>
                    <CustomContryCode
                      label="Código"
                      name="country_code"
                      value={formik.values.country_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                    />
                  </Grid>

                  <Grid item xs={8} sm={8} md={8}>
                    <TextField
                      type="text"
                      name="phone"
                      variant="outlined"
                      label="Teléfono"
                      fullWidth
                      required
                      value={formik.values.phone}
                      onChange={(event) => {
                        formik.setFieldValue("phone", event.target.value.replace(/[^0-9]/g, ""));
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                </Grid>

                <TextField
                  id="ocupacion"
                  name="ocupacion"
                  label="Ocupación"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.ocupacion && Boolean(formik.errors.ocupacion)}
                  helperText={formik.touched.ocupacion && formik.errors.ocupacion}
                  value={formik.values.ocupacion}
                />

                <FormControl
                  fullWidth
                  required
                  sx={{
                    ...customStylesAsterisk,
                    ...customSelect,
                    mt: 2,
                  }}
                  error={formik.touched.esado_civil && Boolean(formik.errors.esado_civil)}
                >
                  <InputLabel id="esado_civil">Estado civil</InputLabel>
                  <Select
                    labelId="esado_civil"
                    id="esado_civil"
                    name="esado_civil"
                    fullWidth
                    required
                    label="Estado civil"
                    value={formik.values.esado_civil}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.esado_civil && Boolean(formik.errors.esado_civil)}
                    disabled={isLoading}
                  >
                    <MenuItem value={""}>Selecciona</MenuItem>
                    {estadoCivil.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.esado_civil && formik.errors.esado_civil && (
                    <FormHelperText>{formik.errors.esado_civil}</FormHelperText>
                  )}
                </FormControl>

                {showCasado && (
                  <FormControl
                    fullWidth
                    required
                    sx={{
                      ...customStylesAsterisk,
                      ...customSelect,
                      mt: 2,
                    }}
                    error={formik.touched.matrimonial_regimes_id && Boolean(formik.errors.matrimonial_regimes_id)}
                  >
                    <InputLabel id="matrimonial_regimes_id">Régimen matrimonial</InputLabel>
                    <Select
                      labelId="matrimonial_regimes_id"
                      id="matrimonial_regimes_id"
                      name="matrimonial_regimes_id"
                      fullWidth
                      required
                      label="Régimen matrimonial *"
                      value={formik.values.matrimonial_regimes_id}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.matrimonial_regimes_id && Boolean(formik.errors.matrimonial_regimes_id)}
                      disabled={isLoading}
                    >
                      <MenuItem value={""}>Selecciona</MenuItem>
                      {estadoMatrimonila.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.matrimonial_regimes_id && formik.errors.matrimonial_regimes_id && (
                      <FormHelperText>{formik.errors.matrimonial_regimes_id}</FormHelperText>
                    )}
                  </FormControl>
                )}

                <Grid container spacing={1} my={1}>
                  <Grid item xs={6} sm={6} md={6}>
                    <Button
                      variant="blue_outlined"
                      type="button"
                      fullWidth
                      onClick={() => {
                        navigate("/producto/creditosimple/docs_aval");
                      }}
                    >
                      Regresar al inicio
                    </Button>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Button variant="default" type="submit" fullWidth disabled={!formik.isValid || formik.isSubmitting}>
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
