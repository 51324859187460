import { getPersonByIdResponse, PatchResponse, PayloadPersonPatch } from "src/types";
import { baseApi } from "./baseApi";

const personasApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPersonById: build.query<getPersonByIdResponse, string>({
      query: (personId) => `personas/${personId}`,
    }),
    getPersonByTypePersonId: build.query<any, { personId: string; applicationId: string }>({
      query: ({ personId, applicationId }) =>
        `personas?q=loan_application_id:${applicationId}&q=person_type_id:${personId}`,
    }),
    patchPersonData: build.mutation<PatchResponse, PayloadPersonPatch>({
      query: (data) => ({
        url: `personas/${data.id}`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const { useGetPersonByIdQuery, useLazyGetPersonByIdQuery, usePatchPersonDataMutation, useLazyGetPersonByTypePersonIdQuery } = personasApi;
