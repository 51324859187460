/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { EMAIL, PHONE_NUMBER } from "@utils/expreciones";
import { customStylesAsterisk, customSelect } from "@components/customStylesInputs/inputsStyles";

import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import * as yup from "yup";
import img from "@assets/img/img_tofu/img_tofu_7.svg";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetReferenceTypesQuery, usePatchReferencesMutation, usePostReferencesMutation } from "@api/referenciasApi";
import { useGetClientsSuppliersByRfcQuery } from "@api/proveedorClienteApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { setPafeSupplierReference, setReferencePafeComplete } from "@store/slices/bofuSlicePfae";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { usePutApplicationCompletedMutation } from "@api/applications";
import { Spinner } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";

interface Referencia {
  country_code: string;
  phone: string;
  email: string;
  proveedor: string;
}

const validationSchema = yup.object().shape({
  referencia: yup.array().of(
    yup.object().shape({
      country_code: yup.string().required("Campo requerido"),
      phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
      proveedor: yup.string().required("Campo requerido"),
      email: yup
        .string()
        .required("Campo requerido")
        .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
    }),
  ),
});

export const ReferenciasProveedoresPfae = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const proveedorReferences = useGetClientsSuppliersByRfcQuery("KIJ0906199R2");
  const proveedorReferenceType = useGetReferenceTypesQuery();

  const [typeReference, setTypeReference] = useState("");

  const [hasReferences, setHasReferences] = useState(false);

  const [postReferencias, { isLoading }] = usePostReferencesMutation();
  const [triggerPatchReferences] = usePatchReferencesMutation();

  const applicationData = useSelector((state: RootState) => state.register.personal_data);
  const pfaeBofu = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);
  const applicationSol = useSelector((state: RootState) => state.register.application);

  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();
  const dispatch = useDispatch();

  const initialValues = {
    referencia: [
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
      {
        country_code: "+52",
        phone: "",
        email: "",
        proveedor: "",
      },
    ],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        const [hasDuplicates, duplicateIndex] = findDuplicateByProperty(values.referencia, "proveedor");

        if (hasDuplicates && duplicateIndex !== null) {
          const errors = values.referencia.reduce(
            (acc, _, index) => {
              if (index === duplicateIndex) {
                acc.referencia = acc.referencia || [];
                acc.referencia[index] = {
                  proveedor: "Este cliente ya ha sido seleccionado",
                };
              }
              return acc;
            },
            { referencia: [] as Array<{ proveedor?: string }> },
          );
          formik.setErrors(errors);
          return;
        }

        values.referencia.forEach(async (reference, index) => {
          try {
            let response = undefined;
            const referenceData = {
              client_details_id: applicationData.id,
              email: reference.email,
              id_ext: "3333",
              phone_number: reference.phone,
              reference_type_id: typeReference,
              code_phone_number: reference.country_code,
              supplier_name: reference.proveedor,
            };
            if (hasReferences) {
              response = await triggerPatchReferences({
                referenceId: String(pfaeBofu.references.clients.at(index)?.id),
                body: referenceData,
              }).unwrap();
            } else {
              response = await postReferencias(referenceData).unwrap();
              dispatch(setReferencePafeComplete({ complete: true }));
            }

            dispatch(
              setPafeSupplierReference({
                clientNumber: index,
                reference: {
                  id: response.data.id,
                  client_id: reference.proveedor,
                  email: reference.email,
                  code_phone: reference.country_code,
                  phone_number: reference.phone,
                },
              }),
            );
          } catch (error: any) {
            if (error?.status === 500 && error?.status === 409) {
              dispatch(setModalErrosVisible({ open: true, type: "500" }));
              return;
            } else {
              dispatch(setModalErrosVisible({ open: true, type: "" }));
              return;
            }
          }
        });

        try {
          const data = {
            references_completed: true,
          };

          await putAapplicationCompleted({ id_sol: applicationSol.id, body: data });
        } catch (error: any) {
          if (error?.status === 500 && error?.status === 409) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
            return;
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
            return;
          }
        }
        window.location.href = '/producto/creditosimple/avance_expediente_digitalPFAE';
      }
    },
  });

  const onChangeEmail = (index: number, value: string) => {
    const low = value.toLowerCase();
    formik.setFieldValue(`referencia[${index}].email`, low);
  };

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.referencia?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  const getOrdinal = (index: number) => {
    const ordinals = ["Primera", "Segunda", "Tercera", "Cuarta", "Quinta"];
    return ordinals[index] || `${index + 1}`;
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (proveedorReferenceType.data) {
      const filteredItem = proveedorReferenceType.data.find((item) => item.code === "CRTPR");
      setTypeReference(String(filteredItem?.id));
    }
  }, [proveedorReferenceType.data]);

  useEffect(() => {
    if (pfaeBofu.references) {
      if (pfaeBofu.references.suppliers.length === 0) {
        setHasReferences(false);
      } else {
        setHasReferences(true);
      }
    }
  }, [pfaeBofu.references]);

  const findDuplicateByProperty = (array: Referencia[], property: keyof Referencia): [boolean, number | null] => {
    const seen = new Map<any, number>();
    for (let i = 0; i < array.length; i++) {
      const value = array[i][property];
      if (seen.has(value)) {
        return [true, seen.get(value)!];
      }
      seen.set(value, i);
    }
    return [false, null];
  };

  useEffect(() => {
    if (proveedorReferenceType.isSuccess && pfaeBofu.references.suppliers.length !== 0) {
      formik.setFieldValue(
        "referencia",
        pfaeBofu.references.suppliers.map((item) => {
          return {
            country_code: item.code_phone,
            phone: item.phone_number,
            email: item.email,
            proveedor: item.client_id,
          };
        }),
      );
    }
  }, [proveedorReferences, pfaeBofu]);

  return (
    <>
      <Grid container>
        <Spinner open={proveedorReferences.isLoading} />
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={2}>
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={800} color={"#002652"}>
                Referencias
              </Typography>
            </Box>

            <Box my={2}>
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={400} color={"#002652"}>
                <span style={{ fontWeight: 800 }}>2</span> de 2
              </Typography>
            </Box>

            <Box my={1}>
              <Typography textAlign={"center"} fontSize={"14px"} fontWeight={600} color={"#528CD6"}>
                Ahora, escribe 3 referencias que sean tus proveedores, considera que podríamos contactarlos.
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                {formik.values.referencia.map((_, index) => (
                  <div key={index}>
                    <Box my={2} mb={2}>
                      <Typography textAlign={"left"} fontSize={"14px"} fontWeight={400} color={"#002652"}>
                        {getOrdinal(index)} referencia
                      </Typography>
                    </Box>
                    <FormControl
                      fullWidth
                      required
                      sx={{
                        ...customStylesAsterisk,
                        ...customSelect,
                        mt: 1,
                      }}
                      error={Boolean(
                        formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index),
                      )}
                    >
                      <InputLabel id={`${index}proveedor`}>Proveedores</InputLabel>
                      <Select
                        labelId="proveedor"
                        name={`referencia[${index}].proveedor`}
                        id={`proveedor${index}`}
                        fullWidth
                        required
                        label="Proveedores"
                        value={formik.values.referencia?.[index]?.proveedor}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index),
                        )}
                        disabled={isLoading}
                      >
                        <MenuItem value="">Selecciona</MenuItem>
                        {proveedorReferences.isSuccess &&
                          proveedorReferences?.data?.data?.data[0]?.data.suppliers
                            ?.filter(
                              (supplier) =>
                                !formik.values.referencia.some(
                                  (ref, refIndex) => refIndex !== index && ref.proveedor === supplier.rfc,
                                ),
                            ) // Excluir proveedores seleccionados en otros Select
                            .map((supplier) => (
                              <MenuItem key={supplier.rfc} value={supplier.rfc}>
                                {supplier.name.toUpperCase()}
                              </MenuItem>
                            ))}
                      </Select>

                      {formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index) && (
                        <FormHelperText>{getFieldError("proveedor", index)}</FormHelperText>
                      )}
                    </FormControl>

                    <TextField
                      name={`referencia[${index}].email`}
                      id={`email${index}`}
                      label="Correo electrónico"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => onChangeEmail(index, e.target.value)}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.touched.referencia?.[index]?.email && getFieldError("email", index))}
                      helperText={formik.touched.referencia?.[index]?.email && getFieldError("email", index)}
                      value={formik.values.referencia?.[index]?.email}
                      disabled={isLoading}
                    />

                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={4} sm={4} md={4}>
                        <CustomContryCode
                          label="Código"
                          name={`referencia[${index}].country_code`}
                          value={formik.values.referencia?.[index]?.country_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched.referencia?.[index]?.country_code && getFieldError("country_code", index),
                          )}
                        />
                      </Grid>

                      <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          name={`referencia[${index}].phone`}
                          id={`phone${index}`}
                          type="text"
                          variant="outlined"
                          label="Teléfono"
                          fullWidth
                          required
                          value={formik.values.referencia?.[index]?.phone}
                          disabled={isLoading}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `referencia[${index}].phone`,
                              event.target.value.replace(/[^0-9]/g, ""),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          error={Boolean(formik.touched.referencia?.[index]?.phone && getFieldError("phone", index))}
                          helperText={formik.touched.referencia?.[index]?.phone && getFieldError("phone", index)}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: 3,
                  }}
                >
                  <Button variant="default" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                    Continuar
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
