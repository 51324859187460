import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";

import img from "@assets/img/right_4.svg";
import icon from "@assets/img/icon_rectangulo.svg";
import { useLazyGetScheduleByApplicationIdQuery } from "@api/applications";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { InactivityHandler } from "@components/InactivityHandler";

export const ConfirmacionVisitaAgendada = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [getSchedule, { data: scheduleData, isLoading, error, isError }] = useLazyGetScheduleByApplicationIdQuery(); // Hook del servicio
  const [visitDate, setVisitDate] = useState<string | null>(null);
  const [visitTime, setVisitTime] = useState<string | null>(null);
  const [message, setMessage] = useState<string>("Cargando...");
  const applicationData = useSelector((state: RootState) => state.register.application.id);
  const userData = useSelector((state: RootState) => state.register.application.type);

  function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
    return typeof error === "object" && error !== null && "status" in error;
  }

  useEffect(() => {
    const solicitudId = applicationData;
    if (solicitudId) {
      getSchedule(solicitudId);
    }
  }, [getSchedule, applicationData]);

  useEffect(() => {
    if (scheduleData && scheduleData.data) {
      if (!scheduleData.data.data || scheduleData.data.data.length === 0) {
        setMessage("No se encontró ninguna visita programada.");
      } else if (scheduleData.data.data.length >= 0) {
        const schedule = scheduleData.data.data[0];
        if (schedule && schedule.date) {
          const date = new Date(schedule.date);
          console.log(date);
          const formattedDate = date.toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "long",
          });
          const formattedTime = date.toLocaleTimeString("es-ES", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZone: "UTC",
          });

          setVisitDate(formattedDate);
          setVisitTime(formattedTime);
        }
      }
    } else if (isError && isFetchBaseQueryError(error)) {
      console.log(error);
      if (error.status === 404) {
        setVisitDate(null);
        setVisitTime(null);
        setMessage("No se encontró ninguna visita programada.");
      } else {
        setVisitDate(null);
        setVisitTime(null);
        setMessage("Error al cargar los datos. Inténtalo de nuevo más tarde.");
      }
    } else if (isError) {
      setVisitDate(null);
      setVisitTime(null);
      setMessage("Error desconocido. Inténtalo de nuevo más tarde.");
    }
  }, [scheduleData, isError, error]);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <Box my={1}>
            <Typography align="center" fontWeight={600} fontSize={"15px"} color={"#528CD6"}>
              Te visitaremos el día
            </Typography>
          </Box>

          <Box my={3} width={"165px"} height={"65px"} sx={{ background: "#002652" }}>
            <Typography align="center" fontWeight={700} fontSize={"15px"} color={"#A3D4E8"} pt={1}>
              {isLoading ? "Cargando..." : visitDate ? visitDate : message} <br />
              {isLoading ? "" : visitTime ? visitTime : ""}
            </Typography>
          </Box>

          <Box my={2}>
            <Typography align="center" fontWeight={600} fontSize={"15px"} color={"#002652"}>
              Recibiras un recordatorio vía WhatsApp 1 hora antes de tu visita y cuando el ejecutivo de visitas se
              encuentre en el domicilio.
            </Typography>
          </Box>
          <Typography variant="body1" fontWeight={600} color="#528CD6" textAlign={"center"} py={4}>
            En caso de que necesites reagendar tu vista, contacta a tu asesor
          </Typography>
          {/* <Box my={2}>
            <Button variant="default" type="button" sx={{ fontSize: "13px", fontWeight: 700 }}>
              Solicitar cambio de fecha
            </Button>
          </Box> */}

          <Box
            sx={{
              backgroundColor: "#EAF3FF", // Light blue background
              borderRadius: "10px",
              padding: "20px",
              maxWidth: "98%",
            }}
            mt={3}
          >
            <Typography fontWeight={600} color={"#002652"} fontSize={"14px"} align="center">
              Recuerda que el día de la visita deberás presentar los siguientes documentos:
            </Typography>
            <List>
              {(() => {
                if (userData === import.meta.env.VITE_CODE_TAXS_CS_PM) {
                  return [
                    "Caratula",
                    "Acta constitutiva",
                    "Actas adicionales",
                    "Comprobante de domicilio operativo",
                    "Estados de cuenta bancarios",
                    "Identificación oficial vigente del representante legal",
                  ];
                } else if (userData === import.meta.env.VITE_CODE_TAXS_CS_PFAE) {
                  return [
                    "Comprobante de domicilio operativo",
                    "Estados de cuenta bancarios",
                    "Identificación oficial vigente del representante legal",
                  ];
                }
                return [];
              })().map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon sx={{ minWidth: "25px" }}>
                    <img src={icon} alt="Document Icon" style={{ width: "15px", height: "15x" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                    sx={{
                      fontSize: "14px",
                      color: "#002652",
                      fontWeight: 300,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
