import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
  Backdrop,
  Fade,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import img from "@assets/img/img_tofu/img_tofu_10.svg";
import { useLazyGetContractualDocumentLoanApplicationQuery, useGetUrlToDownloadDocMutation, usePatchContractualDocumentMutation, } from "@api/documents";
import { useGetFilesCatalogQuery } from "@api/catalogs"
import { usePostValidateNextStageMutation } from "@api/applications";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit"
import { setModalErrosVisible } from "@store/slices/appSlice";
import { url } from "inspector";
import { InactivityHandler } from "@components/InactivityHandler";



interface ViewerProps {
  url: string;
  type: 'pdf' | 'image' | 'unknown';
}
const getFileTypeFromUrl = (url: string): 'pdf' | 'image' | 'unknown' => {
  const extension = url.split('.').pop()?.toLowerCase(); // Extraer la extensión
  if (extension === 'pdf') {
    return 'pdf';
  } else if (['png', 'jpg', 'jpeg', 'gif'].includes(extension || '')) {
    return 'image';
  }
  else {
    return 'pdf';
  }
};

const Viewer: React.FC<ViewerProps> = ({ url, type }) => {
  if (type === 'pdf') {
    return (
      <iframe
        src={`${url}`}
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    );
  } else if (type === 'image') {
    return <img src={url} alt="Document preview" style={{ width: '100%', height: 'auto' }} />;
  } else {
    return <div>Tipo de archivo no compatible</div>;
  }
};

export const FirmaDocumentos: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [signedDocuments, setSignedDocuments] = useState<boolean[]>([]);
  const [allSigned, setAllSigned] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [pdfFile, setPdfFile] = useState<string>("");
  const [fileType, setFileType] = useState<'pdf' | 'image' | 'unknown'>('pdf');
  const [triggerPostNextStage] = usePostValidateNextStageMutation();


  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const [getDocumentByApplicationClient, { data: dataDocuments }] = useLazyGetContractualDocumentLoanApplicationQuery();
  const { data: fileCategoriesData, isLoading: isLoadingCategories, isError: isErrorCategories, error: errorCategories } = useGetFilesCatalogQuery();

  const [documents, setDocuments] = useState<{ name: string; url: string; documentId: string; signedClient: boolean }[]>([]);
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [patchDocument] = usePatchContractualDocumentMutation();
  const isErrorModalVisible = useSelector((state: RootState) => state.app.modal_error.visible)
  const navigate = useNavigate();


  const handleContinue = async () => {

    try {
      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: "CSVCF",
      }).unwrap();

      navigate("/producto/creditosimple/bofu_completado");
    } catch (error: any) {
      if (error?.status === 500 || error?.status === 404 || error?.status === 502) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
        return;
      }
    }
  };

  const handleCategoriesError = () => {
    if (isErrorCategories) {
      handleError(errorCategories);
    }
  };

  useEffect(() => {
    handleCategoriesError
  }, [isErrorCategories]);

  useEffect(() => {
    if (dataDocuments && fileCategoriesData) {
      const documentsWithCategoryNames = dataDocuments.map((document) => {
        const category = fileCategoriesData.find((cat) => cat.id === document.file_category_id);
        return {
          name: category ? category.name : "Categoría desconocida",
          url: document.url || "",
          documentId: document.id,
          signedClient: document.sign_client,
        };
      });
      setDocuments(documentsWithCategoryNames);


      const initialSignedDocuments = documentsWithCategoryNames.map((doc) => doc.signedClient);

      setSignedDocuments(initialSignedDocuments);


      if (initialSignedDocuments.every((doc) => doc)) {
        setAllSigned(true);
      }

    }
  }, [dataDocuments, fileCategoriesData]);

  useEffect(() => {
    if (application.id && clientDetails.id) {
      try {
        getDocumentByApplicationClient({
          loanApplicationId: application.id,
          clientId: clientDetails.id,
        });
      }
      catch (error) {
        handleError(error)

      }
    }
  }, [application.id, clientDetails.id]);


  const handleError = (error: any) => {
    if (!isErrorModalVisible) {
      if (error?.status === 500 || error?.status === 400 || error?.status === 502) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };


  const handleSign = async (index: number) => {

    const document = documents[index];
    try {

      await patchDocument({
        documentId: document.documentId as `${string}-${string}-${string}-${string}-${string}`,
        body: { sign_client: true },
      }).unwrap();
      const newSignedDocuments = [...signedDocuments];
      newSignedDocuments[index] = true;
      setSignedDocuments(newSignedDocuments);
      if (newSignedDocuments.every((doc) => doc)) {
        setAllSigned(true);
      }
    } catch (error: any) {
      handleError(error)
    }
  }


  const handleSignAll = async () => {
    try {
      await Promise.all(
        documents.map((doc) =>
          patchDocument({
            documentId: doc.documentId as `${string}-${string}-${string}-${string}-${string}`,
            body: { sign_client: true },
          })
        )
      );

      setSignedDocuments(Array(documents.length).fill(true));
      setAllSigned(true);
    } catch (error: any) {
      if (error?.status === 500 || error?.status === 404 || error?.status === 502) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
        return;
      }
    }
  };

  const handleOpenModal = async (fileKey: string) => {
    try {
      const result = await triggerPostUrlDocument(fileKey);
      if (result.data) {
        const fileType = getFileTypeFromUrl(result.data);
        setPdfFile(result.data);
        setFileType(fileType);
        setOpenModal(true);
      }
    } catch (error) {
      console.error("Error al generar la URL del documento:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  useEffect(() => {
    if (application.id) {
      getDocumentByApplicationClient({ loanApplicationId: application.id, clientId: clientDetails.id });
    }
  }, [application]);


  return (
    <Grid container sx={{ height: '100vh' }}  >
      <Grid item xs={12} md={8} >
        <InactivityHandler />
        <NavBarOcularVisit currentStep={5} />
        <Box textAlign={"center"} mx={4}>
          <Typography color="#002652" mt={4} mb={3} variant={isMobileOrTablet ? "h5" : "h4"} fontWeight="bold">
            Tus documentos están listos para firmar
          </Typography>
          <Typography variant={isMobileOrTablet ? "h5" : "h4"} color="#528CD6" sx={{ fontSize: "14px", fontWeight: 300, mb: 2 }}>
            En caso de que tengas alguna duda no dudes en contactar a tu asesor
          </Typography>
          <Grid container columnSpacing={1} my={4} justifyContent="center">
            <Grid item xs="auto" sm="auto" md="auto" >
              <Button
                variant="outlined"
                onClick={handleSignAll}
                sx={{
                  mb: 2,
                  textTransform: "none",
                  padding: "2px 12px",
                  fontSize: "12px",
                  minWidth: "100px",
                  maxWidth: "150px",
                  height: "30px",
                  borderColor: "#002652",
                  color: "#002652",
                  "&:hover": { backgroundColor: "#002652", color: "#FFFFFF" },
                }}
              >
                Firmar todo
              </Button>
            </Grid>
          </Grid>
          {documents.map((doc, index) => (
            <Box key={index} display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
              <Box textAlign="left" sx={{ flex: 1 }}>
                <Typography fontWeight="bold" variant="body1" sx={{ fontSize: "13px", color: "#002652", fontWeight: "bold" }}>
                  {doc.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", color: "#528CD6", textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => handleOpenModal(doc.url)}
                >
                  Ver documento
                </Typography>
              </Box>
              <Box textAlign="center">
                <Button
                  variant="default"
                  color="primary"
                  size="small"
                  disabled={signedDocuments[index]}
                  onClick={() => handleSign(index)}
                  sx={{
                    textTransform: "none",
                    width: "120px", // Ancho del botón "Firmar"
                    padding: "8px 18px", // Padding más amplio para alargar el botón
                    backgroundColor: signedDocuments[index] ? "#002652" : "#528CD6", // Cambiar el color de fondo
                    color: signedDocuments[index] ? "#A3D4E8" : "#FFFFFF", // Cambiar el color del texto
                    // Remover el comportamiento predeterminado de opacidad cuando el botón está deshabilitado
                    "&.Mui-disabled": {
                      backgroundColor: "#002652", // Fondo del botón deshabilitado (firmado)
                      color: "#A3D4E8", // Color del texto cuando el botón está deshabilitado
                      opacity: 1, // Forzar que el botón no sea transparente
                    },
                    "&:hover": {
                      backgroundColor: signedDocuments[index] ? "#002652" : "#528CD6", // Mantener el color al hacer hover
                    },
                  }}

                >
                  {signedDocuments[index] ? "Firmado" : "Firmar"}
                </Button>
                <Box sx={{ mt: 1, height: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {signedDocuments[index] ? (
                    <CheckIcon color="success" sx={{ fontSize: "20px" }} />
                  ) : (
                    <Typography variant="body2" sx={{ fontSize: "13px", color: "#F2704F" }}>
                      Pendiente
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
          <Grid container columnSpacing={1} my={4} justifyContent="center">
            <Grid item xs={6} sm={4} md={3} >
              <Button
                variant="default"
                type="submit"
                disabled={!allSigned}
                sx={{ mt: 2, padding: "12px", width: "100%", textTransform: "none" }}

                onClick={handleContinue}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {
        !isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%"
                }}
              />
            </Box>
          </Grid>
        )
      }

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}></Typography>
            <Box sx={{ height: "calc(100% - 40px)" }}>
              <Viewer url={pdfFile} type={fileType} />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Grid >
  );
};
