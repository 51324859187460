import { ConditionResponse } from "@interfaces/response";
import { baseApi } from "./baseApi";
import { getCondicionesBySolicitudIdResponse, PayloadCondition, PayloadCotizacionPut } from "@interfaces/condiciones";

const condicionesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCondicionesBySolicitudId: build.query<getCondicionesBySolicitudIdResponse, string>({
      query: (soldId) => `solicitudes/${soldId}/condiciones`,
    }),
    putCondicionData: build.mutation<getCondicionesBySolicitudIdResponse, PayloadCotizacionPut>({
      query: ({ solicitudId, condicionesId, data }) => ({
        url: `solicitudes/${solicitudId}/condiciones/${condicionesId}`,
        method: "PUT",
        body: data,
      }),
    }),
    postCondition: build.mutation<ConditionResponse, PayloadCondition>({
        query: ({loan_application, loan_amount, requested_term, approved_amount ,approved_term, interest_rate}) => ({
            url: `solicitudes/${loan_application}/condiciones`,
            method: 'POST',
            body: {
                "loan_amount": loan_amount,
                "requested_term": requested_term,
                "approved_amount": approved_amount,
                "approved_term": approved_term,
                "interest_rate": interest_rate
            }
        })
    })
  }),
});

export const { useGetCondicionesBySolicitudIdQuery , usePutCondicionDataMutation, usePostConditionMutation} = condicionesApi;
