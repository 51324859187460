import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import img from "@assets/img/icon_lendia.svg";

import imgError500 from "@assets/img/icon_error_500.svg";
import imgErrorNet from "@assets/img/icon_error_network.svg";
import CloseIcon from "@mui/icons-material/Close";
import { setModalErrosVisible } from "@store/slices/appSlice";

export const ModalErrors = () => {
  const theme = useTheme();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [errorMessageTitle, setErrorMessageTitle] = useState("");
  const [errorMessageBody, setErrorMessageBody] = useState("");
  const [errorMessageFooter, setErrorMessageFooter] = useState("");
  const [errorImg, setErrorImg] = useState("");

  const [hasButton, setHasButton] = useState(false);

  const app = useSelector((state: RootState) => state.app);

  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    switch (app.modal_error?.type) {
      case "500":
        setErrorMessageTitle("Una disculpa, algo salió mal");
        setErrorMessageBody("Parece que nuestro servidor no responde, seguramente el equipo ya lo esta resolviendo");
        setErrorMessageFooter("Inténtalo nuevamente más tarde");
        setErrorImg(imgError500);
        setHasButton(false);
        break;
      default:
        setErrorMessageTitle("¡Ups!");
        setErrorMessageBody("Parece que tu conexión a internet  está fallando");
        setErrorMessageFooter("Verifica tu conexión Wi-Fi o intenta recargar la página");
        setErrorImg(imgErrorNet);
        setHasButton(false);
    }
  }, [app.modal_error?.type]);

  useEffect(() => {
    setOpen(app.modal_error.visible);
  }, [app.modal_error.visible]);

  const handleClose = () => {
    setOpen(false);
    dispatch(setModalErrosVisible({ open: false, type: "" }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobileOrTablet}
      maxWidth={false}
      sx={{
        "& .MuiDialog-paper": {
          margin: "20px",
          width: isMobileOrTablet ? "100%" : "90%",
          height: isMobileOrTablet ? "90%" : "100%",
          maxHeight: "calc(100% - 40px)",
          backgroundColor: "#E3F2F8",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle>
        {!hasButton && (
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: "20px",
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <img src={img} alt="logo" />
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography color={"#002652"} fontSize={"31px"} fontWeight={300}>
          {errorMessageTitle}
        </Typography>

        <Typography color={"#002652"} fontSize={"20px"} fontWeight={300} mt={2}>
          {errorMessageBody}
        </Typography>

        <Box mt={2} mb={3} sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          {errorImg && <img src={errorImg} alt="Error" />}
        </Box>

        <Typography color={"#002652"} fontSize={"20px"} fontWeight={300} mt={2}>
          {errorMessageFooter}
        </Typography>

      </DialogContent>
    </Dialog>
  );
};
