import { mockModeloResponse, mockModeloPayLoad } from "@interfaces/mockModelo";
import { baseApi } from "./baseApi";

const mockModeloApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postModeloMock: build.mutation<mockModeloResponse, mockModeloPayLoad>({
      query: (data: mockModeloPayLoad) => ({
        url: `modelo`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  usePostModeloMockMutation
} = mockModeloApi;
