import { Box, BoxProps, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export interface CustomCheckListProps extends BoxProps {
  title: string;
  items: Array<string>;
  onClickInfo?: (id:string | undefined) => void;
}

export const CustomCheckList = (props: CustomCheckListProps) => {
  return (
    <Box
      sx={{ backgroundColor: "#E3F2F8", borderRadius: "8px", ...props.sx }}
      px={2}
      py={1}
      display={"flex"}
      flexDirection={"column"}
    >
      <Typography textAlign={"left"} variant="body2" fontWeight={600}>
        {props.title}
        {props.onClickInfo && (
          <IconButton
            sx={{ margin: "-5px 2px 0px 2px", padding: 0 }}
            onClick={() => props.onClickInfo && props.onClickInfo(props.id)}
          >
            <InfoOutlinedIcon color={"error"} sx={{ width: 15, height: 15 }} />
          </IconButton>
        )}
      </Typography>
      <List>
        {props.items.map((item, index) => {
          return (
            <ListItem key={index} sx={{ p: 0 }}>
              <ListItemIcon sx={{ minWidth: "15px" }}>
                <Box width={8} height={8} sx={{ backgroundColor: "#528CD6" }}></Box>
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2">{item}</Typography>} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
