import { Link, Typography } from "@mui/material";

export const TerminosCondiciones = () => {
  return (
    <Typography textAlign={"center"}>
      Al continuar estas aceptando nuestros{" "}
      <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
        <Typography component={"span"}>términos y condiciones</Typography>
      </Link>{" "}
      y{" "}
      <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
        <Typography component={"span"}>políticas de privacidad</Typography>
      </Link>
    </Typography>
    // <>
    //   <p className="text-cuenta text-center">
    //     Al continuar estas aceptando nuestros{" "}
    //     <a className="terminos-condiciones-cuenta" href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
    //       términos y condiciones
    //     </a>{" "}
    //     y <br />
    //     <a className="terminos-condiciones-cuenta" href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
    //       {" "}
    //       políticas de privacidad.
    //     </a>
    //   </p>
    // </>
  );
};
