/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  Container,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as yup from "yup";
import dayjs from "dayjs";
import "dayjs/locale/es";

import { RootState, useAppDispatch } from "@store/store";
import { setShareHolderData } from "@store/slices/registerSlice";
import { useGetPersonTypeQuery, useLazyGetStatesQuery } from "@api/catalogs";
import { useSaveShareholderDataMutation } from "@api/onboarding";

import { EMAIL } from "@utils/expreciones";
import { ProgressBar, Spinner } from "@components/index";
import { capitalizeFirstLetter, sinCarcteresEspeciales } from "@helpers/index";
import { CatalogStates, PayloadSaveShareholderData, ResponseSaveShareholderData } from "@interfaces/index";
import { usePostValidateNextStageMutation } from "@api/applications";

import img from "@assets/img/right_9.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

interface FormValues {
  majority_shareholder: boolean;
  name: string;
  first_lastname: string;
  second_lastname: string;
  date_birth: string;
  birth_state: string;
  email: string;
  sex: string;
}

export const DatosAccionista = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const application = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const shareholderData = useSelector((state: RootState) => state.register.shareholder);
  const accountUserData = useSelector((state: RootState) => state.register.account_user);

  const dispatch = useAppDispatch();
  const [getStatesTrigger] = useLazyGetStatesQuery();
  const [saveShareholderData] = useSaveShareholderDataMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [personTypeId, setPersonTypeId] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialValues] = useState<FormValues>({
    majority_shareholder: false,
    name: shareholderData.name || "",
    first_lastname: shareholderData.first_lastname || "",
    second_lastname: shareholderData.second_lastname || "",
    date_birth: "",
    birth_state: shareholderData.birth_state || "",
    email: shareholderData.email || "",
    sex: shareholderData.sex || "",
  });
  const [placesCatalog, setPlacesCatalog] = useState<CatalogStates[]>([]);

  const { data: personTypeData, isSuccess: typePersonSucess } = useGetPersonTypeQuery();

  const validationSchema = yup.object().shape({
    name: yup.string().required("Campo requerido"),
    first_lastname: yup.string().required("Campo requerido"),
    second_lastname: yup.string(),
    date_birth: yup.string().required("Campo requerido"),
    birth_state: yup.string().required("Campo requerido"),
    email: yup
      .string()
      .required("Campo requerido")
      .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
    sex: yup.string().oneOf(["Masculino", "Femenino"], "Debe seleccionar un genero").required("Campo requerido"),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);

      const payloadShareholderData: PayloadSaveShareholderData = {
        person_type_id: personTypeId,
        name: values.name,
        last_name: values.first_lastname,
        last_name_2: values.second_lastname,
        birth_date: dayjs(values.date_birth).format("YYYY-MM-DD"),
        birth_state: Number(values.birth_state),
        email: values.email,
        sex: values.sex === "Masculino" ? "MASCULINO" : "FEMENINO",
        rfc: "",
        curp: "",
        loan_application_id: application.id,
      };
      const responseShareholderData: ResponseSaveShareholderData = await saveShareholderData(
        payloadShareholderData,
      ).unwrap();

      dispatch(
        setShareHolderData({
          id: responseShareholderData.data.id,
          name: values.name,
          first_lastname: values.first_lastname,
          second_lastname: values.second_lastname,
          date_birth: values.date_birth,
          birth_state: values.birth_state,
          email: values.email,
          sex: values.sex,
          rfc: "",
          rfc_homoclave: "",
          curp: "",
          bureau_inquiry: {
            prestamo_hipotecario: false,
            credito_auto: false,
            tarjeta_credito: false,
            tarjeta: "",
            autorizacion_buro: false,
          },
        }),
      );

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: "CSDAL",
      }).unwrap();

      navigate("/producto/creditosimple/rfc_curp");
    } catch (error: any) {
      console.log(error);
      if (error?.status === 400 || error?.status === 500 || error?.status === 409) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (personTypeData) {
      const filteredItem = personTypeData.find((item) => item.code === "PTAM");
      setPersonTypeId(String(filteredItem?.id));
    }
  }, [personTypeData, typePersonSucess]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const onChangeNames = (value: string, input: string) => {
    const capiatal = capitalizeFirstLetter(value);
    const siCaracteresEspeciales = sinCarcteresEspeciales(capiatal);
    formik.setFieldValue(`${input}`, siCaracteresEspeciales);
  };

  const handleMajorityShareholderChange = (checked: boolean) => {
    if (checked) {
      formik.setFieldValue("name", personalData.name);
      formik.setFieldValue("first_lastname", personalData.first_lastname);
      formik.setFieldValue("second_lastname", personalData.second_lastname);
      formik.setFieldValue("email", accountUserData.email);
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("first_lastname", "");
      formik.setFieldValue("second_lastname", "");
      formik.setFieldValue("email", "");
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const responseStates = await getStatesTrigger().unwrap();
        setPlacesCatalog(responseStates);
      } catch (error) {
        console.log("Error al obtener los datos iniciales");
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  return (
    <>
      <Grid container>
        <Spinner open={!typePersonSucess}></Spinner>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 3 : 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center">
              Registro del accionista mayoritario
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 4 }}>
              <ProgressBar progress={7} />
            </Container>

            <Container maxWidth="sm" sx={{ mt: 1 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Soy el accionista mayoritario"
                    name="majority_shareholder"
                    checked={formik.values.majority_shareholder}
                    onChange={(_, checked: boolean) => {
                      formik.setFieldValue("majority_shareholder", checked);
                      handleMajorityShareholderChange(checked);
                    }}
                  />
                </Box>

                <FormControl fullWidth>
                  <TextField
                    id="name"
                    name="name"
                    label="Nombre(s)"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => onChangeNames(e.target.value, "name")}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name ? formik.errors.name : ""}
                    inputProps={{ maxLength: 20 }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="first_lastname"
                    name="first_lastname"
                    label="Apellido paterno"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => onChangeNames(e.target.value, "first_lastname")}
                    value={formik.values.first_lastname}
                    error={formik.touched.first_lastname && Boolean(formik.errors.first_lastname)}
                    helperText={formik.touched.first_lastname ? formik.errors.first_lastname : ""}
                    inputProps={{ maxLength: 20 }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="second_lastname"
                    name="second_lastname"
                    label="Apellido materno"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => onChangeNames(e.target.value, "second_lastname")}
                    value={formik.values.second_lastname}
                    error={formik.touched.second_lastname && Boolean(formik.errors.second_lastname)}
                    helperText={formik.touched.second_lastname ? formik.errors.second_lastname : ""}
                    inputProps={{ maxLength: 20 }}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker
                      label="Fecha de nacimiento"
                      value={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        formik.setFieldValue("date_birth", dayjs(date).format("YYYY-MM-DD"));
                      }}
                      slotProps={{
                        textField: {
                          name: "date_birth",
                          onBlur: formik.handleBlur,
                          error: formik.touched.date_birth && Boolean(formik.errors.date_birth),
                          helperText: formik.touched.date_birth ? formik.errors.date_birth : "",
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl
                  fullWidth
                  required
                  sx={{ mt: 2 }}
                  error={formik.touched.birth_state && Boolean(formik.errors.birth_state)}
                >
                  <InputLabel id="birth_state-label">Lugar de nacimento</InputLabel>
                  <Select
                    labelId="birth_state-label"
                    id="birth_state"
                    name="birth_state"
                    fullWidth
                    required
                    label="Lugar de nacimento"
                    value={formik.values.birth_state}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.birth_state && Boolean(formik.errors.birth_state)}
                  >
                    <MenuItem value={""}>Selecciona</MenuItem>
                    {placesCatalog.map((item: CatalogStates) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(formik.touched.birth_state) && Boolean(formik.errors.birth_state) && (
                    <FormHelperText>{formik.errors.birth_state}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="email"
                    name="email"
                    label="Correo electrónico"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email ? formik.errors.email : ""}
                    inputProps={{ maxLength: 30 }}
                  />
                </FormControl>

                <Box sx={{ mt: 2, mb: 2 }}>
                  <Typography variant={isMobileOrTablet ? "body2" : "body1"}>Sexo como aparece en tu INE:</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Masculino"
                        checked={formik.values.sex === "Masculino"}
                        onChange={() => formik.setFieldValue("sex", "Masculino")}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Femenino"
                        checked={formik.values.sex === "Femenino"}
                        onChange={() => formik.setFieldValue("sex", "Femenino")}
                      />
                    </Grid>
                  </Grid>
                  {formik.errors.sex && formik.touched.sex ? (
                    <Typography color="error" variant="caption" sx={{ mt: 1, mr: 1 }}>
                      {formik.errors.sex}
                    </Typography>
                  ) : null}
                </Box>

                <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 4}>
                  <Button variant="default" type="submit" color="primary" disabled={isLoading}>
                    Continuar
                  </Button>
                </Box>
              </form>
              <Box sx={{ mt: 3, mb: 2 }}>
                <p className="text-cuenta text-center">
                  Al continuar estas autorizando a Lendit group a consultar tu información ante el SAT. Consulta{" "}
                  <a
                    className="terminos-condiciones-cuenta"
                    href={import.meta.env.VITE_URL_TERM_CONDICIONES}
                    target="_blank"
                  >
                    términos y condiciones
                  </a>{" "}
                  y
                  <a
                    className="terminos-condiciones-cuenta"
                    href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD}
                    target="_blank"
                  >
                    {" "}
                    aviso de privacidad
                  </a>
                </p>
              </Box>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"} bgcolor={"#A3D4E8"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
