/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "@store/store";
import { Spinner } from "@components/Spinner";
import { QuotationOferta } from "@components/index";

import { formatCurrency } from "@helpers/index";
import { useGetApplicationByApplicationIdQuery, usePostValidateNextStageMutation } from "@api/applications";
import {
  useGetCondicionesBySolicitudIdQuery,
  usePostConditionMutation,
  usePutCondicionDataMutation,
} from "@api/conditions";

import iconLeft from "@assets/img/icon_left_bottom.svg";
import iconRight from "@assets/img/icon_right_top.svg";

import iconD from "@assets/img/icon_donwload.svg";
import { set_Id_applicationCondition, setNewConditionLoan } from "@store/slices/registerSlice";
import { usePostDocumentGenarateMutation } from "@api/documents";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

export const Oferta = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [amout, setAmout] = useState(0);
  const [term, setTerm] = useState(0);

  const [amoutPut, setAmoutPut] = useState(0);
  const [termPut, setTermPut] = useState(0);
  const [amountMonthlyPut, setAmountMonthlyPut] = useState(0);

  const [rate, setRate] = useState(0);
  const [termAprovet, setTermAprovet] = useState(0);
  const [amoutAprovet, setAmoutAprovet] = useState(0);

  const [residualValue, setResidualValue] = useState(0);

  const [score, setScore] = useState(0);
  const [scoreCode, setScoreCode] = useState("");

  const [amountMonthly, setAmountMonthly] = useState(0);

  const [applicationId, setApplicationId] = useState("");

  const [condicionesId, setCondicionesId] = useState("");

  const [loading, setLoading] = useState(false);

  const [putCondiconesData, { isLoading: loadingPut }] = usePutCondicionDataMutation();
  const [postNewConditions] = usePostConditionMutation();

  const [createDocument, { isLoading: loadingDocument }] = usePostDocumentGenarateMutation();

  const application = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);

  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [nextStageValidation, setNextStageValidation] = useState(false);

  const { data: applicationSol, isSuccess } = useGetApplicationByApplicationIdQuery(application.id);

  const {
    data: applicationData,
    error: applicationtError,
    isLoading: applicationIsLoading,
  } = useGetCondicionesBySolicitudIdQuery(application.id);

  const goTo = () => {
    if (application.type == import.meta.env.VITE_CODE_TAXS_CS_PM) {
      navigate("/producto/creditosimple/check_list");
    } else {
      navigate("/producto/creditosimple/informacion_documentos_pfae");
    }
  };

  useEffect(() => {
    if (applicationSol) {
      if (applicationSol.data[0].status_catalog?.url_actual === "/producto/creditosimple/oferta") {
        setNextStageValidation(true);
      } else {
        setNextStageValidation(false);
      }
    }
  }, [applicationSol, isSuccess]);

  useEffect(() => {
    if (applicationData?.data) {
      const filteredData = applicationData?.data.filter((item) => item.active);
      const data = filteredData[0];

      if (data) {
        setAmout(data.approved_amount || 0);
        setTerm(data.approved_term || 0);
        setAmoutPut(data.loan_amount || 0);
        setTermPut(data.requested_term || 0);
        setRate(data.interest_rate || 0);
        setTermAprovet(data.approved_term || 0);
        setAmoutAprovet(data.approved_amount || 0);
        setAmountMonthly(data.monthly_payment || 0);
        setAmountMonthlyPut(data.monthly_payment || 0);
        setCondicionesId(data.id);
        setApplicationId(data.loan_application_id);
        setResidualValue(data.residual_value);
        setScore(data.score);
        setScoreCode(data.score_code);
      }
    } else if (applicationtError) {
      setAmout(0);
      setTerm(0);
      setRate(0);
      setTermAprovet(0);
      setAmoutAprovet(0);
      setAmountMonthly(0);
    }

    setLoading(applicationIsLoading);
  }, [applicationData, applicationtError, applicationIsLoading]);

  const setConditions = async () => {
    const data = {
      data: {
        loan_amount: amoutPut,
        requested_term: termPut,
        monthly_payment: amountMonthlyPut,
        approved_amount: amoutAprovet,
        approved_term: termAprovet,
        interest_rate: rate,
        residual_value: residualValue,
        score: score,
        score_code: scoreCode,
        active: false,
      },
      solicitudId: applicationId,
      condicionesId: condicionesId,
    };
    try {
      const response = await putCondiconesData(data);
      console.log("Respuesta de PATCH:", response.data);
    } catch (error: any) {
      console.error("Error al realizar PATCH:", error);
      if (error?.status === 500 || error?.status === 400) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }

    try {
      const dataPost = {
        loan_application: applicationId,
        loan_amount: amout,
        requested_term: term,
        approved_amount: amoutAprovet,
        approved_term: termAprovet,
        interest_rate: rate,
      };
      const ResponsePost = await postNewConditions(dataPost).unwrap();

      dispatch(set_Id_applicationCondition(String(ResponsePost.data.id)));

      dispatch(
        setNewConditionLoan({
          amountMonthly: amountMonthly,
          loanAmount: amout,
          loanTerm: term,
          approved_amount: amoutAprovet,
          approved_term: termAprovet,
          interest_rate: rate,
        }),
      );
      if (nextStageValidation) {
        await triggerPostNextStage({
          applicationId: applicationId,
          statusCode: "CSAAO",
        }).unwrap();
      }

      goTo();
    } catch (error: any) {
      console.error("Error al realizar Post:", error);
      if (error?.status === 500 || error?.status === 409) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  const handleOnUpdateValues = (amount: number, term: number, monthlyRate: number) => {
    setAmout(amount);
    setTerm(term);
    setAmountMonthly(monthlyRate);
  };

  useEffect(() => {
    setLoading(loadingPut);
  }, [loadingPut]);

  const generateTablaAmortizacion = async () => {
    try {
      const data = {
        loan_application_id: String(application.id),
        document_type: "tabla_amortizacion",
      };

      const documents: any = await createDocument(data);

      if (documents?.error?.status === 500) {
        throw new Error(JSON.stringify({ status: 500 }));
      }

      if (documents?.error?.status === 400) {
        throw new Error(JSON.stringify({ status: 400 }));
      }

      if (documents?.data) {
        openFileInNewTab(documents.data);
      }
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  const openFileInNewTab = (fileUrl: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank"; // Abrir en una nueva pestaña
    link.rel = "noopener noreferrer"; // Seguridad para prevenir acceso de la nueva pestaña al origen
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Spinner open={loadingDocument} />
      <Grid container>
        <Grid item xs={12} md={12} mt={5} sx={{ position: "relative" }}>
          <InactivityHandler />
          {!isMobileOrTablet && (
            <Box
              component="img"
              src={iconRight}
              alt="icono superior derecho"
              sx={{
                position: "absolute",
                top: "10px",
                right: "20px",
                width: "50px",
                height: "50px",
              }}
            />
          )}

          <Box
            component="img"
            src={iconLeft}
            alt="icono inferior izquierdo"
            sx={{
              position: "absolute",
              bottom: "10px",
              left: "22px",
              width: "50px",
              height: "50px",
            }}
          />
          <Container maxWidth={"md"}>
            <Box
              sx={{
                my: 0,
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: 0, mb: 2 }}>
                <Typography color={"#528CD6"} fontWeight={"500"} align="center" fontSize={"22px"}>
                  ¡Felicidades{" "}
                  {personalData.name.concat(" ", personalData.first_lastname, " ", personalData.second_lastname)}!
                </Typography>
              </Box>

              <Box sx={{ mt: 1, mb: 1 }}>
                <Typography color={"#002652"} fontWeight={"300"} align="center" fontSize={"15px"}>
                  Tienes un crédito autorizado de hasta:
                </Typography>

                <Typography color={"#528CD6"} fontWeight={"600"} align="center" fontSize={"22px"}>
                  {formatCurrency(amoutAprovet)} MXN
                </Typography>
              </Box>

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Typography color={"#002652"} fontWeight={"300"} align="center" fontSize={"15px"}>
                      Con un plazo de hasta:
                    </Typography>

                    <Typography color={"#002652"} fontWeight={"600"} align="center" fontSize={"15px"}>
                      {termAprovet} meses
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Typography color={"#002652"} fontWeight={"300"} align="center" fontSize={"15px"}>
                      Y una tasa de:
                    </Typography>

                    <Typography color={"#002652"} fontWeight={"600"} align="center" fontSize={"15px"}>
                      {rate}%
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <QuotationOferta
                isShowed={true}
                onUpdateValues={handleOnUpdateValues}
                approved_amount={amoutAprovet}
                approved_term={termAprovet}
              />

              <p className="text-password-login" onClick={generateTablaAmortizacion}>
                <img src={iconD} /> Descarga aquí tu tabla de amortización
              </p>
              <Box sx={{ mt: 1, mb: 1 }}>
                <div className="pt-10">
                  <Button variant="default" onClick={setConditions} sx={{ width: "130px", height: "40px" }}>
                    Continuar
                  </Button>
                </div>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};
