import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { fetchAuthSession } from "aws-amplify/auth";

import { Home } from "@pages/home";
import { Login } from "@pages/Login";
import { CambiarContraseña } from "@pages/CambiaContraseña";
import { NuevaContraseña } from "@pages/nuevaContraseña";
import MainLayout from "../layouts/MainLayout";
import { RootState, useAppDispatch } from "@store/store";
import { CreditoSimple } from "@pages/producto/credito_simple/";
import { ValidateCodeLogin } from "@pages/home/ValidateCodeLogin";

export const LenditRoute = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const appData = useSelector((state: RootState) => state.app);

  //   useEffect(() => {
  //     fetchAuthSession()
  //       .then((values) => {
  //         if (values.userSub === undefined) {
  //           dispatch({ type: "CLEAN_STORE" });
  //           navigate("/login");
  //         }
  //       })
  //       .catch((_) => {
  //         dispatch({ type: "CLEAN_STORE" });
  //         navigate("/login");
  //       });
  //   }, []);

  return (
    <Routes>
      <Route path="/producto/creditosimple/*" element={<CreditoSimple />} />
      <Route path="/home/*" element={<Home />} />
      <Route path="/cambia_contraseña" element={<MainLayout />}>
        <Route index element={<CambiarContraseña />} />
      </Route>
      <Route path="/nueva_contraseña" element={<MainLayout />}>
        <Route index element={<NuevaContraseña />} />
      </Route>
      <Route path="/login" element={<MainLayout />}>
        <Route index element={<Login />} />
      </Route>
      <Route path="/validacion_codigo_login" element={<MainLayout />}>
        <Route index element={<ValidateCodeLogin />} />
      </Route>
      {/* <Route path="*" element={<Navigate to={"/login"} />}></Route> */}
      <Route path="*" element={<Navigate to={appData.process_page ? appData.process_page : "/login"} />}></Route>
    </Routes>
  );
};
