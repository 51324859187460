import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

import { useSelector } from "react-redux";
import { RootState } from "@store/store";

interface ProgressBarProps {
  progress: number;
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#d9d9d9",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#528CD6",
  },
  boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.2)",
}));

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const applicationData = useSelector((state: RootState) => state.register.application);

  const normalizedProgress = (progress / (applicationData.type == import.meta.env.VITE_CODE_TAXS_CS_PM ? 10 : 8)) * 100;

  return (
    <>
      <Typography align="right" variant="body2" color={"#002652"}>
        {progress} de {applicationData.type == import.meta.env.VITE_CODE_TAXS_CS_PM ? 10 : 8}
      </Typography>
      <BorderLinearProgress variant="determinate" value={normalizedProgress} />
    </>
  );
};
