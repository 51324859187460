import { Suspense } from "react";
import { NavbarAuthenticated } from "../components";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

const AuthenticatedLayout = () => {
  return (
    <>
      <NavbarAuthenticated />
      <Box pt={27} height={'calc(100vh - 27rem)'}>
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
};

export default AuthenticatedLayout;
