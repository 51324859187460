import { ReactNode, useEffect, useState } from "react";
import { Grid, Dialog, DialogContent, DialogTitle, IconButton, DialogProps, Box } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export interface CustomDialogProps extends DialogProps {
  open: boolean;
  itemTitle: ReactNode;
  itemContent: ReactNode;
  onClose: () => void;
  colorClose: string;
}

export const CustomDialog = (dialogProps: CustomDialogProps) => {
  const [statusOpen, setStatusOpen] = useState<boolean>(dialogProps.open);

  useEffect(() => {
    setStatusOpen(dialogProps.open);
  }, [dialogProps.open]);

  return (
    <Dialog open={statusOpen}>
      <DialogTitle>
        <Box position={'relative'}>
          <IconButton sx={{ position:'absolute', top:-20, right:-20}} onClick={dialogProps.onClose}>
            <CloseIcon sx={{ color: dialogProps.colorClose, fontSize: "30px" }} />
          </IconButton>
        </Box>
        <Grid container direction={"column"}>
          <Grid item></Grid>
          <Grid item>{dialogProps.itemTitle}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{dialogProps.itemContent}</DialogContent>
    </Dialog>
  );
};
