import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Grid,
    Typography,
    TextField,
    useMediaQuery,
    useTheme,
    MenuItem,
    FormControl,
    FormHelperText,
    Select,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { useLazyGetByCpQuery } from "../../../../../api/postalCodes";
import { CustomUploadFile } from "@components/CustomUploadFile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useGetAddressTypeQuery } from "@api/catalogs";
import { useLazyGetAddressByClientIdQuery, usePatchSocialMediaMutation, usePostAddresMutation } from "@api/addressApi";
import img from "@assets/img/img_tofu/img_tofu_1.svg";
import icon from "@assets/img/icon_rectangulo.svg";
import { useLazyGetScheduleByApplicationIdQuery } from "@api/applications";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { removeCompanyDocument, setCompanyCompleted, setCompanyDocument } from "@store/slices/bofuSlice";
import { UUID } from "crypto";
import { UploadedFile } from "@interfaces/store";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

const validationSchema = yup.object({
    codigoPostal: yup
        .string()
        .required("Requerido")
        .matches(/^[0-9]{5}$/, "Debe ser un código postal válido"),
    calle: yup.string().required("Requerido"),
    noExterior: yup.string().required("Requerido"),
    noInterior: yup.string(),
    colonia: yup.string().required("Requerido"),
    ciudad: yup.string().required("Requerido"),
    municipio: yup.string().required("Requerido"),
    entidadFederativa: yup.string().required("Requerido"),
});

export const AgendaVisitaOcularPFAE: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

    const [isFormValid, setIsFormValid] = useState(false);
    const [getByCp, { data: postalData, isError: isErrorCP, error: errorCP }] = useLazyGetByCpQuery();
    const [getAddressByPerson, { data: rawAddressData, isError: isErrorAddress, error: errorAddress }] = useLazyGetAddressByClientIdQuery();
    const { data: addressTypes, isError: isErrorAddressTypes, error: errorAddressTypes } = useGetAddressTypeQuery();
    const [updateAddress] = usePatchSocialMediaMutation();
    const [createAddress] = usePostAddresMutation();
    const [colonias, setColonias] = useState<{ id: number; name: string }[]>([]);
    const personalData = useSelector((state: RootState) => state.register.personal_data);

    const [addressData, setAddressData] = useState<any[]>([]);
    const [addressId, setAddressId] = useState<string | null>(null);
    const operationalAddressType = addressTypes?.find((type) => type.code === "CDOP");
    const confirmedoperationalAddressType = addressTypes?.find((type) => type.code === "CDOC");
    const [cpError, setCpError] = useState("");
    const applicationData = useSelector((state: RootState) => state.register.application.id);
    const tofuSection = useSelector((state: RootState) => state.register);
    const application = useSelector((state: RootState) => state.register.application);
    const companySection = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);

    const [getSchedule, { data: scheduleData, isError: isErrorSchedule, error: errorSchedule }] = useLazyGetScheduleByApplicationIdQuery();

    const [validations, setValidations] = useState<yup.ObjectShape>({});

    const dispatch = useDispatch();

    const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
    const [isContinueEnabled, setIsContinueEnabled] = useState(false);

    const isErrorModalVisible = useSelector((state: RootState) => state.app.modal_error.visible)


    const handleError = (error: any) => {
        if (!isErrorModalVisible) {
            if (error?.status === 500 || error?.status === 400 || error?.status === 502) {
                dispatch(setModalErrosVisible({ open: true, type: "500" }));
            } else {
                dispatch(setModalErrosVisible({ open: true, type: "" }));
            }
        }
    };


    const handleAddressError = () => {
        if (isErrorAddress) {
            handleError(errorAddress);
        }
    };


    const handleCPError = () => {
        if (isErrorCP) {
            handleError(errorCP);
        }
    };

    const handleAddressTypesError = () => {
        if (isErrorAddressTypes) {
            handleError(errorAddressTypes);
        }
    };


    useEffect(() => {
        handleAddressError();
        handleCPError();
        handleAddressTypesError();
    }, [isErrorAddress, isErrorCP, isErrorAddressTypes]);



    const formik = useFormik({
        initialValues: {
            codigoPostal: "",
            calle: "",
            noExterior: "",
            noInterior: "",
            colonia: "",
            ciudad: "",
            municipio: "",
            entidadFederativa: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const selectedColonia = colonias.find((colonia) => colonia.name === values.colonia);
            const updatedValues = {
                ...values,
                colonia_id: selectedColonia ? selectedColonia.id : "",
                ciudad_id: addressData ? addressData[0]?.city_id : "",
                municipio_id: addressData ? addressData[0]?.city_id : "",
                estado_id: addressData ? addressData[0]?.state_id : "",
            };
            try {
                if (!addressId) {
                    await createAddress({
                        street: values.calle,
                        no_ext: values.noExterior,
                        no_int: values.noInterior,
                        colonia_id: Number(updatedValues.colonia_id),
                        state_id: updatedValues.estado_id,
                        city_id: updatedValues.ciudad_id,
                        country_id: 1,
                        cp: values.codigoPostal,
                        client_details_id: personalData.id,
                        address_type_id: operationalAddressType?.id || "",
                    }).unwrap();
                } else {
                    await updateAddress({
                        direccion: addressId,
                        data: {
                            street: updatedValues.calle,
                            no_ext: updatedValues.noExterior,
                            no_int: updatedValues.noInterior,
                            colonia_id: Number(updatedValues.colonia_id),
                            state_id: updatedValues.estado_id,
                            city_id: updatedValues.ciudad_id,
                            country_id: 1,
                            cp: updatedValues.codigoPostal,
                            client_details_id: personalData.id || "",
                            address_type_id: operationalAddressType?.id || "",
                        },
                    }).unwrap();
                }

                setIsContinueEnabled(true);
            } catch (error: any) {
                handleError(error)
            }
        },
    });

    useEffect(() => {
        if (personalData.id) {
            getAddressByPerson(personalData.id);
        }
    }, [getAddressByPerson, personalData.id]);

    useEffect(() => {
        if (rawAddressData && (operationalAddressType || confirmedoperationalAddressType)) {
            const confirmedOperationalAddress = rawAddressData.find(
                (address) => address.address_type_id === confirmedoperationalAddressType?.id
            );
            const operationalAddress = rawAddressData.find(
                (address) => address.address_type_id === operationalAddressType?.id
            );

            if (confirmedOperationalAddress) {
                formik.setValues({
                    codigoPostal: confirmedOperationalAddress.cp || "",
                    calle: confirmedOperationalAddress.street || "",
                    noExterior: confirmedOperationalAddress.no_ext || "",
                    noInterior: confirmedOperationalAddress.no_int || "",
                    colonia: confirmedOperationalAddress.colonia_id?.toString() || "",
                    ciudad: confirmedOperationalAddress.city_id?.toString() || "",
                    municipio: confirmedOperationalAddress.colonia_id?.toString() || "",
                    entidadFederativa: confirmedOperationalAddress.state_id?.toString() || "",
                });

                setAddressData([confirmedOperationalAddress]);
                setAddressId(confirmedOperationalAddress.id);
            } else if (operationalAddress) {
                formik.setValues({
                    codigoPostal: operationalAddress.cp || "",
                    calle: operationalAddress.street || "",
                    noExterior: operationalAddress.no_ext || "",
                    noInterior: operationalAddress.no_int || "",
                    colonia: operationalAddress.colonia_id?.toString() || "",
                    ciudad: operationalAddress.city_id?.toString() || "",
                    municipio: operationalAddress.colonia_id?.toString() || "",
                    entidadFederativa: operationalAddress.state_id?.toString() || "",
                });

                setAddressData([operationalAddress]);
                setAddressId(null);
            }
        }
    }, [rawAddressData, operationalAddressType]);

    useEffect(() => {
        if (formik.values.codigoPostal.length === 5) {
            getByCp(formik.values.codigoPostal);
        }
    }, [formik.values.codigoPostal, getByCp]);

    useEffect(() => {
        if (postalData) {
            if (postalData.colonias.length === 0) {
                formik.setFieldValue("colonia", "");
                setColonias([]);
                formik.setFieldValue("ciudad", "");
                formik.setFieldValue("municipio", "");
                formik.setFieldValue("entidadFederativa", "");
                setCpError("No existe el código postal");
            } else {
                setCpError("");
                if (formik.values.colonia !== postalData.colonias[0]?.name) {
                    formik.setFieldValue("colonia", postalData.colonias[0]?.name || "");
                }
                if (formik.values.ciudad !== postalData.cities[0]?.name) {
                    formik.setFieldValue("ciudad", postalData.cities[0]?.name || "");
                }
                if (formik.values.municipio !== postalData.cities[0]?.name) {
                    formik.setFieldValue("municipio", postalData.cities[0]?.name || "");
                }
                if (formik.values.entidadFederativa !== postalData.states[0]?.name) {
                    formik.setFieldValue("entidadFederativa", postalData.states[0]?.name || "");
                }

                setColonias(postalData.colonias);
                formik.setFieldError("codigoPostal", "");
            }
        }
    }, [postalData, formik.values]);

    const handleUpdateStore = (action: any, _: number, uploadedFile: UploadedFile | null, fileInternalId: UUID) => {
        if (action === "ADD" && uploadedFile !== null) {
            dispatch(setCompanyDocument(uploadedFile));
        } else if (action === "DEL") {
            dispatch(removeCompanyDocument(fileInternalId));
        }
    };

    const registerInternalFormik = (name: string, validationSchema: yup.Schema) => {
        validations[name] = validationSchema;
        setValidations(validations);
    };

    useEffect(() => {
        if (formik.values.codigoPostal || formik.values.calle || formik.values.noExterior || formik.values.colonia || formik.values.ciudad || formik.values.municipio || formik.values.entidadFederativa) {
            const isDomicilioValid =
                !!formik.values.codigoPostal &&
                !!formik.values.calle &&
                !!formik.values.noExterior &&
                !!formik.values.colonia &&
                !!formik.values.ciudad &&
                !!formik.values.municipio &&
                !!formik.values.entidadFederativa;

            const hasComprobante = companySection.files.some(file => file.file_code === "DCDC");

            setIsConfirmEnabled(isDomicilioValid);

            setIsContinueEnabled(isDomicilioValid && hasComprobante);
        }
    }, [formik.values, companySection.files]);

    const handleContinue = async () => {
        const solicitudId = applicationData;
        if (solicitudId) {
            await getSchedule(solicitudId);

            if (!scheduleData?.data.data || scheduleData.data.data.length === 0) {
                navigate("/producto/creditosimple/confirmacion_domicilio");
            } else if (scheduleData.data.data.length >= 0) {

                navigate("/producto/creditosimple/confirmacion_visita_agendada");
            }
        }
    };

    return (
        <Grid container sx={{ height: '100vh' }}>
            <Grid item xs={12} md={8}>
                <InactivityHandler />
                <Box textAlign={'center'} mx={4}>
                    <NavBarOcularVisit currentStep={1} />
                    <Typography mt={4} mb={3} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
                        Agenda tu visita ocular
                    </Typography>

                    <Typography component="span" variant={isMobileOrTablet ? "body2" : "body1"} color={"#528CD6"} fontWeight={400}>
                        Bienvenido a los últimos pasos para obtener tu crédito, a continuación realizaremos una visita ocular y cotejo de documentos
                    </Typography>

                    <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
                        Tu ejecutivo se pondrá en contacto para agendar la visita a tu domicilio operativo:
                    </Typography>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <TextField
                            fullWidth
                            id="codigoPostal"
                            name="codigoPostal"
                            label="Código postal"
                            required
                            value={formik.values.codigoPostal}
                            onChange={formik.handleChange}
                            error={!!cpError || !!formik.errors.codigoPostal}
                            helperText={cpError || (formik.touched.codigoPostal && formik.errors.codigoPostal)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            id="calle"
                            name="calle"
                            label="Calle o avenida"
                            required
                            value={formik.values.calle}
                            onChange={formik.handleChange}
                            error={formik.touched.calle && Boolean(formik.errors.calle)}
                            helperText={formik.touched.calle && formik.errors.calle}
                            margin="normal"
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="noExterior"
                                    name="noExterior"
                                    label="No. Exterior"
                                    required
                                    value={formik.values.noExterior}
                                    onChange={formik.handleChange}
                                    error={formik.touched.noExterior && Boolean(formik.errors.noExterior)}
                                    helperText={formik.touched.noExterior && formik.errors.noExterior}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="noInterior"
                                    name="noInterior"
                                    label="No. Interior"
                                    value={formik.values.noInterior}
                                    onChange={formik.handleChange}
                                    error={formik.touched.noInterior && Boolean(formik.errors.noInterior)}
                                    helperText={formik.touched.noInterior && formik.errors.noInterior}
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                        <FormControl fullWidth margin="normal" error={formik.touched.colonia && Boolean(formik.errors.colonia)}>
                            <InputLabel>Colonia</InputLabel>
                            <Select
                                id="colonia"
                                name="colonia"
                                required
                                value={formik.values.colonia}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={colonias.length === 0 || !!cpError}
                            >
                                {colonias.map((colonia) => (
                                    <MenuItem key={colonia.id} value={colonia.name}>
                                        {colonia.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.colonia && <FormHelperText>{formik.errors.colonia}</FormHelperText>}
                        </FormControl>
                        <TextField
                            fullWidth
                            id="ciudad"
                            name="ciudad"
                            label="Ciudad"
                            required
                            value={formik.values.ciudad}
                            onChange={formik.handleChange}
                            error={formik.touched.ciudad && Boolean(formik.errors.ciudad)}
                            helperText={formik.touched.ciudad && formik.errors.ciudad}
                            margin="normal"
                            disabled
                        />
                        <TextField
                            fullWidth
                            id="municipio"
                            name="municipio"
                            label="Municipio"
                            required
                            value={formik.values.municipio}
                            onChange={formik.handleChange}
                            error={formik.touched.municipio && Boolean(formik.errors.municipio)}
                            helperText={formik.touched.municipio && formik.errors.municipio}
                            margin="normal"
                            disabled
                        />
                        <TextField
                            fullWidth
                            id="entidadFederativa"
                            name="entidadFederativa"
                            label="Entidad federativa"
                            required
                            value={formik.values.entidadFederativa}
                            onChange={formik.handleChange}
                            error={formik.touched.entidadFederativa && Boolean(formik.errors.entidadFederativa)}
                            helperText={formik.touched.entidadFederativa && formik.errors.entidadFederativa}
                            margin="normal"
                            disabled
                        />
                        <CustomUploadFile
                            py={2}
                            updateStore={handleUpdateStore}
                            optionalName="Comprobante de domicilio"
                            uploadedFile={companySection.files.find((uploadedFile) => uploadedFile.file_code === "DCDC")}
                            optionalDescription="No deberá ser mayor a 3 meses"
                            setFieldValue={formik.setFieldValue}
                            registerFormik={registerInternalFormik}
                            errors={formik.errors}
                            metadata={{
                                taxCode: import.meta.env.VITE_CODE_TAXS_CS_PFAE,
                                fileCode: "DCDC",
                                uploadType: "client",
                                loan_application_id: application.id,
                                regimen: tofuSection.application.type,
                                clientDetailsId: tofuSection.personal_data.id,
                            }}
                        />
                        <Grid container columnSpacing={1} my={4} justifyContent="center">
                            <Grid item xs={6} sm={4} md={3}>
                                <Button
                                    disabled={!isConfirmEnabled}
                                    variant="default"
                                    type="submit"
                                    size="medium"
                                    fullWidth
                                    sx={{ mt: 2, padding: "12px" }}
                                >
                                    Confirma tu domicilio
                                </Button>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                backgroundColor: "#EAF3FF",
                                borderRadius: "10px",
                                padding: "20px",
                                maxWidth: "98%",
                            }}
                            mt={3}
                        >
                            <Typography fontWeight={600} color={"#002652"} fontSize={"14px"} align="center">
                                Recuerda que el día de la visita deberás presentar los siguientes documentos:
                            </Typography>
                            <List sx={{ listStyleType: "disc", paddingLeft: 2 }}>
                                {[
                                    "Comprobante de domicilio operativo",
                                    "Estados de cuenta bancarios",
                                    "Identificación oficial vigente del representante legal",
                                ].map((item, index) => (
                                    <ListItem key={index} disablePadding>
                                        <ListItemIcon sx={{ minWidth: "25px" }}>
                                            <img src={icon} alt="Document Icon" style={{ width: "15px", height: "15px" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item}
                                            sx={{
                                                fontSize: "14px",
                                                color: "#002652",
                                                fontWeight: 300,
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Grid container columnSpacing={1} my={4} justifyContent="center">
                            <Grid item xs={6} sm={4} md={3}>
                                <Button
                                    disabled={!isContinueEnabled}
                                    variant="default"
                                    onClick={handleContinue}
                                    fullWidth
                                    sx={{ mt: 2, padding: "12px" }}
                                >
                                    Continuar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Grid>
            {!isMobileOrTablet && (
                <Grid item xs={12} md={4}>
                    <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
                        <img
                            src={img}
                            alt=""
                            style={{
                                height: "calc(100vh - 70px)",
                                margin: "auto",
                                width: "100%",
                            }}
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};
