import { baseApi } from "./baseApi";

interface getDocumentsContractsParams {
  loan_application_id: string;
  size?: number;
}

interface getDocumentsFiscalParams {
  loan_application_id: string;
  size?: number;
}

const accountApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLoans: build.query<any, undefined>({
      query: () => `loans`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getPaymentsByContractId: build.query<any, string>({
      query: (contractId: string) => `loans/${contractId}/schedule`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getDocumentsContracts: build.query<any, getDocumentsContractsParams>({
      query: (params: getDocumentsContractsParams) =>
        `documentos/documents_contract?q=loan_application_id:${params.loan_application_id}&size=${params.size || 200}`,
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
    getDocumentsFiscal: build.query<any, getDocumentsFiscalParams>({
      query: (params: getDocumentsFiscalParams) =>
        `documentos/documents_fiscal?q=loan_application_id:${params.loan_application_id}&size=${params.size || 200}`,
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
  }),
});

export const {
  useLazyGetLoansQuery,
  useLazyGetPaymentsByContractIdQuery,
  useLazyGetDocumentsContractsQuery,
  useLazyGetDocumentsFiscalQuery,
} = accountApi;
