/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";

import * as yup from "yup";
import { UUID } from "crypto";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "@store/store";
import { useGetLoanUseQuery } from "@api/catalogs";
import { useLazyPostLoanUseQuery, usePostValidateNextStageMutation } from "@api/applications";

import { ProgressBar } from "@components/ProgressBar";

import img from "@assets/img/right_7.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";

import { InactivityHandler } from "@components/InactivityHandler";

export const UsoDeCredito = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [reasonEnabled, setReasonEnabled] = useState<boolean>(false);

  const responseLoanUse = useGetLoanUseQuery();

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [triggerSaveUseSelected, resultUseSelected] = useLazyPostLoanUseQuery();

  const dispatch = useAppDispatch();

  const applicationData = useSelector((state: RootState) => state.register.application);

  const formik = useFormik({
    initialValues: {
      uses: [],
      reason: "",
    },
    validationSchema: yup.object().shape(
      {
        uses: yup.array().min(1, "").required(""),
        reason: yup.string().when("reason", (_) => {
          if (reasonEnabled) {
            return yup.string().trim().required("Campo requerido");
          } else {
            return yup.string().notRequired();
          }
        }),
      },
      [["reason", "reason"]],
    ),
    onSubmit: async (values) => {
      if (formik.isValid) {
        const otherUseId = responseLoanUse.data?.filter((use) => use.name === "Otro")[0].id;

        const tmp = values.uses.map((item: { resources_use_id: UUID; reason: string }) => {
          if (item.resources_use_id === otherUseId) {
            item.reason = values.reason;
          }
          return item;
        });

        try {
          await triggerSaveUseSelected({
            solicitud_id: applicationData.id,
            body: {
              resource_uses: tmp,
            },
          }).unwrap();

          await triggerPostNextStage({
            applicationId: applicationData.id,
            statusCode: "CSUDC",
          }).unwrap();

          if (applicationData.type == import.meta.env.VITE_CODE_TAXS_CS_PFAE) {
            navigate("/producto/creditosimple/presencia_digital_pfae");
          } else {
            navigate("/producto/creditosimple/presencia_digital");
          }
        } catch (error: any) {
          if (
            error?.status === 400 ||
            error?.status === 500 ||
            error?.status === 409 ||
            error?.status === "FETCH_ERROR"
          ) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
          }
        }
      }
    },
  });

  const handleOnChangeUseSelected = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    formik.setFieldValue(
      "uses",
      checked
        ? [...formik.values.uses, { resources_use_id: event.target.value, reason: "" }]
        : [
          ...formik.values.uses.filter(
            (item: { resources_use_id: string; reason: string }) => item.resources_use_id !== event.target.value,
          ),
        ],
    );

    if (event.target.id === "otro") {
      setReasonEnabled(checked);
    }
  };

  useEffect(() => {
    if (reasonEnabled === false) {
      formik.setValues(
        {
          reason: "",
          uses: formik.values.uses,
        },
        true,
      );
    }
  }, [reasonEnabled]);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box my={8} mx={4} display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Typography mb={3} variant="h4" fontWeight={500}>
            Selección de uso de crédito
          </Typography>
          <Container maxWidth="sm" sx={{ mt: 2, mb: 2, p: 0 }}>
            <ProgressBar progress={applicationData.type == import.meta.env.VITE_CODE_TAXS_CS_PM ? 5 : 6} />
            {/* {personType === "pm" && <ProgressBar progress={5} />} */}
            {/* {personType === "pfae" && <ProgressBarPfae progress={5} />} */}
          </Container>

          <Container maxWidth="sm" sx={{ mt: 1, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              {responseLoanUse.isSuccess && responseLoanUse.data
                ? responseLoanUse.data.map((item, index) => {
                  return (
                    <Box my={2} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={item.name.replace(" ", "_").toLowerCase()}
                            name="uses"
                            value={item.id}
                            onChange={handleOnChangeUseSelected}
                          />
                        }
                        label={item.name}
                      />
                      {item.other && (
                        <TextField
                          id="reason"
                          name="reason"
                          label="Especifica otro"
                          sx={{ display: reasonEnabled ? "block" : "none" }}
                          margin="normal"
                          required
                          fullWidth
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.reason}
                          error={formik.touched.reason && Boolean(formik.errors.reason)}
                          helperText={formik.touched.reason && formik.errors.reason}
                        />
                      )}
                    </Box>
                  );
                })
                : [...Array(6).keys()].map((_, index) => (
                  <Box my={2} key={index} py={1}>
                    <Skeleton variant="text" animation={"wave"} />
                  </Box>
                ))}

              <Box my={5} display={"flex"} justifyContent={"center"}>
                <Button
                  type="submit"
                  variant="default"
                  disabled={
                    !formik.dirty ||
                    !formik.isValid ||
                    !(Object.keys(formik.errors).length === 0) ||
                    resultUseSelected.isLoading
                  }
                >
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
