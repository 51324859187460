import { baseApi } from "../baseApi";
import { Response } from "src/types";

export interface CiecValidatorRequest {
  rfc: string;
  ciec: string;
  loan_application_id: string;
  application_code: string;
  client_details_id: string;
  loan_application_conditions_id: string;
  requested_amount?: number,
  requested_term?: number
}

interface LoanApplicationPF {
  mortgage_credit: boolean;
  automotive_credit: boolean;
  credit_card: boolean;
  last_four_digits: string | null;
}

export interface BuroValidatorRequest {
  person_Type: "pf" | "pm" | "pfae";
  data: {
    rfc: string;
    transaction_number: string;
    application_code: string;
    legal_name: string;
    first_name: string;
    second_name: string;
    father_last_name: string;
    mother_last_name: string;
    reenter_NIP: boolean;
    authorization_label_response: boolean;
    loan_application_id: string;
    pf: LoanApplicationPF;
  };
}

const satApiNew = baseApi.injectEndpoints({
  endpoints: (build) => ({
    ciecValidator: build.mutation<Response, CiecValidatorRequest>({
      query: (data) => ({
        url: `sat_core/ciec_validator`,
        method: "POST",
        body: data,
      }),
    }),
    buroValidator: build.mutation<Response, BuroValidatorRequest>({
      query: ({ person_Type, data }) => ({
        url: `sat_core/credit_bureau_authorization/${person_Type}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useCiecValidatorMutation, useBuroValidatorMutation } = satApiNew;
