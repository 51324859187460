/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  Grid,
  Box,
  Container,
  TextField,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Typography,
  Link,
  Button,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "@store/store";

import { usePostValidateNextStageMutation } from "@api/applications";

import { CustomSwitch, ProgressBar } from "@components/index";

import img from "@assets/img/ciec_img_right.svg";
import { usePostModeloMockMutation } from "@api/mockModeloAPi";
import { setModalErrosVisible } from "@store/slices/appSlice";
import {
  useCiecValidatorMutation,
  CiecValidatorRequest,
  useBuroValidatorMutation,
  BuroValidatorRequest,
} from "@api/sat_apis_new/satApsi";
import { InactivityHandler } from "@components/InactivityHandler";

export const ConsultaCiec = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = React.useState(false);
  const companyData = useSelector((state: RootState) => state.register.company);
  const application = useSelector((state: RootState) => state.register.application);
  const userData = useSelector((state: RootState) => state.register.personal_data);


  const [postModeloMock] = usePostModeloMockMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [ciecValidation] = useCiecValidatorMutation();
  const [buroValidation] = useBuroValidatorMutation();

  const sendCiecRequest = async (values: any) => {
    const payload_cieq: CiecValidatorRequest = {
      rfc: companyData.rfc,
      ciec: values.ciec,
      application_code: "LEN",
      client_details_id: String(application.client_details_id),
      loan_application_conditions_id: String(application.application_condition_id),
      loan_application_id: String(application.id),
      requested_amount: Number(application.loan_amount),
      requested_term: Number(application.loan_term),
    };
    await ciecValidation(payload_cieq).unwrap();
  };

  const sendBuroValidator = async () => {
    const payload: BuroValidatorRequest = {
      person_Type: application.type === import.meta.env.VITE_CODE_TAXS_CS_PFAE ? 'pfae' : 'pm',
      data: {
        rfc: companyData.rfc,
        transaction_number: String(application.id),
        application_code: "LEN",
        legal_name: companyData.legal_name,
        first_name: application.type === import.meta.env.VITE_CODE_TAXS_CS_PFAE ? userData.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
        second_name: "",
        father_last_name: application.type === import.meta.env.VITE_CODE_TAXS_CS_PFAE ? userData.first_lastname.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
        mother_last_name: application.type === import.meta.env.VITE_CODE_TAXS_CS_PFAE ? userData.second_lastname.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
        reenter_NIP: true,
        authorization_label_response: true,
        loan_application_id: String(application.id),
        pf: {
          mortgage_credit: false,
          automotive_credit: false,
          credit_card: false,
          last_four_digits: "",
        },
      },
    };
    await buroValidation(payload).unwrap();
  };

  const sendModeloMock = async () => {
    const dataMock = {
      loan_application_id: String(application.id),
      loan_application_conditions_id: String(application.application_condition_id),
    };

    await postModeloMock(dataMock).unwrap();
  };

  const sendNextStage = async () => {
    await triggerPostNextStage({
      applicationId: application.id,
      statusCode: "CSAEM",
    }).unwrap();
  };

  const formik = useFormik({
    initialValues: {
      ciec: "",
      switch: false,
    },
    validationSchema: yup.object().shape({
      ciec: yup.string().min(8, "Formato incorrecto, debe tener 8 dígitos").required("Campo requerido."),
      switch: yup.boolean().oneOf([true], "Debe aceptar los términos y condiciones"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setIsLoading(true);
          await sendCiecRequest(values);
          await sendBuroValidator();
          await sendModeloMock();
          await sendNextStage();
          navigate("/producto/creditosimple/uso_de_credito");
        } catch (error: any) {
          if (error?.status === 400 || error?.status === 500) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={3} mt={4}>
          <Typography mb={3} variant="h4" fontWeight={500}>
            Registro de empresa
          </Typography>
          <Container maxWidth="sm" sx={{ my: 2, p: 0 }}>
            <ProgressBar progress={application.type == import.meta.env.VITE_CODE_TAXS_CS_PM ? 4 : 5} />
          </Container>

          <Typography mt={2} variant={isMobileOrTablet ? "body2" : "body1"} textAlign={"center"}>
            Haremos una consulta de tus datos al SAT para obtener tu oferta personalizada
          </Typography>

          <Typography my={2} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
            {companyData.legal_name}
          </Typography>
          <Typography my={2} variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={600}>
            {companyData.rfc}
          </Typography>
          <Typography my={1} variant={isMobileOrTablet ? "body2" : "body1"}>
            Ingresa la contraseña
          </Typography>
          <Container maxWidth="sm" sx={{ mt: 1, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                id="ciec"
                name="ciec"
                label="Contraseña del SAT (CIEC)"
                margin="normal"
                type="password"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.ciec}
                error={formik.touched.ciec && Boolean(formik.errors.ciec)}
                helperText={formik.touched.ciec ? formik.errors.ciec : ""}
                inputProps={{ maxLength: 8 }}
              />

              <Box my={5}>
                <FormControlLabel
                  control={<CustomSwitch />}
                  label={
                    <Typography variant={isMobileOrTablet ? "body2" : "body1"}>
                      Autorizo a Lendit Group a consultar tu información ante el SAT. Consulta{" "}
                      <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
                        términos y condiciones
                      </Link>{" "}
                      y{" "}
                      <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
                        aviso de privacidad
                      </Link>{" "}
                    </Typography>
                  }
                  name="switch"
                  checked={formik.values.switch}
                  onChange={formik.handleChange}
                />
              </Box>

              <Button
                type="submit"
                variant="default"
                disabled={isLoading || !formik.dirty || !formik.isValid || !(Object.keys(formik.errors).length === 0)}
              >
                Continuar
              </Button>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
