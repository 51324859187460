import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SyntheticEvent } from "react";
import "./CustomContryCode.css";
import countryCodes from "./countrys";

interface CustomCountryCodeItem {
  name: string;
  img: string;
  code: string;
}

interface CustomCountryCodeProps {
  label: string;
  name: string;
  value: string;
  required?: boolean;
  onChange: (event: SelectChangeEvent) => void;
  disabled?: boolean;
  onBlur?: (event: SyntheticEvent) => void;
  error?: boolean;
  size?: "small" | "medium";
}

const CustomCountryCode = ({
  label,
  name,
  value: currentValue,
  onChange,
  onBlur,
  disabled,
  error,
  required,
  size,
}: CustomCountryCodeProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <FormControl fullWidth required={required}>
        <InputLabel id={`${name}-label`} size={size == "small" ? "small" : "normal"}>
          {label}
        </InputLabel>
        <Select
          labelId={`${name}-label`}
          id={name}
          name={name}
          fullWidth
          required={required}
          label={label}
          value={currentValue || ""}
          onBlur={onBlur}
          onChange={onChange}
          error={error}
          disabled={disabled}
          size={size || "medium"}
          sx={{
            "& .MuiSelect-icon": {
              display: "none",
            },
            "& .MuiSelect-select": {
              paddingRight: "0px",
              paddingLeft: "10px",
            },
          }}
        >
          {countryCodes.map((item: CustomCountryCodeItem, index: number) => (
            <MenuItem key={`${index}_${item.img}`} value={item.code} sx={{
              paddingRight: "0px",
            }}>
              <Box
                component={"div"}
                className="custom-country-code"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignContent="center"
                alignItems="center"
                gap={isMobileOrTablet ? 0 : 1}
              >
                <div className={`flag ${item.img}`}></div>
                {item.code}
              </Box>
            </MenuItem>
          ))}
        </Select>
        {/* {error && helperText && <FormHelperText>{helperText}</FormHelperText>} */}
      </FormControl>
    </>
  );
};

export default CustomCountryCode;
