/* eslint-disable @typescript-eslint/no-explicit-any */
import { LegalAgent, LoanApplicationCompany } from "@interfaces/index";
import { Owner, UploadedFile } from "@interfaces/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UUID } from "crypto";

export interface BankAccount {
  id: UUID | undefined;
  clabe: string;
  bank_name: string;
  bank_statements: Array<UploadedFile>;
}

export interface TransactionalInfo {
  payment_methods: Array<UUID>;
  details: {
    id: UUID | undefined | string;
    advance_payments: boolean | undefined;
    own_resources: boolean | undefined;
    pep_shareholder: boolean | undefined;
    pep_members: boolean | undefined;
  };
}

export interface Reference {
  id: UUID | undefined;
  client_id: string;
  email: string;
  code_phone: string | undefined;
  phone_number: string | undefined;
}

export interface BofuSlice {
  digital_file: {
    current_step: "company" | "guarantee" | "actual_owner";
    company: {
      completed: boolean;
      files: Array<UploadedFile>;
      loan_application: {
        legal_agent: LegalAgent;
        transactional_info: TransactionalInfo;
      };
      references: {
        clients: Array<Reference>;
        suppliers: Array<Reference>;
      };
      bank_accounts: Array<BankAccount>;
    };
    guarantee: {
      completed: boolean;
      files: Array<UploadedFile>;
      bank_accounts: Array<BankAccount>;
      docs_info: {
        info: boolean;
        indenti: boolean;
        info_bank: boolean;
        files: Array<UploadedFile>;
      };
      id_address: string;
      id_pepsFamiliar: string;
      id_pepsPropio: string;
    };
    actual_owner: {
      completed: boolean;
      owners: Owner[];
      current_owner_id: string | null;
    };
  };
}

const initialState: BofuSlice = {
  digital_file: {
    current_step: "company",
    company: {
      completed: false,
      files: [],
      bank_accounts: [
        {
          id: undefined,
          clabe: "",
          bank_name: "",
          bank_statements: [
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
          ],
        },
      ],
      loan_application: {
        legal_agent: {
          code_phone: undefined,
          phone_number: undefined,
          email: "",
          company: {
            activity_id: undefined,
            description: "",
          },
          operative_address: {
            postal_code: "",
            street: "",
            no_ext: "",
            no_int: "",
            colonia_id: 0,
            state_id: 0,
            city_id: 0,
            country_id: 0,
            id: undefined,
          },
        },
        transactional_info: {
          payment_methods: [],
          details: {
            id: undefined,
            advance_payments: undefined,
            own_resources: undefined,
            pep_shareholder: undefined,
            pep_members: undefined,
          },
        },
      },
      references: {
        clients: [],
        suppliers: [],
      },
    },
    guarantee: {
      completed: false,
      files: [],
      bank_accounts: [
        {
          id: undefined,
          clabe: "",
          bank_name: "",
          bank_statements: [
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
            {
              id: undefined,
              file_category_id: "00000000-0000-0000-0000-000000000000",
              url: "",
              status: true,
              created_at: "",
              updated_at: "",
              file_code: "",
              file_name: "",
              validated: null,
            },
          ],
        },
      ],
      docs_info: {
        info: false,
        indenti: false,
        info_bank: false,
        files: [],
      },
      id_address: "",
      id_pepsFamiliar: "",
      id_pepsPropio: "",
    },
    actual_owner: {
      completed: false,
      owners: [{ name: "", second_lastname: "", first_lastname: "", id: "", files: [], information_person_completed: null, file_anexo_status: null }],
      current_owner_id: null,
    },
  },
};

export const bofuSlice = createSlice({
  name: "bofu",
  initialState,
  reducers: {
    setCompanyDocument: (state, action: PayloadAction<UploadedFile>) => {
      if (!state.digital_file.company.files || !Array.isArray(state.digital_file.company.files)) {
        state.digital_file.company.files = [];
      }

      state.digital_file.company.files.push(action.payload);
    },
    removeCompanyDocument: (state, action: PayloadAction<UUID>) => {
      state.digital_file.company.files = state.digital_file.company.files.filter((docs) => docs.id !== action.payload);
    },
    setCompanyCompleted: (state) => {
      state.digital_file.company.completed = true;
    },
    setGuaranteeCompleted: (state) => {
      state.digital_file.guarantee.completed = true;
    },
    setCompleteInfoGuarantee: (state) => {
      state.digital_file.guarantee.docs_info.info = true;
    },
    setAddresIdGuarantee: (state, action: PayloadAction<string>) => {
      state.digital_file.guarantee.id_address = action.payload;
    },
    setCompanyBankAccount: (state) => {
      const bankAccount: BankAccount = {
        id: "00000000-0000-0000-0000-000000000000",
        clabe: "",
        bank_name: "",
        bank_statements: [],
      };
      state.digital_file.company.bank_accounts.push(bankAccount);
    },
    setCompanyBankAccountFile: (
      state,
      action: PayloadAction<{ parentIndex: number; indexFile: number; uploadedFile: UploadedFile }>,
    ) => {
      if (
        state.digital_file.company.bank_accounts[action.payload.parentIndex].bank_statements.at(
          action.payload.indexFile,
        )
      ) {
        state.digital_file.company.bank_accounts[action.payload.parentIndex].bank_statements[action.payload.indexFile] =
          action.payload.uploadedFile;
      } else {
        state.digital_file.company.bank_accounts[action.payload.parentIndex].bank_statements.push(
          action.payload.uploadedFile,
        );
      }
    },
    removeCompanyBankAccountFile: (state, action: PayloadAction<{ parentIndex: number; indexFile: number }>) => {
      state.digital_file.company.bank_accounts[action.payload.parentIndex].bank_statements[action.payload.indexFile] = {
        id: undefined,
        file_category_id: "00000000-0000-0000-0000-000000000000",
        url: "",
        status: true,
        validated: null,
        created_at: "",
        updated_at: "",
        file_code: "",
        file_name: "",
      };
    },
    setCompanyTransactionalInfo: (
      state,
      action: PayloadAction<{
        paymentMethods: Array<UUID>;
        paymentDetailsId: UUID;
        advancePayments: boolean;
        resourcesTypes: boolean;
      }>,
    ) => {
      if (state.digital_file.company.loan_application) {
        state.digital_file.company.loan_application.transactional_info.payment_methods = action.payload.paymentMethods;

        state.digital_file.company.loan_application.transactional_info.details.id = action.payload.paymentDetailsId;
        state.digital_file.company.loan_application.transactional_info.details.advance_payments =
          action.payload.advancePayments;
        state.digital_file.company.loan_application.transactional_info.details.own_resources =
          action.payload.resourcesTypes;
      }
    },
    setCompanyPepInfo: (state, action: PayloadAction<{ pepShareholder: boolean; pepMembers: boolean }>) => {
      state.digital_file.company.loan_application.transactional_info.details.pep_members = action.payload.pepMembers;
      state.digital_file.company.loan_application.transactional_info.details.pep_shareholder =
        action.payload.pepShareholder;
    },
    setCompanyLegalAgentData: (state, action: PayloadAction<LegalAgent>) => {
      state.digital_file.company.loan_application.legal_agent = action.payload;
    },
    setCompanyClientReference: (state, action: PayloadAction<{ clientNumber: number; reference: Reference }>) => {
      state.digital_file.company.references.clients[action.payload.clientNumber] = action.payload.reference;
    },
    setCompanySupplierReference: (state, action: PayloadAction<{ clientNumber: number; reference: Reference }>) => {
      state.digital_file.company.references.suppliers[action.payload.clientNumber] = action.payload.reference;
    },
    restoreBofuCompanyData: (
      state,
      action: PayloadAction<{
        files: Array<UploadedFile>;
        bank_accounts: Array<BankAccount>;
        loan_application: LoanApplicationCompany;
      }>,
    ) => {
      if (action.payload.files) {
        state.digital_file.company.files = action.payload.files;
      }

      if (action.payload.bank_accounts) {
        state.digital_file.company.bank_accounts = action.payload.bank_accounts;
      }
      if (!action.payload.bank_accounts) {
        state.digital_file.company.bank_accounts

      }

      if (action.payload.loan_application) {
        if (action.payload.loan_application.transactional_info) {
          state.digital_file.company.loan_application.transactional_info =
            action.payload.loan_application.transactional_info;
        }

        if (action.payload.loan_application.legal_agent) {
          if (action.payload.loan_application.legal_agent.operative_address) {
            state.digital_file.company.loan_application.legal_agent.operative_address =
              action.payload.loan_application.legal_agent.operative_address;
          }

          if (action.payload.loan_application.legal_agent.company) {
            state.digital_file.company.loan_application.legal_agent.company =
              action.payload.loan_application.legal_agent.company;
          }

          state.digital_file.company.loan_application.legal_agent.code_phone =
            action.payload.loan_application.legal_agent.code_phone;
          state.digital_file.company.loan_application.legal_agent.email =
            action.payload.loan_application.legal_agent.email;
          state.digital_file.company.loan_application.legal_agent.phone_number =
            action.payload.loan_application.legal_agent.phone_number;
        }
      }
    },
    setGuarenteeDocument: (state, action: PayloadAction<UploadedFile>) => {
      if (!state.digital_file.guarantee.files || !Array.isArray(state.digital_file.guarantee.files)) {
        state.digital_file.guarantee.files = [];
      }

      state.digital_file.guarantee.files.push(action.payload);
    },
    removeGuaranteeDocument: (state, action: PayloadAction<UUID>) => {
      state.digital_file.guarantee.files = state.digital_file.guarantee.files.filter(
        (docs) => docs.id !== action.payload,
      );
    },
    restoreBofuGuaranteeData: (
      state,
      action: PayloadAction<{ files: Array<UploadedFile>; bank_accounts: Array<BankAccount> }>,
    ) => {
      state.digital_file.guarantee.files = action.payload.files;
      if (action.payload.bank_accounts) {
        state.digital_file.guarantee.bank_accounts = action.payload.bank_accounts;
      }
    },
    setGuaranteeBankAccountFile: (
      state,
      action: PayloadAction<{ parentIndex: number; indexFile: number; uploadedFile: UploadedFile }>,
    ) => {
      if (
        state.digital_file.guarantee.bank_accounts[action.payload.parentIndex].bank_statements.at(
          action.payload.indexFile,
        )
      ) {
        state.digital_file.guarantee.bank_accounts[action.payload.parentIndex].bank_statements[
          action.payload.indexFile
        ] = action.payload.uploadedFile;
      } else {
        state.digital_file.guarantee.bank_accounts[action.payload.parentIndex].bank_statements.push(
          action.payload.uploadedFile,
        );
      }
    },
    removeGuaranteBankAccountFile: (state, action: PayloadAction<{ parentIndex: number; indexFile: number }>) => {
      state.digital_file.guarantee.bank_accounts[action.payload.parentIndex].bank_statements[action.payload.indexFile] =
      {
        id: undefined,
        file_category_id: "00000000-0000-0000-0000-000000000000",
        url: "",
        status: true,
        validated: null,
        created_at: "",
        updated_at: "",
        file_code: "",
        file_name: "",
      };
    },
    setGuaranteeBankAccount: (state) => {
      const bankAccount: BankAccount = {
        id: "00000000-0000-0000-0000-000000000000",
        clabe: "",
        bank_name: "",
        bank_statements: [],
      };
      state.digital_file.guarantee.bank_accounts.push(bankAccount);
    },
    setActualOwnerDocument: (
      state,
      action: PayloadAction<{ parentIndex: number; indexFile: number; uploadedFile: UploadedFile }>
    ) => {
      const { parentIndex, indexFile, uploadedFile } = action.payload;
      const ownerFiles = state.digital_file.actual_owner.owners[parentIndex].files;

      if (ownerFiles.at(indexFile)) {
        ownerFiles[indexFile] = uploadedFile;
      } else {
        ownerFiles.push(uploadedFile);
      }
    },
    removeActualOwnerDocument: (state, action: PayloadAction<{ parentIndex: number; indexFile: number }>) => {
      state.digital_file.actual_owner.owners[action.payload.parentIndex].files[action.payload.indexFile] =
      {
        id: undefined,
        file_category_id: "00000000-0000-0000-0000-000000000000",
        url: "",
        status: true,
        validated: null,
        created_at: "",
        updated_at: "",
        file_code: "",
        file_name: "",
      };
    },
    restoreBofuOwnersData: (state, action: PayloadAction<Array<Owner>>) => {
      state.digital_file.actual_owner.owners = action.payload;
    },

    setCurrentOwnerId: (state, action: PayloadAction<string>) => {
      state.digital_file.actual_owner.current_owner_id = action.payload;
    },
    clearCurrentOwnerId: (state) => {
      state.digital_file.actual_owner.current_owner_id = null;
    },

    removeCompanyBankAccount: (state, action: PayloadAction<number>) => {
      state.digital_file.company.bank_accounts = state.digital_file.company.bank_accounts.filter(
        (_, index) => index !== action.payload,
      );
    },

    removeGuaranteeBankAccount: (state, action: PayloadAction<number>) => {
      state.digital_file.guarantee.bank_accounts = state.digital_file.guarantee.bank_accounts.filter(
        (_, index) => index !== action.payload,
      );
    },

    setCompanyBankAccountUpdateId: (state, action: PayloadAction<{ index: number; id: string | any }>) => {
      state.digital_file.company.bank_accounts[action.payload.index].id = action.payload.id;
    },
    setGuaranteeBankAccountUpdateId: (state, action: PayloadAction<{ index: number; id: string | any }>) => {
      state.digital_file.guarantee.bank_accounts[action.payload.index].id = action.payload.id;
    },

    setCompanyBankAccountUpdate: (
      state,
      action: PayloadAction<{ index: number; clabe: string; bank_name: string }>,
    ) => {
      state.digital_file.company.bank_accounts[action.payload.index].clabe = action.payload.clabe;
      state.digital_file.company.bank_accounts[action.payload.index].bank_name = action.payload.bank_name;
    },
    setGuaranteeBankAccountUpdate: (
      state,
      action: PayloadAction<{ index: number; clabe: string; bank_name: string }>,
    ) => {
      state.digital_file.guarantee.bank_accounts[action.payload.index].clabe = action.payload.clabe;
      state.digital_file.guarantee.bank_accounts[action.payload.index].bank_name = action.payload.bank_name;
    },
    sePepsIdFamyliarGuarantee: (state, action: PayloadAction<string>) => {
      state.digital_file.guarantee.id_pepsFamiliar = action.payload;
    },
    sePepsIdPropioGuarantee: (state, action: PayloadAction<string>) => {
      state.digital_file.guarantee.id_pepsPropio = action.payload;
    },

    setInformationPersonCompletedById: (
      state,
      action: PayloadAction<{ ownerId: string; completedStatus: boolean | null }>
    ) => {
      const { ownerId, completedStatus } = action.payload;
      const ownerIndex = state.digital_file.actual_owner.owners.findIndex(owner => owner.id === ownerId);

      if (ownerIndex !== -1) {
        state.digital_file.actual_owner.owners[ownerIndex].information_person_completed = completedStatus;
      }
    },

    setAnexoValidationPersonById: (
      state,
      action: PayloadAction<{ ownerId: string; completedStatus: boolean | null }>
    ) => {
      const { ownerId, completedStatus } = action.payload;
      const ownerIndex = state.digital_file.actual_owner.owners.findIndex(owner => owner.id === ownerId);

      if (ownerIndex !== -1) {
        state.digital_file.actual_owner.owners[ownerIndex].file_anexo_status = completedStatus;
      }
    }
  },
});

export const {
  setCompanyDocument,
  removeCompanyDocument,
  setCompanyCompleted,
  setGuaranteeCompleted,
  setCompleteInfoGuarantee,
  setCompanyBankAccount,
  setCompanyBankAccountFile,
  removeCompanyBankAccountFile,
  setCompanyTransactionalInfo,
  setCompanyPepInfo,
  setCompanyLegalAgentData,
  setCompanyClientReference,
  setCompanySupplierReference,
  restoreBofuCompanyData,
  setGuarenteeDocument,
  removeGuaranteeDocument,
  restoreBofuGuaranteeData,
  setGuaranteeBankAccountFile,
  removeGuaranteBankAccountFile,
  setGuaranteeBankAccount,
  setActualOwnerDocument,
  removeActualOwnerDocument,
  setAddresIdGuarantee,
  restoreBofuOwnersData,
  setCurrentOwnerId,
  clearCurrentOwnerId,
  removeCompanyBankAccount,
  removeGuaranteeBankAccount,
  setCompanyBankAccountUpdateId,
  setGuaranteeBankAccountUpdateId,
  setCompanyBankAccountUpdate,
  setGuaranteeBankAccountUpdate,
  sePepsIdFamyliarGuarantee,
  sePepsIdPropioGuarantee,
  setInformationPersonCompletedById,
  setAnexoValidationPersonById,
} = bofuSlice.actions;
export default bofuSlice.reducer;
