/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Spinner } from "@components/index";
import { useGetAccountUserByIdQuery } from "@api/accountUser";
import { signOut } from "aws-amplify/auth";
import icon from "@assets/img/icon_bot.svg";
import { RootState } from "@store/store";

import img from "@assets/img/right_11.svg";

import { InactivityHandler } from "@components/InactivityHandler";

export const Rechazo = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const personalData = useSelector((state: RootState) => state.register.personal_data);

  const {
    data: accountData,
    error: accountError,
    isLoading: accountIsLoading,
  } = useGetAccountUserByIdQuery(personalData.id);

  useEffect(() => {
    if (accountError) {
      console.error("Error al obtener la data:", accountError);
    }

    if (accountData) {
      const fullName = `${accountData.data.name} ${accountData.data.last_name} ${accountData.data.last_name_2}`;

      setName(fullName);
    }
  }, [accountData, accountError, accountIsLoading]);

  const goTo = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <>
      <Spinner open={accountIsLoading} />
      <Grid container>
        {/* Form Section */}
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography color={"#528CD6"} fontWeight={"500"} align="center" fontSize={"22px"}>
                ¡Hola {name}!
              </Typography>
            </Box>

            <Box sx={{ mt: 1, mb: 1 }}>
              <p className="text-solicitud-subtitulo text-center">
                Lo sentimos mucho, en este momento no podemos autorizar tu solicitud de crédito
              </p>
            </Box>

            <Box sx={{ mt: 2, mb: 2 }}>
              <img src={icon} alt="" />
            </Box>

            <Box sx={{ mt: 1, mb: 1 }}>
              <Typography color={"#528CD6"} fontWeight={"500"} align="center" fontSize={"15px"}>
                ¡No te desanimes!
              </Typography>
            </Box>

            <Box sx={{ mt: 1, mb: 1 }}>
              <p className="text-solicitud-subtitulo text-center">Puedes volver a intentarlo en 3 meses</p>
            </Box>

            <Box sx={{ mt: 1, mb: 1 }}>
              <div className="pt-10">
                <button className="btn-registro" onClick={goTo}>
                  Salir
                </button>
              </div>
            </Box>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
