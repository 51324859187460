import React, { useState } from "react";
import { AppBar, Box, Button, IconButton, Menu, MenuItem, SxProps, Toolbar, Typography } from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { Link, useNavigate } from "react-router-dom";
import { ContactInfo, NotificationInfo, NotificationCardProps } from "@interfaces/index";
import ImageProfile from "@components/NavBar/ImageProfile";

import logo_lendia from "@assets/img/Logo.svg";
import icon_menu_arrow_down from "@assets/img/icon_menu_arrow_down.svg";
import icon_menu_notifications from "@assets/img/icon_menu_notifications.svg";
import icon_menu_profile from "@assets/img/icon_menu_profile.svg";
import icon_whatsapp from "@assets/img/icon_whatsapp.svg";
import icon_notifications_bell from "@assets/img/icon_notifications_bell.svg";
import icon_notifications_dots from "@assets/img/icon_notifications_dots.svg";
import image_navbar_adornment from "@assets/img/image_navbar_adornment.svg";

const styleMenu: SxProps = {
  fontFamily: "Outfit",
  fontWeight: "600px",
  fontSize: "16px",
  lineHeight: "19.5px",
  color: "#000000",
};

const styleMenuItem: SxProps = {
  fontFamily: "Outfit",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "19.5px",
  color: "#002652",
  my: "5px",
};

const styleMenuButton: SxProps = {
  fontFamily: "Outfit",
  fontSize: "14px",
  lineHeight: "17.64px",
  fontWeight: 500,
  height: "36px",
};

const styleLink: React.CSSProperties = {
  textDecoration: "none",
  width: "100%",
  height: "100%",
  color: "inherit",
};

const NotificationEmpty = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "327px",
      }}
    >
      <img src={icon_notifications_bell} alt="notifications-bell" width={87} height={98} />
      <Typography
        fontFamily="Montserrat"
        fontWeight={600}
        fontSize="16px"
        lineHeight="19.5px"
        color="#002652"
        textAlign="center"
        mt={2}
      >
        No tienes notificaciones pendientes
      </Typography>
      <Typography
        fontFamily="Montserrat"
        fontWeight={400}
        fontSize="13px"
        lineHeight="15.85px"
        color="#002652"
        textAlign="center"
        mt={1}
      >
        Te avisaremos cuando algo nuevo llegue
      </Typography>
    </Box>
  );
};

const NotificationCard = ({ data }: NotificationCardProps) => {
  return (
    <Box
      sx={{
        borderBottom: "1px solid #E3F2F8",
        width: "327px",
        paddingTop: "10px",
        paddingBottom: "5px",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="start" justifyContent="space-between">
        <Typography fontFamily="Montserrat" fontWeight={600} fontSize="16px" lineHeight="19.5px" color="#002652">
          {data.title}
        </Typography>
        <IconButton>
          <img src={icon_notifications_dots} alt="notifications-bell" width={14} height={14} />
        </IconButton>
      </Box>

      <Typography fontFamily="Montserrat" fontWeight={400} fontSize="13px" lineHeight="15.85px" color="#002652" mt={1}>
        {data.content}
      </Typography>

      <Typography
        fontFamily="Outfit"
        fontWeight={300}
        fontSize="11px"
        lineHeight="13.86px"
        textAlign="right"
        color="#002652"
        mt={1}
      >
        {data.date}
      </Typography>
    </Box>
  );
};

const MenuProducts = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSimpleCredit = () => {
    setAnchorEl(null);
    console.log("Credito Simple");
  };

  return (
    <Box>
      <Button
        id="positioned-button-products"
        aria-controls={open ? "positioned-menu-products" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={styleMenu}
      >
        <Box display="flex" flexDirection="row" gap={1}>
          Productos <img src={icon_menu_arrow_down} alt="menu-arrow-down" />
        </Box>
      </Button>
      <Menu
        id="positioned-menu-products"
        aria-labelledby="positioned-button-products"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickSimpleCredit} sx={styleMenuItem}>
          Credito Simple
        </MenuItem>
      </Menu>
    </Box>
  );
};

const MenuQueries = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickInvoices = () => {
    setAnchorEl(null);
    console.log("Facturas");
  };

  const handleClickStatements = () => {
    setAnchorEl(null);
    console.log("Estados de cuenta");
  };

  const handleClickPaymentReceipts = () => {
    setAnchorEl(null);
    console.log("Recibos de pago");
  };

  const handleClickContracts = () => {
    setAnchorEl(null);
    console.log("Contratos");
  };

  return (
    <Box>
      <Button
        id="positioned-button-queries"
        aria-controls={open ? "positioned-menu-queries" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={styleMenu}
      >
        <Box display="flex" flexDirection="row" gap={1}>
          Consultas <img src={icon_menu_arrow_down} alt="menu-arrow-down" />
        </Box>
      </Button>
      <Menu
        id="positioned-menu-queries"
        aria-labelledby="positioned-button-queries"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickInvoices} sx={styleMenuItem}>
          <Link to={"/home/consulta_facturas"} style={styleLink}>
            Facturas
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClickStatements} sx={styleMenuItem}>
          Estados de cuenta
        </MenuItem>
        <MenuItem onClick={handleClickPaymentReceipts} sx={styleMenuItem}>
          Recibos de pago
        </MenuItem>
        <MenuItem onClick={handleClickContracts} sx={styleMenuItem}>
          <Link to={"/home/consulta_contratos"} style={styleLink}>
            Contratos
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const MenuContact = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    whatsapp: "Whatsapp",
    email: "contacto@lenditgroup.com",
    phone: "5591044964",
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSendEmail = () => {
    window.location.href = `mailto:${contactInfo.email}?subject=Hola%20Tengo%20una%20duda&body=Mi%20duda%20es...`;
  };

  const handleSendWhatsapp = () => {
    window.open(`https://wa.me/+52${contactInfo.phone}?text=Hola%20Tengo%20una%20duda...`, "_blank");
  };

  const handleCallPhone = () => {
    window.open(`tel:+52${contactInfo.phone}`, "_blank");
  };

  return (
    <Box>
      <Button
        id="positioned-button-contact"
        aria-controls={open ? "positioned-menu-contact" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={styleMenu}
      >
        <Box display="flex" flexDirection="row" gap={1}>
          Contactanos <img src={icon_menu_arrow_down} alt="menu-arrow-down" />
        </Box>
      </Button>
      <Menu
        id="positioned-menu-contact"
        aria-labelledby="positioned-button-contact"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box maxWidth={"288px"}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2} padding="10px" paddingX="20px">
            <Typography
              fontFamily="Montserrat"
              fontWeight={600}
              fontSize="16px"
              lineHeight="19.5px"
              textAlign="center"
              color="#528CD6"
              width="237px"
            >
              ¿Tienes alguna duda?
            </Typography>

            <Typography
              fontFamily="Montserrat"
              fontWeight={400}
              fontSize="13px"
              lineHeight="15.85px"
              textAlign="center"
              color="#002652"
              width="237px"
            >
              Contáctanos por alguno de nuestros medios
            </Typography>

            <Button fullWidth variant="green" sx={styleMenuButton} onClick={handleSendWhatsapp}>
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <img src={icon_whatsapp} alt="whatsapp" />
                Whatsapp
              </Box>
            </Button>

            <Button variant="blue_outlined" fullWidth sx={styleMenuButton} onClick={handleSendEmail}>
              {contactInfo.email}
            </Button>

            <Button variant="blue_outlined" fullWidth sx={styleMenuButton} onClick={handleCallPhone}>
              {contactInfo.phone}
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

const MenuNotifications = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [notifications] = useState<NotificationInfo[]>([
    {
      id: "1",
      title: "Nuevos beneficios",
      content:
        "Por ser un cliente distinguido, reconocemos tu compromiso y responsabilidad ofreciéndote nuevos beneficios exclusivos",
      date: "2024-08-11",
    },
    {
      id: "2",
      title: "¡Bienvenido/a!",
      content:
        "Ahora podrás estar al tanto de todo lo que sucede con tu crédito, en esta sección de notificaciones te mantendremos al tanto de lo más relevante.",
      date: "2024-08-09",
    },
  ]);

  return (
    <Box mr={1}>
      <IconButton
        id="button-queries"
        aria-controls={open ? "positioned-queries" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={styleMenu}
      >
        <img src={icon_menu_notifications} width={24.38} height={30} />
      </IconButton>
      <Menu
        id="positioned-queries"
        aria-labelledby="button-queries"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiMenu-paper": {
            width: "387px",
            px: "10px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
            py: "15px",
          }}
        >
          {notifications.length === 0 && <NotificationEmpty />}

          {notifications.map((notification, index) => (
            <NotificationCard key={index} data={notification} />
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

const MenuAccount = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    console.log("Cerrar sesión");
    setAnchorEl(null);
    await signOut();
    navigate("/login");
  };

  return (
    <Box>
      <IconButton
        id="button-queries"
        aria-controls={open ? "positioned-queries" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={styleMenu}
      >
        <img src={icon_menu_profile} width={30} height={30} />
      </IconButton>
      <Menu
        id="positioned-queries"
        aria-labelledby="button-queries"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleSignOut} sx={styleMenuItem}>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </Box>
  );
};

const AdornmentImage = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "90px",
        right: "0px",
        zIndex: 1,
      }}
    >
      <img src={image_navbar_adornment} alt="adornment" width={78} height={78} />
    </Box>
  );
};

export const NavbarAuthenticated = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "#E3F2F8",
        height: "203px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        gap: "10px",
      }}
    >
      <ImageProfile />
      <AdornmentImage />

      <Box
        sx={{
          paddingLeft: "215px",
          height: "30px",
        }}
      >
        <img src={logo_lendia} alt="logo-lendia" width={159} height={29} />
      </Box>
      <Toolbar
        sx={{
          bgcolor: "#FFFFFF",
          height: "60px",
        }}
      >
        <Box
          height={53}
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          paddingRight={6}
        >
          <MenuProducts />
          <MenuQueries />
          <MenuContact />
          <MenuNotifications />
          <MenuAccount />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
