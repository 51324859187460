import { Box, Button, Container, Grid, Link, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { EMAIL, PHONE_NUMBER } from "@utils/expreciones";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import QRCode from "react-qr-code";
import img from "@assets/img/img_tofu/img_tofu_10.svg";
import * as yup from "yup";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { useSelector } from "react-redux";
import { usePutApplicationCompletedMutation } from "@api/applications";
import { RootState } from "@store/store";
import { InactivityHandler } from "@components/InactivityHandler";


const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Campo requerido")
    .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
  country_code: yup.string().required("Campo requerido"),
  phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
});

export const ValidarIndentidadPfae = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const applicationData = useSelector((state: RootState) => state.register.application);

  const personal = useSelector((state: RootState) => state.register.personal_data);


  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();

  const initialValues = {
    email: "",
    country_code: "+52",
    phone: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        console.log(values);
        const data = {
          identity_validation_completed: true,
        };
        await putAapplicationCompleted({ id_sol: applicationData.id, body: data });
        window.location.href = '/producto/creditosimple/avance_expediente_digitalPFAE';
      }
    },
  });

  const onChangeEmail = (value: string) => {
    const low = value.toLowerCase();
    formik.setFieldValue("email", low);
  };
  return (
    <>
      {/* <Spinner open={isLoading} /> */}
      <Grid container>
        {/* Form Section */}
        <Grid item xs={12} md={8}>
        <InactivityHandler/>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box my={1}>
              <Typography align={"center"} fontSize={"14px"} fontWeight={600} color={"#002652"}>
                Ahora validaremos la identidad del dueño del negocio
              </Typography>
            </Box>

            <Typography fontSize={"14px"} fontWeight={400} color={"#528CD6"} align="center" mt={3} mb={3}>
              {`${personal.name} ${personal.first_lastname} ${personal.second_lastname}`}
            </Typography>

            <Typography fontSize={"14px"} fontWeight={400} color={"#002652"} align="center" mt={3} mb={3}>
              Necesitamos validar la identidad del negocio, escanea el siguiente código QR o da click en el link, para
              realizarlo a través de tu teléfono
            </Typography>

            <Typography align={"center"} fontSize={"14px"} fontWeight={600} color={"#002652"} mt={3} mb={3}>
              Deberas tener tu identificación oficial a la mano y deberás tomarte un par de selfies
            </Typography>

            <Link mt={3} mb={3}>
              <Typography align={"center"} fontSize={"14px"} color={"#528CD6"}>
                wwww.hjdhhfurhurhurhr.com.mx
              </Typography>
            </Link>

            <Typography fontSize={"14px"} fontWeight={400} color={"#002652"} align="center" mt={3} mb={3}>
              Proporciona los siguientes datos en caso de que necesites enviar un enlace al aval para que valide su
              identidad
            </Typography>

            <Box ml={2} mr={2}>
              <QRCode size={220} value={"hello"} viewBox={`0 0 256 256`} />
            </Box>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  id="email"
                  name="email"
                  label="Correo electrónico"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={(e) => onChangeEmail(e.target.value)}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                />

                <Grid container spacing={1} mt={1}>
                  <Grid item xs={4} sm={4} md={4}>
                    <CustomContryCode
                      label="Código"
                      name="country_code"
                      value={formik.values.country_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                    />
                  </Grid>

                  <Grid item xs={8} sm={8} md={8}>
                    <TextField
                      type="text"
                      name="phone"
                      variant="outlined"
                      label="Teléfono"
                      fullWidth
                      required
                      value={formik.values.phone}
                      onChange={(event) => {
                        formik.setFieldValue("phone", event.target.value.replace(/[^0-9]/g, ""));
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1} my={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <Button
                      variant="blue_outlined"
                      type="button"
                      fullWidth
                      onClick={() => navigate("/producto/creditosimple/avance_expediente_digitalPFAE")}
                    >
                      Regresar
                    </Button>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Button variant="default" type="submit" fullWidth disabled={!formik.isValid || formik.isSubmitting}>
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
