import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import {
  DocumentFiscalYearMonth,
  ResponseDocumentFiscal,
  CustomStyles,
  DocumentFiscal,
} from "@interfaces/ConsultaFacturas";
import { useLazyGetDocumentsFiscalQuery } from "@api/accountData";
import { useGetUrlToDownloadDocMutation, useGetUrlToDownloadZipMutation } from "@api/documents";
import { forceDownload, getMimeTypeFromFilename, getMonthName } from "@helpers/funciones";
import { TablePagination } from "./TablePagination";
import FiltroAnio from "../../../components/FilterYearMonth";
import ModalFilePreview, { FileParams } from "@components/ModalFilePreview";

const styles: CustomStyles = {
  table_group_title: {
    color: "#002652",
    fontWeight: "bold",
    padding: "16px",
    border: "none",
  },
  table_group_item: {
    color: "#002652",
    fontWeight: "bold",
    border: "1px solid #E0E0E0",
    padding: "16px",
    height: "100%",
    verticalAlign: "middle",
  },
  table_group_button_see: {
    backgroundColor: "#002652",
    color: "#fff",
    minWidth: "100px",
    textTransform: "none",
    marginRight: 0,
    width: "50%",
    borderRadius: "7px 0px 0px 7px",
    height: "100%",
    margin: 0,
    border: "none",
  },
  table_group_button_download: {
    backgroundColor: "#6BA4E6",
    color: "#fff",
    minWidth: "100px",
    textTransform: "none",
    marginRight: 0,
    width: "50%",
    borderRadius: "0px 7px 7px 0px",
    height: "100%",
    margin: 0,
    border: "none",
  },
  table_document_title: {
    color: "#002652",
    fontWeight: "bold",
    padding: "16px",
    textAlign: "center",
  },
  table_document_item: {
    color: "#002652",
    padding: "0px",
    fontWeight: "bold",
    border: "1px solid #E0E0E0",
    textAlign: "center",
  },
  table_document_button_see: {
    backgroundColor: "#002652",
    color: "#fff",
    width: "50%",
    borderRadius: "7px 0px 0px 7px",
    height: "100%",
    border: "none",
  },
  table_document_button_download: {
    backgroundColor: "#6BA4E6",
    color: "#fff",
    width: "50%",
    borderRadius: "0px 7px 7px 0px",
    height: "100%",
    border: "none",
  },
};

interface FilterParams {
  startYear: number | null;
  startMonth: number | null;
  endYear: number | null;
  endMonth: number | null;
}

export const ConsultaFacturas: React.FC = () => {
  const loanData = useSelector((state: RootState) => state.register);
  const [getDocumentsFiscal] = useLazyGetDocumentsFiscalQuery();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [triggerPostUrlZip] = useGetUrlToDownloadZipMutation();
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [_isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [documents, setDocuments] = useState<DocumentFiscalYearMonth[]>([]);
  const [documentsFiltered, setDocumentsFiltered] = useState<DocumentFiscalYearMonth[]>([]);

  const [fileParams, setFileParams] = useState<FileParams | null>(null);
  const [paginationParams, setPaginationParams] = useState({
    startIndex: 0,
    endIndex: 5,
  });
  const [filterParams, setFilterParams] = useState<FilterParams>({
    startYear: null,
    startMonth: null,
    endYear: null,
    endMonth: null,
  });

  const handleToggleExpand = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const seeDocument = async (document: DocumentFiscal) => {
    try {
      const response_url = await triggerPostUrlDocument(document.url).unwrap();
      setFileParams({
        file_name: document.file_name,
        file_url: response_url,
      });
    } catch (error) {
      console.error("Error al obtener el documento", error);
    }
  };

  const downloadDocument = async (document: DocumentFiscal) => {
    try {
      setIsDownloading(true);
      const response_url = await triggerPostUrlDocument(document.url).unwrap();
      const file = await fetch(response_url);
      const blob = await file.blob();
      const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(document.file_name) }));
      forceDownload(url, document.file_name);
    } catch (error) {
      console.error("Error al descargar el documento", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const downloadAllDocuments = async (row: DocumentFiscalYearMonth) => {
    try {
      setIsDownloading(true);
      const files_to_download: any = [];
      row.documents.forEach((document: DocumentFiscal) => {
        files_to_download.push({
          file_key: document.url,
          file_name: document.file_name,
        });
      });
      const response_url = await triggerPostUrlZip(files_to_download).unwrap();
      const file = await fetch(response_url);
      const blob = await file.blob();
      const url = window.URL.createObjectURL(new Blob([blob], { type: "application/zip" }));
      forceDownload(url, `Facturas_${row.year}_${row.month}.zip`);
    } catch (error) {
      console.error("Error al descargar todos los documentos", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);

      if (!loanData || !loanData.application || !loanData.application.id) {
        return;
      }

      const response_documents = await getDocumentsFiscal({
        loan_application_id: loanData.application.id,
        size: 500,
      }).unwrap();

      const resultados: DocumentFiscalYearMonth[] = [];

      response_documents.forEach((item: ResponseDocumentFiscal) => {
        if (item.year_month) {
          const date_parts = item.year_month.split("_");
          const year = parseInt(date_parts[0]);
          const month = parseInt(date_parts[1]);
          const yearMonthIndex = resultados.findIndex((item) => item.year === year && item.month === month);

          if (yearMonthIndex === -1) {
            const new_item: DocumentFiscalYearMonth = {
              year: year,
              month: month,
              month_name: getMonthName(month),
              documents: [
                {
                  number: item.invoice_id,
                  file_name: item.file_name,
                  url: item.url,
                },
              ],
            };
            resultados.push(new_item);
          } else {
            resultados[yearMonthIndex].documents.push({
              number: item.invoice_id,
              file_name: item.file_name,
              url: item.url,
            });
          }
        }
      });

      resultados.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        } else {
          return a.year - b.year;
        }
      });

      setDocuments(resultados);
    } catch (error) {
      console.error("Error al obtener los documentos", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterAndPaginate = () => {
    const { startYear, startMonth, endYear, endMonth } = filterParams;
    let documents_filtered = documents;

    if (startYear !== null && startMonth !== null) {
      documents_filtered = documents.filter((item) => {
        return item.year >= (startYear || 0) && item.month >= (startMonth || 0);
      });
    }

    if (endYear !== null && endMonth !== null) {
      documents_filtered = documents_filtered.filter((item) => {
        return item.year <= (endYear || 0) && item.month <= (endMonth || 0);
      });
    }

    setDocumentsFiltered(documents_filtered);
  };

  const handleClearFilters = () => {
    setFilterParams({
      startYear: null,
      startMonth: null,
      endYear: null,
      endMonth: null,
    });
  };

  const handleSetFilterStartYear = (year: number, month: number) => {
    setFilterParams({
      ...filterParams,
      startYear: year,
      startMonth: month,
    });
  };

  const handleSetFilterEndYear = (year: number, month: number) => {
    setFilterParams({
      ...filterParams,
      endYear: year,
      endMonth: month,
    });
  };

  useEffect(() => {
    filterAndPaginate();
  }, [documents, paginationParams, filterParams]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        padding: 4,
      }}
    >
      <Box
        sx={{
          marginBottom: 4,
        }}
      >
        <Typography
          color={"#F2704F"}
          fontSize={"16px"}
          fontWeight={600}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon sx={{ marginRight: "5px" }} />
          Regresar a inicio
        </Typography>

        <Typography color={"#002652"} fontSize={"31px"} fontWeight={600} mt={2}>
          Facturas
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            backgroundColor: "#fff",
            width: "70%",
          }}
        >
          <Box display="flex" justifyContent="flex-end" marginBottom={4}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Typography color="#002652" sx={{ fontWeight: "bold" }}>
                  Consulta un período:
                </Typography>
              </Grid>
              <Grid item>
                <FiltroAnio
                  onChange={handleSetFilterStartYear}
                  lastSelection={
                    filterParams.startYear && filterParams.startMonth
                      ? {
                          year: filterParams.startYear,
                          month: filterParams.startMonth,
                        }
                      : undefined
                  }
                />
              </Grid>
              <Grid item>
                <Typography color="#002652" sx={{ fontWeight: "bold" }}>
                  a
                </Typography>
              </Grid>
              <Grid item>
                <FiltroAnio
                  onChange={handleSetFilterEndYear}
                  lastSelection={
                    filterParams.endYear && filterParams.endMonth
                      ? {
                          year: filterParams.endYear,
                          month: filterParams.endMonth,
                        }
                      : undefined
                  }
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    height: "45px",
                  }}
                  onClick={handleClearFilters}
                >
                  Borrar filtros
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" justifyContent="center" marginBottom={4}>
            <TableContainer component={Box} sx={{ overflow: "hidden", boxShadow: "none" }}>
              <Table sx={{ borderSpacing: "0 15px", borderCollapse: "separate" }}>
                <TableHead>
                  <TableRow sx={{ border: "none" }}>
                    <TableCell sx={styles.table_group_title}>Mes</TableCell>
                    <TableCell sx={styles.table_group_title}>Año</TableCell>
                    <TableCell sx={styles.table_group_title}>Número de facturas</TableCell>
                    <TableCell sx={styles.table_group_title}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentsFiltered.slice(paginationParams.startIndex, paginationParams.endIndex).map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow sx={{ border: "none", backgroundColor: "#fff" }}>
                        <TableCell sx={styles.table_group_item} align="center">
                          {row.month_name}
                        </TableCell>
                        <TableCell sx={styles.table_group_item} align="center">
                          {row.year}
                        </TableCell>
                        <TableCell sx={styles.table_group_item} align="center">
                          {row.documents.length}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <Box display="flex" justifyContent="center" height="100%">
                            <Button
                              variant="contained"
                              onClick={() => handleToggleExpand(index)}
                              sx={styles.table_group_button_see}
                            >
                              {expandedRow === index ? "Ocultar" : "Ver"}
                              {expandedRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Button>
                            <Button
                              variant="contained"
                              sx={styles.table_group_button_download}
                              onClick={() => downloadAllDocuments(row)}
                              disabled={isDownloading}
                            >
                              Descargar
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {expandedRow === index && (
                        <TableRow>
                          <TableCell colSpan={4} sx={{ padding: 0 }}>
                            <Box sx={{ width: "60%", margin: "0 auto", paddingY: 0 }}>
                              <Table sx={{ marginTop: 0 }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={styles.table_document_title}>No.</TableCell>
                                    <TableCell sx={styles.table_document_title}>Factura</TableCell>
                                    <TableCell sx={styles.table_document_title}>Acciones</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row.documents.map((factura, index) => (
                                    <TableRow key={index} sx={{ backgroundColor: "#E3F2F8" }}>
                                      <TableCell sx={styles.table_document_item}>{index + 1}</TableCell>

                                      <TableCell sx={styles.table_document_item}>{factura.number}</TableCell>

                                      <TableCell sx={{ padding: "0px" }}>
                                        <Box display="flex" justifyContent="center" height="100%" width="100%">
                                          <Button
                                            variant="contained"
                                            sx={styles.table_document_button_see}
                                            disabled={isDownloading}
                                            onClick={() => seeDocument(factura)}
                                          >
                                            Ver
                                          </Button>
                                          <Button
                                            variant="contained"
                                            sx={styles.table_document_button_download}
                                            disabled={isDownloading}
                                            onClick={() => downloadDocument(factura)}
                                          >
                                            Descargar
                                          </Button>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <TablePagination
            totalItems={documentsFiltered.length}
            onChange={(page: number, rowsPerPage: number) => {
              setPaginationParams({
                startIndex: (page - 1) * rowsPerPage,
                endIndex: page * rowsPerPage,
              });
            }}
          />

          <ModalFilePreview fileParams={fileParams} onClose={() => setFileParams(null)} />
        </Box>
      </Box>
    </Box>
  );
};
