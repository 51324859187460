/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { RootState, useAppDispatch } from "@store/store";
import { LoanConditions } from "@components/LoanConditions";
import { usePostValidateNextStageMutation } from "@api/applications";
import { setModalErrosVisible, setModalQuotationVisible } from "@store/slices/appSlice";

import img from "@assets/img/img_tofu/img_tofu_12.svg";

import icon from "@assets/img/img_tofu/img_tofu_22.svg";
import { InactivityHandler } from "@components/InactivityHandler";

export const ExpedienteDigitalCompleto = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const userData = useSelector((state: RootState) => state.register.application.type);
  const application = useSelector((state: RootState) => state.register.application);

  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const handleOnClickToStart = () => {
    if (userData === import.meta.env.VITE_CODE_TAXS_CS_PFAE) {
      navigate("/producto/creditosimple/informacion_documentos_pfae");
    } else if (userData === import.meta.env.VITE_CODE_TAXS_CS_PM) {
      navigate("/producto/creditosimple/check_list");
    }
  };
  const handleOnClickNext = async () => {
    try {
      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: "CSAVC",
      }).unwrap();
      navigate("/producto/creditosimple/agenda_visita");
    } catch (error: any) {
      if (error?.status === 500 || error?.status === 404 || error?.status === 502) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
        return;
      }
    }
  };

  const handleOnClickShowQuotation = () => {
    dispatch(setModalQuotationVisible(true));
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={4}>
          <Typography mt={4} mb={3} textAlign={"center"} variant="body1" fontWeight={600} color={"#002652"}>
            ¡Felicidades {personalData.name.concat(" ", personalData.first_lastname, " ", personalData.second_lastname)}
            !
          </Typography>
          <Typography mt={4} mb={3} px={2} textAlign={"center"} variant="body1" fontWeight={400} color="#528CD6">
            Tu expediente digital ha sido autorizado
          </Typography>

          <Box mt={2} mb={3}>
            <img src={icon} />
          </Box>

          <Typography mt={4} mb={3} px={2} textAlign={"center"} fontSize={"16px"} fontWeight={400} color="#002652">
            Cada vez estas más cerca de obtener tu crédito
          </Typography>
          <Typography mt={4} mb={3} px={4} textAlign={"center"} fontSize={"16px"} fontWeight={600} color="#002652">
            Revisa las condiciones del crédito que estas solicitando
          </Typography>

          <LoanConditions />

          <Box sx={{ paddingTop: "50px" }}>
            <Button variant="blue_outlined" size="small" onClick={handleOnClickShowQuotation}>
              Modifica tu cotización
            </Button>
          </Box>

          <Grid container mt={4} columnSpacing={2} px={2} justifyContent={"center"}>
            <Grid item xs={"auto"}>
              <Button variant="default" onClick={handleOnClickNext}>
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
