import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SolicitudCreditoState {
  personalData: {
    telefono: string;
    email: string;
    giro: string;
    descripcion: string;
    codigoPostal: string;
    calle: string;
    noInterior: string;
    noExterior: string;
    colonia: string;
    ciudad: string;
    municipio: string;
    entidadFederativa: string;
  };
  transaccionalData: {
    formaPago: string;
    pagosAnticipados: string;
    recursosPropios: string;
  };
  empresaData: {
    socioFuncionario: string;
    conyugeFuncionario: string;
  };
}

const initialState: SolicitudCreditoState = {
  personalData: {
    telefono: '',
    email: '',
    giro: '',
    descripcion: '',
    codigoPostal: '',
    calle: '',
    noInterior: '',
    noExterior: '',
    colonia: '',
    ciudad: '',
    municipio: '',
    entidadFederativa: '',
  },
  transaccionalData: {
    formaPago: '',
    pagosAnticipados: '',
    recursosPropios: '',
  },
  empresaData: {
    socioFuncionario: '',
    conyugeFuncionario: '',
  },
};

const solicitudCreditoSlice = createSlice({
  name: 'solicitudCredito',
  initialState,
  reducers: {
    setPersonalData(state, action: PayloadAction<Partial<SolicitudCreditoState['personalData']>>) {
      state.personalData = { ...state.personalData, ...action.payload };
    },
    setTransaccionalData(state, action: PayloadAction<Partial<SolicitudCreditoState['transaccionalData']>>) {
      state.transaccionalData = { ...state.transaccionalData, ...action.payload };
    },
    setEmpresaData(state, action: PayloadAction<Partial<SolicitudCreditoState['empresaData']>>) {
      state.empresaData = { ...state.empresaData, ...action.payload };
    },
  },
});

export const { setPersonalData, setTransaccionalData, setEmpresaData } = solicitudCreditoSlice.actions;

export default solicitudCreditoSlice.reducer;

