/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Box, Container, TextField, useTheme, useMediaQuery, Button, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RFC_PM } from "@utils/expreciones";
import { useAppDispatch, type RootState } from "@store/store";
import { ProgressBar } from "@components/ProgressBar";
import { setCompanyData } from "@store/slices/registerSlice";
import { useLazyAddCompanyDataQuery } from "@api/account";
import { usePostValidateNextStageMutation } from "@api/applications";
import img from "@assets/img/right_5.svg";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

export const DatosEmpresaPm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [triggerAddCompanyData] = useLazyAddCompanyDataQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const companyData = useSelector((state: RootState) => state.register.company);
  const application = useSelector((state: RootState) => state.register.application);
  const personal_data = useSelector((state: RootState) => state.register.personal_data);

  const formik = useFormik({
    initialValues: {
      rfc: companyData.rfc,
      name: companyData.name,
      legal_name: companyData.legal_name,
    },
    validationSchema: yup.object().shape({
      rfc: yup.string().required("Campo requerido").matches(RFC_PM, "Formato incorrecto, inténtalo de nuevo"),
      name: yup.string().required("Campo requerido"),
      legal_name: yup.string().required("Campo requerido"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        dispatch(
          setCompanyData({
            rfc: values.rfc,
            name: values.name,
            legal_name: values.legal_name,
            role: "",
            ciec_pass: "",
            authorize_sat: false,
          }),
        );

        try {
          await triggerAddCompanyData({
            id: personal_data.id,
            body: {
              rfc: values.rfc,
              name: values.name,
              legal_name: values.legal_name,
            },
          }).unwrap();

          await triggerPostNextStage({
            applicationId: application.id,
            statusCode: "CSDEM",
          }).unwrap();
          navigate("/producto/creditosimple/consulta_ciec");
        } catch (error: any) {
          if (error?.data?.errors) {
            formik.setFieldError("rfc", `Existe una cuenta con RFC - ${values.rfc}`);
            return;
          }
          if (error?.status === 400 || error?.status === 500) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
          }
        }
      }
    },
    validateOnMount: true,
  });

  const handleUpperChange = (values: string) => {
    formik.setFieldValue("rfc", values.toUpperCase());
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={3} mt={4}>
          <Typography mb={3} variant="h4" fontWeight={500}>
            Registro de empresa
          </Typography>
          <Container maxWidth="sm" sx={{ mt: 2, mb: 2, p: 0 }}>
            <ProgressBar progress={3} />
          </Container>

          <Typography mt={4} mb={2} variant="body2" color={"#002652"}>
            Ingresa los datos de la empresa
          </Typography>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                id="rfc"
                name="rfc"
                label="RFC de la empresa"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => handleUpperChange(e.target.value)}
                value={formik.values.rfc}
                error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                helperText={formik.touched.rfc ? formik.errors.rfc : ""}
                inputProps={{ maxLength: 12 }}
              />

              <TextField
                id="name"
                name="name"
                label="Nombre comercial"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name ? formik.errors.name : ""}
                inputProps={{ maxLength: 40 }}
              />

              <TextField
                id="legal_name"
                name="legal_name"
                label="Razón social"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.legal_name}
                error={formik.touched.legal_name && Boolean(formik.errors.legal_name)}
                helperText={formik.touched.legal_name ? formik.errors.legal_name : ""}
                inputProps={{ maxLength: 40 }}
              />

              <Button
                type="submit"
                variant="default"
                sx={{ my: 4 }}
                disabled={!formik.isValid || !(Object.keys(formik.errors).length === 0)}
              >
                Continuar
              </Button>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
