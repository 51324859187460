import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SyntheticEvent } from "react";

interface CustomRadioItemProps {
  value: string | number;
  label: string;
}

interface CustomRadioGroupProps {
  label: string;
  name: string;
  value: string;
  required?: boolean;
  onChange: (event: SyntheticEvent) => void;
  options: CustomRadioItemProps[];
  disabled?: boolean;
  onBlur?: (event: SyntheticEvent) => void;
  error?: boolean;
  helperText?: string | false;
}

const CustomRadioGroup = ({
  label,
  options,
  name,
  value: currentValue,
  onChange,
  onBlur,
  disabled,
  helperText,
  error,
  required,
}: CustomRadioGroupProps) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const sxLabel: SxProps = {
    fontSize: isMobileOrTablet ? 13 : 16,
    color: "#002652",
    "& .MuiFormLabel-asterisk": {
      color: "red",
    },
  };

  const sxLabelItem: SxProps = {
    color: "#002652",
    "& .MuiFormControlLabel-label": {
      fontSize: isMobileOrTablet ? 13 : 16,
    },
  };

  return (
    <>
      <FormControl>
        <FormLabel id={`radio_grouo_${name}`} sx={sxLabel} required={required}>
          {label}
        </FormLabel>
        <RadioGroup aria-labelledby={`radio_grouo_${name}`} defaultValue="female" name={name}>
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              key={option.value.toString()}
              sx={sxLabelItem}
              control={<Radio />}
              label={option.label}
              disabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
              checked={option.value === currentValue}
            />
          ))}
        </RadioGroup>
        {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default CustomRadioGroup;
