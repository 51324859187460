import React, { useState } from "react";
import {
    Box,
    Grid,
    Container,
    TextField,
    Typography,
    Button,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import img from "@assets/img/right_15.svg";
import { EMAIL } from "@utils/expreciones";
import { useRequestPasswordChangeMutation, useGetUserbyEmailMutation } from "@api/onboarding";


export const CambiarContraseña = () => {
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = useState(false);
    const [requestPasswordChange] = useRequestPasswordChangeMutation();
    const [getUserbyEmail] = useGetUserbyEmailMutation();



    const validationSchema = yup.object({
        email: yup
            .string()
            .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)")
            .required("Campo requerido"),
    });


    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {


                const userResponse = await getUserbyEmail({ email: values.email }).unwrap();
                console.log(userResponse);

                if (userResponse && userResponse.id) {

                    await requestPasswordChange({ username: values.email }).unwrap();
                    console.log("Solicitud de cambio de contraseña enviada a:", values.email);
                }
            }
            catch (error) {
                console.error("Error enviando solicitud de cambio de contraseña:", error);
            }
            setLoading(false);
        },
    });

    return (
        <Grid container>
            <Grid item xs={12} md={8}>
                <Box
                    sx={{
                        mt: isMobileOrTablet ? 4 : 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box my={2}>
                        <Typography variant={isMobileOrTablet ? "h3" : "h2"} fontWeight={700} color={"#528CD6"}>
                            Ingresa tu correo electrónico
                        </Typography>
                    </Box>

                    <Box my={4}>
                        <Typography variant={isMobileOrTablet ? "body2" : "body1"} textAlign="center">
                            Te enviaremos un enlace para que generes una nueva contraseña
                        </Typography>
                    </Box>

                    <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <TextField
                                id="email"
                                name="email"
                                label="Correo electrónico"
                                margin="normal"
                                required
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                value={formik.values.email}
                                sx={{ my: 4 }}
                            />

                            <Box textAlign={"center"} mt={6}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={!formik.isValid || loading}
                                    sx={{
                                        backgroundColor: "#528CD6",
                                        color: "#fff",
                                        padding: "10px 24px",
                                        borderRadius: "24px",
                                        textTransform: "none",
                                    }}
                                >
                                    {loading ? "Enviando..." : "Continuar"}
                                </Button>
                            </Box>
                        </form>
                    </Container>
                </Box>
            </Grid>

            {!isMobileOrTablet && (
                <Grid item xs={12} md={4} textAlign={"center"} bgcolor={"#002652"}>
                    <img src={img} alt="Descripción" style={{ height: "calc(100vh - 76px)" }} />
                </Grid>
            )}
        </Grid>
    );
};