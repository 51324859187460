import { styled } from "@mui/material/styles";
import { Slider } from "@mui/material";

import icon from "@assets/img/icon-cotizador.png";

export const StyledSlider = styled(Slider)(({ theme }) => ({
    color: "#002652",
    height: "10px",
    padding: "20px 0 0 0 !important",
    "& .MuiSlider-thumb": {
      height: 26,
      width: 24,
      marginTop: 2,
      backgroundImage: `url(${icon})`,
      backgroundSize: "10px 15px",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      border: "none",
      cursor: "grab",
      backgroundColor: "transparent",
    },
    "& .MuiSlider-thumb::before": {
      boxShadow: "none",
    },
    "& .MuiSlider-markLabel": {
      fontSize: 13,
      fontWeight: 300,
      margin: "-10px 0px 0px 0px !important",
    },
    "& .MuiSlider-rail": {
      opacity: 1,
      backgroundColor: "#E3F2F8",
    },
  }));

  