/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import { FormikErrors, useFormik } from "formik";

import { RootState } from "@store/store";
import { CustomUploadFile } from "@components/CustomUploadFile";
import {
  removeActualOwnerDocument,
  setActualOwnerDocument,
  setCurrentOwnerId,
  setInformationPersonCompletedById,
} from "@store/slices/bofuSlice";

import { UploadedFile } from "@interfaces/store";

import img from "@assets/img/img_tofu/img_tofu_8.svg";
import { Spinner, TabStepBofuPm } from "@components/index";
import { useGetPersonTypeQuery, useGetStatusQuery } from "@api/catalogs";
import { useLazyGetPersonByTypePersonIdQuery, usePatchPersonDataMutation } from "@api/personasApi";
import { PersonaStructure } from "@interfaces/index";
import { useLazyGetDocumentPersonatIdQuery, usePostDocumentGenarateMutation } from "@api/documents";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { usePutApplicationUpdateStatusMutation } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";

interface DocumentDetails {
  client_details_id: string | null;
  created_at: string;
  file_category_id: string;
  file_name: string;
  id: string;
  loan_application_id: string;
  person_id: string;
  sign_client: string | null;
  sign_user: string | null;
  sign_validated: string | null;
  status: boolean;
  updated_at: string;
  url: string;
  validated: string | null;
}

interface OwenerDosc {
  id_owener: string;
  file?: DocumentDetails | null;
  information_person_completed?: string;
}

interface FileASection {
  index: number;
  ownerId: string;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<unknown>> | Promise<void>;
  registerFormik?: (formikLocal: () => Promise<any>, name: string, validationSchema: yup.Schema) => void;
}
const FileSection = ({ setFieldValue, registerFormik, index, ownerId }: FileASection) => {
  const dispatch = useDispatch();

  const [sectionInternalId] = useState<string>(crypto.randomUUID());
  const [validations, setValidations] = useState<yup.ObjectShape>({});

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const ownersSection = useSelector((state: RootState) => state.bofu.digital_file.actual_owner);

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape(validations),
    onSubmit: async (values) => {
      console.log(values);
    },
    validateOnMount: true,
  });

  const registerInternalFormik = (name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
  };

  useEffect(() => {
    registerFormik?.(
      formik.submitForm,
      sectionInternalId,
      yup.boolean().isTrue(`La sección con id ${sectionInternalId} no ha pasado sus validaciones`),
    );
  }, []);

  useEffect(() => {
    setFieldValue?.(sectionInternalId, formik.isValid);
  }, [formik.isValid]);

  const handleUpdateStore = (
    action: "ADD" | "DEL",
    indexFile: number,
    uploadedFile: UploadedFile | null,
    _fileId: `${string}-${string}-${string}-${string}-${string}`,
  ) => {
    if (action === "ADD" && uploadedFile) {
      dispatch(
        setActualOwnerDocument({
          parentIndex: index,
          indexFile: indexFile,
          uploadedFile: uploadedFile,
        }),
      );
    } else if (action === "DEL") {
      dispatch(
        removeActualOwnerDocument({
          parentIndex: index,
          indexFile: indexFile,
        }),
      );
    }
  };

  return (
    <>
      <CustomUploadFile
        py={2}
        index={0}
        updateStore={handleUpdateStore}
        uploadedFile={ownersSection.owners[index].files.find((uploadedFile) => uploadedFile.file_code === "DIO")}
        optionalDescription="Vigente, puede ser INE, Pasaporte o FM2"
        setFieldValue={formik.setFieldValue}
        registerFormik={registerInternalFormik}
        errors={formik.errors}
        metadata={{
          taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
          personCode: "PTPR",
          fileCode: "DIO",
          uploadType: "person",
          loan_application_id: applicationData.id,
          regimen: applicationData.type,
          person_id: ownerId,
          clientDetailsId: personalData.id,
          personType: "propietario_real",
        }}
      />

      <CustomUploadFile
        py={2}
        index={1}
        updateStore={handleUpdateStore}
        uploadedFile={ownersSection.owners[index].files.find((uploadedFile) => uploadedFile.file_code === "DCDF")}
        optionalDescription="Deberá estar actualizada"
        setFieldValue={formik.setFieldValue}
        registerFormik={registerInternalFormik}
        errors={formik.errors}
        metadata={{
          taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
          personCode: "PTPR",
          fileCode: "DCDF",
          uploadType: "person",
          loan_application_id: applicationData.id,
          regimen: applicationData.type,
          person_id: ownerId,
          clientDetailsId: personalData.id,
          personType: "propietario_real",
        }}
      />
    </>
  );
};

export const PropietarioRealPM: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [validations, setValidations] = useState<yup.ObjectShape>({});
  const [formiks, setFormiks] = useState<{ [k: string]: () => Promise<any> }>({});
  const applicationData = useSelector((state: RootState) => state.register.application);
  const ownersSection = useSelector((state: RootState) => state.bofu.digital_file.actual_owner);
  const [personTypeId, setPersonTypeId] = useState("");
  const [validAllPersonaData, setValidAllPersonaData] = useState(false);
  const [validAllPersonaDocs, setValidAllPersonDocs] = useState(false);

  const [statusValidacionId, setStatusValidacionId] = useState("");

  const [listPropietarios, setPropietarios] = useState<PersonaStructure[]>([]);
  const [createDocument, { isLoading: loadigDocCreate }] = usePostDocumentGenarateMutation();
  const [getPropietario, { data: dataPropietario, isSuccess: isSuccessPropietario }] =
    useLazyGetPersonByTypePersonIdQuery();

  const { data: dataStatus, isSuccess: isSuccessStatus } = useGetStatusQuery();
  const [upDateStatus] = usePutApplicationUpdateStatusMutation();
  const { data: personTypeData, isSuccess: typePersonSucess } = useGetPersonTypeQuery();
  const [getDocumentByPersonaId] = useLazyGetDocumentPersonatIdQuery();
  const [patchPersonData] = usePatchPersonDataMutation();

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape(validations),
    onSubmit: async () => {
      if (validAllPersonaData && validAllPersonaDocs) {
        try {
          const data = {
            status_id: statusValidacionId,
          };
          await upDateStatus({ id_sol: applicationData.id, body: data }).unwrap();
          navigate("/producto/creditosimple/validando_expediente_pm");
        } catch (error: any) {
          if (error?.status === 500 || error?.status === 409) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
            return;
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
            return;
          }
        }
      }
    },
  });

  useEffect(() => {
    if (dataStatus) {
      const statusExpediente = dataStatus.find((item) => item.code === "CSEDC");
      setStatusValidacionId(String(statusExpediente?.id));
    }
  }, [dataStatus, isSuccessStatus]);

  useEffect(() => {
    if (personTypeData) {
      const resultPropietario = personTypeData.find((item) => item.code === "PTPR");
      setPersonTypeId(String(resultPropietario?.id));
    }
  }, [personTypeData, typePersonSucess]);

  useEffect(() => {
    if (personTypeId && applicationData) {
      getPropietario({ applicationId: applicationData.id, personId: personTypeId });
    }
  }, [personTypeId, applicationData]);

  useEffect(() => {
    if (dataPropietario) {
      setPropietarios(dataPropietario?.data?.data);
    }
  }, [dataPropietario, isSuccessPropietario]);

  const handleButtonClick = (ownerId: string, navigateTo: string) => {
    dispatch(setCurrentOwnerId(ownerId));
    navigate(navigateTo);
  };

  const registerFormik = (formikLocal: () => Promise<any>, name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
    formiks[name] = formikLocal;
    setFormiks(formiks);

    formik.validateForm();
  };

  useEffect(() => {
    if (listPropietarios) {
      for (const element of listPropietarios) {
        dispatch(
          setInformationPersonCompletedById({
            ownerId: element.id,
            completedStatus: element.information_person_completed,
          }),
        );
      }
    }
  }, [listPropietarios]);

  const [documentsByName, setDocumentsByName] = useState<DocumentDetails[]>([]);
  const [geneateDocumentByOwner, setGeneateDocumentByOwner] = useState<OwenerDosc[]>([]);

  useEffect(() => {
    const fetchDocumentsForOwners = async () => {
      if (listPropietarios && applicationData?.id) {
        let allDocuments: DocumentDetails[] = [];
        const ownerDocuments: OwenerDosc[] = [];

        for (const owner of listPropietarios) {
          try {
            const result: any = await getDocumentByPersonaId({
              personaId: owner.id,
              loanApplicationId: applicationData.id,
            }).unwrap();
            allDocuments = allDocuments.concat(result);
          } catch (error) {
            console.error("Error fetching documents:", error);
          }
          const filteredDataHasData = allDocuments.find(
            (item) => item.file_name.includes("anexo_2") && item.status && item.person_id === owner.id,
          );
          ownerDocuments.push({
            id_owener: String(owner.id),
            information_person_completed: String(`${owner.information_person_completed}`),
            file: filteredDataHasData || null,
          });
        }
        const filteredData = allDocuments.filter((item) => item.file_name.includes("anexo_2") && item.status);
        setGeneateDocumentByOwner(ownerDocuments);
        setDocumentsByName(filteredData);
      }
    };

    fetchDocumentsForOwners();
  }, [listPropietarios, applicationData?.id, getDocumentByPersonaId]);

  const getButom = (id: string) => {
    const filteredDataDoc = documentsByName.find((item) => item.person_id === id);
    const filteredDataOwner = ownersSection.owners.find((item) => item.id === id);

    const docValid = `${filteredDataDoc?.validated}`;
    const infoPersonValid = filteredDataOwner?.information_person_completed;

    const buttonProps = (label: string, bgColor: string, textColor: string, isFilled = false) => ({
      isFilled,
      buttonLabel: label,
      backgroundColor: bgColor,
      color: textColor,
    });

    if (filteredDataDoc !== undefined && filteredDataOwner !== undefined) {
      if (docValid === "null" || docValid === null) {
        if (infoPersonValid === null) {
          return buttonProps("Llenar", "#528CD6", "#FFFFFF");
        }
        if (!infoPersonValid) {
          return buttonProps("Editar", "#F2704F", "#FFFFFF");
        }
        if (infoPersonValid) {
          return buttonProps("Completo", "#002652", "#A3D4E8", true);
        }
      }

      if (docValid === "false") {
        if (infoPersonValid === null) {
          return buttonProps("Llenar", "#528CD6", "#FFFFFF");
        }
        if (!infoPersonValid) {
          return buttonProps("Editar", "#F2704F", "#FFFFFF");
        }
        if (infoPersonValid) {
          return buttonProps("Completo", "#002652", "#A3D4E8", true);
        }
      }

      if (docValid === "true" && infoPersonValid) {
        return buttonProps("Validado", "#A3D4E8", "#528CD6", true);
      }
    } else {
      if (infoPersonValid === null) {
        return buttonProps("Llenar", "#528CD6", "#FFFFFF");
      }
      if (!infoPersonValid) {
        return buttonProps("Editar", "#F2704F", "#FFFFFF");
      }
      if (infoPersonValid) {
        return buttonProps("Completo", "#002652", "#A3D4E8", true);
      }
    }
  };

  const generateSolicitudDocument = async (id: string) => {
    try {
      const data = {
        loan_application_id: String(applicationData.id),
        document_type: "anexo_2",
        person_id: id,
      };

      const documents: any = await createDocument(data);

      if (documents?.error?.status) {
        throw new Error(JSON.stringify({ status: documents.error.status }));
      }
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      let errorType = "";
      if (parsedError.status === 500) {
        errorType = "500";
      } else if (parsedError.status === 400) {
        errorType = "400";
      }
      dispatch(setModalErrosVisible({ open: true, type: errorType }));
    }
  };

  useEffect(() => {
    if (geneateDocumentByOwner.length > 0) {
      geneateDocumentByOwner.forEach(async (owner) => {
        if (owner.file === null && owner.information_person_completed === "true") {
          await generateSolicitudDocument(owner.id_owener);
        }
      });
    }
  }, [geneateDocumentByOwner]);

  useEffect(() => {
    const allOwnersStatusTrue = ownersSection.owners.every((owner) => owner.information_person_completed === true);
    const requiredFileCodes = ["DIO", "DCDF"];

    const allOwnersHaveRequiredFiles = ownersSection.owners.every((owner) => {
      const ownerFileCodes = owner.files.map((file: { file_code: string }) => file.file_code);

      return requiredFileCodes.every((code) => ownerFileCodes.includes(code));
    });
    setValidAllPersonaData(allOwnersStatusTrue);
    setValidAllPersonDocs(allOwnersHaveRequiredFiles);
  }, [ownersSection]);

  const updateStatusComplete = async (id: string) => {
    try {
      const data = {
        completed: null,
        id: id,
      };

      await patchPersonData(data).unwrap();
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    if (listPropietarios.length > 0) {
      listPropietarios.forEach(async (owner) => {
        if (`${owner.completed}` === "false") {
          if (owner.information_person_completed) {
            updateStatusComplete(String(owner.id));
            generateSolicitudDocument(String(owner.id));
            setKey((prevKey) => prevKey + 1);
          }
        }
      });
    }
  }, [listPropietarios]);

  return (
    <Grid container>
      <Spinner open={loadigDocCreate}></Spinner>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={4}>
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600}>
            Expediente digital
          </Typography>

          <TabStepBofuPm key={key} />

          {isSuccessPropietario ? (
            <>
              <form onSubmit={formik.handleSubmit} noValidate>
                {listPropietarios.length > 0 ? (
                  <>
                    {ownersSection.owners.map((owener, index) => (
                      <div key={owener.id} style={{ paddingTop: "20px" }}>
                        <Typography mt={4} variant="body1" sx={{ fontWeight: "bold", fontSize: "16px" }}>
                          Ahora llena los formatos relacionados al o los propietarios reales
                        </Typography>
                        <Typography color={"#002652"} fontSize={"15px"} fontWeight={400}>
                          Propietario real {index + 1}: {owener.name} {owener.first_lastname} {owener.second_lastname}
                        </Typography>
                        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                            Información del propietario real
                          </Typography>

                          <Button
                            variant="default"
                            onClick={() => handleButtonClick(String(owener.id), "../datos_propietario_real_1")}
                            disabled={getButom(String(owener.id))?.isFilled}
                            sx={{
                              width: "160px",
                              fontSize: "15px",
                              height: "30px",
                              paddingTop: 1,
                              backgroundColor: getButom(String(owener.id))?.backgroundColor,
                              color: getButom(String(owener.id))?.color,
                              "&:hover": {
                                backgroundColor: getButom(String(owener.id))?.backgroundColor,
                              },
                              "&.Mui-disabled": {
                                backgroundColor: getButom(String(owener.id))?.backgroundColor,
                                color: getButom(String(owener.id))?.color,
                                opacity: 1,
                              },
                            }}
                          >
                            {getButom(String(owener.id))?.buttonLabel}
                          </Button>
                        </Box>
                        <Box>
                          <FileSection
                            key={index}
                            index={index}
                            ownerId={String(owener.id)}
                            registerFormik={registerFormik}
                            setFieldValue={formik.setFieldValue}
                          />
                        </Box>
                      </div>
                    ))}
                    {isSuccessStatus && (
                      <Button
                        variant="default"
                        type="submit"
                        sx={{ mt: 4, width: "180px", padding: "12px", mb: 5 }}
                        disabled={!validAllPersonaData || !validAllPersonaDocs}
                      >
                        Continuar
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Typography color={"#002652"} fontSize={"20px"} textAlign={"center"} fontWeight={600}>
                      No hay propietarios registrados
                    </Typography>
                  </>
                )}
              </form>
            </>
          ) : (
            <>
              <Box mt={3}>
                <Skeleton variant="rectangular" height={70} />
              </Box>
            </>
          )}
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
