/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Grid, Box, Container, Typography, Button } from "@mui/material";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { signOut } from "aws-amplify/auth";

import { RootState } from "@store/store";
import { useGetApplicationByApplicationIdQuery } from "@api/applications";

import icon from "@assets/img/check_wating_room.svg";

import iconLeft from "@assets/img/icon_left_bottom.svg";
import iconRight from "@assets/img/icon_right_top.svg";

import Confetti from "react-confetti";

import { InactivityHandler } from "@components/InactivityHandler";

export const WaitingRoom = () => {
  const navigate = useNavigate();

  const [timeLeft, setTimeLeft] = useState(10);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [urlNext, setUrlNext] = useState("");
  const application = useSelector((state: RootState) => state.register.application);

  const { data: applicationData, isSuccess } = useGetApplicationByApplicationIdQuery(application.id, {
    skip: !shouldFetch,
  });

  useEffect(() => {
    if (timeLeft > 0) {
      setShouldFetch(false);
      const timerId = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      setUrlNext("");
      setShouldFetch(true);
      setTimeLeft(10);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (applicationData && isSuccess) {
      const url = applicationData.data[0]?.status_catalog?.url_actual;
      setUrlNext(url);
    }
  }, [applicationData, isSuccess]);

  const routes = {
    "/producto/creditosimple/oferta": "/producto/creditosimple/oferta",
    "/producto/creditosimple/credito_rechazado": "/producto/creditosimple/credito_rechazado",
  } as const;

  const handleNavigate = (status: string) => {
    if (status in routes) {
      navigate(routes[status as keyof typeof routes]);
    }
  };

  useEffect(() => {
    if (urlNext) {
      handleNavigate(urlNext);
    }
  }, [urlNext]);

  const goTo = async () => {
    await signOut();
    navigate("/login");
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#002652";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    setShowConfetti(true);
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Grid container sx={{ position: "relative" }}>
        <InactivityHandler />
        {showConfetti && <Confetti className="confetti-container" />}
        <Box
          component="img"
          src={iconRight}
          alt="icono superior derecho"
          sx={{
            position: "absolute",
            top: "22px",
            right: "22px",
            width: "50px",
            height: "50px",
          }}
        />

        <Box
          component="img"
          src={iconLeft}
          alt="icono inferior izquierdo"
          sx={{
            position: "absolute",
            bottom: "-20px",
            left: "22px",
            width: "50px",
            height: "50px",
          }}
        />
        <Grid item xs={12} md={12} mt={4}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Container sx={{ mt: 2, mb: 2 }}>
              <Typography fontSize={"31px"} fontWeight={"600"} align="center" color={"#ffffff"} lineHeight={"39.06px"}>
                ¡Concluiste exitosamente tu solicitud!
              </Typography>
            </Container>

            <Box sx={{ mt: 2, mb: 2 }}>
              <img src={icon} alt="" />
            </Box>

            <Box sx={{ mt: 3, mb: 1 }}>
              <Typography fontWeight={"300"} align="center" color={"#ffffff"} fontSize={"16px"} lineHeight={"20.16px"}>
                <span>
                  Estamos procesando tu solicitud, esto puede <br /> tomar varios minutos.
                </span>
              </Typography>
            </Box>

            <Box sx={{ mt: 3, mb: 1 }}>
              <Typography fontWeight={"300"} align="center" color={"#ffffff"} fontSize={"16px"} lineHeight={"20.16px"}>
                <span>
                  ¡Despreocupate! <br /> Puedes salir de esta pantalla, tan pronto este lista <br /> tu oferta
                  personalizada, te contactaremos vía <br /> WhatsApp, de igual forma puedes iniciar sesión y <br />{" "}
                  consultar tu estatus
                </span>
              </Typography>
            </Box>

            <Box sx={{ mt: 1, mb: 1 }}>
              <Button variant="default" onClick={goTo} sx={{ px: 6, py: 1, mt: 2 }}>
                Salir
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
