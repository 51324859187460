import { payloadSocialMedia } from "@interfaces/socialMedia";
import { baseApi } from "./baseApi";

const socialMediaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postSocialMedia: build.mutation<Response, { client_details_id: string, data: payloadSocialMedia }>({
        query: ({ client_details_id, data }) => ({
            url: `/cuentas/${client_details_id}/social_media`,
            method: "POST",
            body: data,
          }),
    }),
  }),
});

export const {  usePostSocialMediaMutation} = socialMediaApi;