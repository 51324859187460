import {
  Box,
  Button,
  Typography,
  SxProps,
  Modal,
} from "@mui/material";

import icon_error from "@assets/img/icon_error.svg";
import icon_success from "@assets/img/icon_success.svg";

const style: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "327px",
  minHeight: "227px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

interface ModalResultOtpLoginProps {
  isError: boolean;
  title: string;
  message: string;
  handleClose: () => void;
}

export default function ModalResultOtpLogin(props: ModalResultOtpLoginProps) {
  return (
    <Modal open={true} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={style}>
        <Typography id="modal-title" sx={{ mb: 2 }} color={"#002652"} fontSize={"16px"} fontWeight={600}>
          {props.title}
        </Typography>
        {!props.isError ? <img src={icon_success} alt="" /> : <img src={icon_error} alt="" />}

        <Typography id="modal-description" sx={{ mt: 2, mb: 4 }} color={"#002652"} fontSize={"16px"} fontWeight={600}>
          {props.message}
        </Typography>
        {!props.isError ? (
          <Button variant="default" onClick={props.handleClose} sx={{ width: "130px" }}>
            Continuar
          </Button>
        ) : (
          <Button variant="default" onClick={props.handleClose} sx={{ width: "130px" }}>
            Salir
          </Button>
        )}
      </Box>
    </Modal>
  );
}
