import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Typography,
  Box,
} from "@mui/material";

import { ContractPaymentProps } from "@interfaces/contract";
import { useLazyGetPaymentsByContractIdQuery } from "@api/accountData";
import { formatCurrency } from "@helpers/funciones";
import UnfoldMoreSharpIcon from "@mui/icons-material/UnfoldMoreSharp";
import iconPuls from "@assets/img/icon_table_plus.svg";
import iconEqual from "@assets/img/icon_table_equal.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  tableHeaderText,
  bordeHeaderLeft,
  iconStyle,
  bordeHeaderTop,
  bordeHeaderRight,
  bordeLeft,
  bordeCenter,
  borderRight,
} from "@components/customStylesInputs/inputsStyles";
import dayjs from "dayjs";

interface rowData {
  paymentNo: number;
  date_formated: string;
  date_timestamp: number;
  initialBalance: number;
  capitalPayment: number;
  ordinaryInterest: number;
  moratoryInterest: number;
  totalPayment: number;
  finalBalance: number;
  status: string;
  status_text: string;
  status_color: string;
}

const PAYMENT_STATUS = {
  DEFAULT: {
    text: "N/A",
    color: "#000",
  },
  PENDING: {
    text: "Pendiente",
    color: "#002652",
  },
  GRACE_PERIOD: {
    text: "Período de gracia",
    color: "#FBA028",
  },
  LATE: {
    text: "Atrasado",
    color: "#F2704F",
  },
  PARTIALLY_PAID: {
    text: "Parcialmente pagado",
    color: "#987CEC",
  },
  PAID: {
    text: "Pagado",
    color: "#4ACBC6",
  },
};

export const HistorialDePagos = () => {
  const params = useParams<{ contractId: string }>();
  const [getPaymentHistory] = useLazyGetPaymentsByContractIdQuery();
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [rows, setRows] = useState<rowData[]>([]);

  const handleSort = () => {
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const sortedRows = rows.sort((a: rowData, b: rowData) => {
    return orderDirection === "asc" ? a.date_timestamp - b.date_timestamp : b.date_timestamp - a.date_timestamp;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!params.contractId) {
          return;
        }

        const history = await getPaymentHistory("parentAccountKey-3414").unwrap();
        const row_list: rowData[] = [];

        history.forEach((item: ContractPaymentProps) => {
          const payment_date = dayjs(item.dueDate);
          const payment_status = PAYMENT_STATUS[item.state] || PAYMENT_STATUS["DEFAULT"];
          row_list.push({
            paymentNo: 0,
            date_formated: payment_date.format("DD/MM/YYYY"), //ok
            date_timestamp: payment_date.unix(), //ok
            initialBalance: 0, // pendiente
            capitalPayment: item.principal.amount.expected, //ok
            ordinaryInterest: item.interest.amount.expected, //ok
            moratoryInterest: item.penalty.amount.expected, //ok
            totalPayment: item.principal.amount.expected + item.interest.amount.expected + item.penalty.amount.expected, //ok
            finalBalance: 0, // pendiente
            status: item.state,
            status_text: payment_status.text, //ok
            status_color: payment_status.color, //ok
          });
        });

        // order by date and set payment number
        row_list.sort((a, b) => a.date_timestamp - b.date_timestamp);
        row_list.forEach((item, index) => {
          item.paymentNo = index + 1;
        });

        setRows(row_list);
      } catch (error) {
        console.error("Error al obtener los datos del historial de pagos:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Box sx={{ px: 4 }} mt={3}>
        <Typography
          color={"#F2704F"}
          fontSize={"16px"}
          fontWeight={600}
          mb={3}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ArrowBackIosIcon sx={{ marginRight: "0px" }} />
          Regresar a inicio
        </Typography>

        <Typography color={"#002652"} fontSize={"31px"} fontWeight={600} mt={2} mb={2}>
          Historial de pagos
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            border: "none",
            width: "100%",
            overflowX: "auto",
          }}
        >
          <Table
            sx={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "0 10px",
            }}
            aria-label="loan table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableHeaderText, border: "none", textAlign: "center" }}>No. de pago</TableCell>
                <TableCell sx={{ ...tableHeaderText, border: "none", textAlign: "center" }}>
                  <TableSortLabel
                    active
                    direction={orderDirection}
                    onClick={handleSort}
                    IconComponent={UnfoldMoreSharpIcon}
                  >
                    Fecha {orderDirection}
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ ...tableHeaderText, border: "none", textAlign: "center" }}>Saldo inicial</TableCell>
                <TableCell sx={{ ...tableHeaderText, ...bordeHeaderLeft, textAlign: "center" }}>
                  Pago de capital
                  <img src={iconPuls} alt="Plus Icon" style={iconStyle} />
                </TableCell>
                <TableCell sx={{ ...tableHeaderText, ...bordeHeaderTop, textAlign: "center" }}>
                  Interés ordinario
                  <img src={iconPuls} alt="Plus Icon" style={iconStyle} />
                </TableCell>
                <TableCell sx={{ ...tableHeaderText, ...bordeHeaderTop, textAlign: "center" }}>
                  Interés moratorio
                  <img src={iconEqual} alt="Plus Icon" style={iconStyle} />
                </TableCell>
                <TableCell sx={{ ...tableHeaderText, ...bordeHeaderRight, textAlign: "center", color: "#528CD6" }}>
                  Pago total
                </TableCell>
                <TableCell sx={{ ...tableHeaderText, border: "none", textAlign: "center" }}>Saldo final</TableCell>
                <TableCell sx={{ ...tableHeaderText, border: "none", textAlign: "center" }}>Estatus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.map((row: rowData) => (
                <TableRow
                  key={row.paymentNo}
                  sx={{
                    "&:nth-of-type(odd)": { backgroundColor: "#F9F9F9" }, // Alternating row colors
                  }}
                >
                  <TableCell sx={bordeLeft}>
                    <Typography color={"#002652"} fontSize={"16px"}>
                      {row.paymentNo}
                    </Typography>
                  </TableCell>
                  <TableCell sx={bordeCenter}>
                    <Typography color={"#002652"} fontSize={"16px"}>
                      {row.date_formated}
                    </Typography>
                  </TableCell>
                  <TableCell sx={bordeCenter}>
                    <Typography color={"#002652"} fontSize={"16px"}>
                      {row.initialBalance}
                    </Typography>
                  </TableCell>
                  <TableCell sx={bordeCenter}>
                    <Typography color={"#002652"} fontSize={"16px"}>
                      {formatCurrency(row.capitalPayment)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={bordeCenter}>
                    <Typography color={"#002652"} fontSize={"16px"}>
                      {formatCurrency(row.ordinaryInterest)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={bordeCenter}>
                    <Typography color={"#002652"} fontSize={"16px"}>
                      {formatCurrency(row.moratoryInterest)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={bordeCenter}>
                    <Typography color={"#002652"} fontSize={"16px"}>
                      {formatCurrency(row.totalPayment)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={bordeCenter}>
                    <Typography color={"#002652"} fontSize={"16px"}>
                      {formatCurrency(row.finalBalance)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={borderRight}>
                    <Typography color={row.status_color} fontSize={"16px"} fontWeight={600}>
                      {row.status_text}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
