import { AddressData, AddressPayLoad, AddressPayLoadResponse, ApiResponseAddress } from "@interfaces/direcciones";
import { baseApi } from "./baseApi";

const addresApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postAddres: build.mutation<AddressPayLoadResponse, AddressPayLoad>({
      query: (data: AddressPayLoad) => ({
        url: `direcciones`,
        method: "POST",
        body: data,
      }),
    }),
    getAddressByPerson: build.query<AddressData[], string>({
      query: (personId: string) => `direcciones?q=person_id:${personId}`,
      transformResponse: (response: ApiResponseAddress) => {
        return response.data.data;
      },
    }),
    getAddressByClientId: build.query<AddressData[], string>({
      query: (client_details_id: string) => `direcciones?q=client_details_id:${client_details_id}`,
      transformResponse: (response: ApiResponseAddress) => {
        
        return response.data.data; 
      },
    }),
    patchSocialMedia: build.mutation<Response, { direccion: string; data: AddressPayLoad }>({
      query: ({ direccion, data }) => ({
        url: `direcciones/${direccion}`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const {
  usePostAddresMutation,
  useGetAddressByPersonQuery,
  useLazyGetAddressByPersonQuery,
  usePatchSocialMediaMutation,
  useLazyGetAddressByClientIdQuery
} = addresApi;
