import { baseApi } from "./baseApi";

import { accountUserResponse } from "../types";

const accoutUser = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAccountUserById: build.query<accountUserResponse, string>({
      query: (accoutUserId) => `cuentas/${accoutUserId}`,
    }),
  }),
});

export const { useGetAccountUserByIdQuery } = accoutUser;
