import { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { setModalQuotationVisible } from "@store/slices/appSlice";
import { setConditionLoan, setNewConditionLoan } from "@store/slices/registerSlice";

import { usePostConditionMutation } from "@api/conditions";
import { Quotation } from "./Quotation";

export const QuotationModal = () => {
  const dispatch = useAppDispatch();

  const modalState = useSelector((state: RootState) => state.app.modal_quotation);
  const loanApplication = useSelector((state: RootState) => state.register.application);

  const [triggerPostCondition] = usePostConditionMutation();

  const [loanAmount, setLoanAmountLocal] = useState<number>(loanApplication.loan_amount);
  const [loanTerm, setLoanTermLocal] = useState<number>(loanApplication.loan_term);
  const [monthlyRate, setMonthlyRateLocal] = useState<number>(0);

  const handleOnClickUpdateConditionLoan = async () => {
    try {
      await triggerPostCondition({
        loan_application: loanApplication.id,
        loan_amount: loanAmount,
        requested_term: loanTerm,
        approved_amount: loanApplication.approved_amount,
        approved_term: loanApplication.approved_term,
        interest_rate: loanApplication.interest_rate,
      }).unwrap();

      dispatch(
        setNewConditionLoan({
          amountMonthly: monthlyRate,
          loanAmount: loanAmount,
          loanTerm: loanTerm,
          approved_amount: loanApplication.approved_amount,
          approved_term: loanApplication.approved_term,
          interest_rate: loanApplication.interest_rate,
        }),
      );
      dispatch(setModalQuotationVisible(false));
    } catch (error) {
      console.log("Ha ocurrido un error: ", error);
    }
  };

  const handleOnClickClose = () => {
    dispatch(setModalQuotationVisible(false));
  };

  const handleOnUpdateValues = (amount: number, term: number, monthlyRate: number) => {
    setLoanAmountLocal(amount);
    setLoanTermLocal(term);
    setMonthlyRateLocal(monthlyRate);
  };

  return (
    <Dialog open={modalState.visible} maxWidth={"xs"}>
      <DialogTitle>
        <Typography variant="body1" color={"#002652"} fontWeight={600} textAlign={"center"}>
          Modifica tu cotización
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Quotation isShowed={modalState.visible} onUpdateValues={handleOnUpdateValues} />
        <Grid container columnSpacing={1} my={4}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="blue_outlined"
              sx={{ padding: "0", height: "100%" }}
              onClick={handleOnClickClose}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="default" size="medium" onClick={handleOnClickUpdateConditionLoan}>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
