import { Suspense } from "react"
import { Route, Routes } from "react-router-dom"

import AuthenticatedLayout from "../../layouts/AuthenticatedLayout"
import { Contrato } from "./Contrato"
import { HistorialDePagos } from "./HistorialDePagos"
import { ConsultaFacturas } from "./ConsultaFacturas/ConsultaFacturas"
import { DocumentosContratos } from "./ConsultaContratos/ConsultaContratos"
import { EstadosDeCuenta } from "./EstadosdeCuenta"

export const Home = () => {
    return (
        <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<AuthenticatedLayout />} >
                    <Route index element={<Contrato />} />
                    <Route path="hitorial_de_pagos/:contractId" element={<HistorialDePagos />} />
                    <Route path="consulta_facturas" element={<ConsultaFacturas />} />
                    <Route path="consulta_contratos" element={<DocumentosContratos />} />
                    <Route path="documentos" element={<DocumentosContratos />} />
                    <Route path="estados" element={<EstadosDeCuenta />} />
                </Route>
            </Routes>
        </Suspense>
    )
}