import React, { useState, useEffect } from "react";
import {
    Grid,
    Box,
    TextField,
    Typography,
    Container,
    InputAdornment,
    IconButton,
    Button,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import img from "@assets/img/right_16.svg";
import { useLocation } from "react-router-dom";

export const NuevaContraseña = () => {
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [uuid, setUuid] = useState<string | null>(null);
    const location = useLocation();

    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .required("Campo requerido")
            .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/, "Formato Incorrecto"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
            .required("Campo requerido"),
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const requestUUID = queryParams.get("request");
        if (requestUUID) {
            setUuid(requestUUID);
            console.log(requestUUID)
        }
    }, [location]);

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema,
        onSubmit: (values) => {
            console.log("Nueva contraseña:", values.password);
        },
    });

    const isLengthValid = formik.values.password.length >= 8 && formik.values.password.length <= 20;
    const hasNumber = /[0-9]/.test(formik.values.password);
    const hasSpecialChar = /[!@#$%^&*]/.test(formik.values.password);
    const hasUpperCase = /[A-Z]/.test(formik.values.password);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Grid container>
            <Grid item xs={12} md={8}>
                <Box
                    sx={{
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box my={2}>
                        <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={700} color={"#528CD6"}>
                            Escribe y confirma tu nueva contraseña
                        </Typography>
                    </Box>

                    <Container maxWidth="sm" sx={{ mt: 2 }}>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <TextField
                                id="password"
                                name="password"
                                label="Nueva contraseña"
                                type={showPassword ? "text" : "password"}
                                margin="normal"
                                required
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleClickShowPassword} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ my: 4 }}
                            />

                            <TextField
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Confirma contraseña"
                                type={showConfirmPassword ? "text" : "password"}
                                margin="normal"
                                required
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ my: 4 }}
                            />

                            <Box sx={{ mt: 1, mb: 2 }}>
                                <Typography variant="body1" fontWeight={500}>
                                    Tu contraseña debe contener:
                                </Typography>
                                <Typography mt={1} variant="body2" color={isLengthValid ? "green" : "red"} display="flex" alignItems="center">
                                    {isLengthValid ? <CheckIcon /> : <CloseIcon />} &nbsp;Entre 8 y 20 caracteres
                                </Typography>
                                <Typography variant="body2" color={hasNumber ? "green" : "red"} display="flex" alignItems="center">
                                    {hasNumber ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter numérico
                                </Typography>
                                <Typography variant="body2" color={hasSpecialChar ? "green" : "red"} display="flex" alignItems="center">
                                    {hasSpecialChar ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter especial
                                </Typography>
                                <Typography variant="body2" color={hasUpperCase ? "green" : "red"} display="flex" alignItems="center">
                                    {hasUpperCase ? <CheckIcon /> : <CloseIcon />} &nbsp;Una mayúscula
                                </Typography>
                            </Box>

                            <Box textAlign={"center"} mt={6}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={!formik.isValid}
                                    sx={{
                                        backgroundColor: "#528CD6",
                                        color: "#fff",
                                        padding: "10px 24px",
                                        borderRadius: "24px",
                                        textTransform: "none",
                                    }}
                                >
                                    Continuar
                                </Button>
                            </Box>
                        </form>
                    </Container>
                </Box>
            </Grid>

            {!isMobileOrTablet && (
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: "#002652" }}>
                    <img src={img} alt="Imagen" style={{ height: "80%", width: "80%", objectFit: "contain" }} />
                </Grid>
            )}
        </Grid>
    );
};