/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Box,
  TextField,
  useMediaQuery,
  useTheme,
  Typography,
  Container,
  Autocomplete,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import { RootState, useAppDispatch } from "@store/store";
import { setAccountUser, setConditionLoan, setTaxProductId, setTypePerson } from "@store/slices/registerSlice";
import { useSaveLeadDataMutation } from "@api/onboarding";
import { PayloadSaveLeadData } from "@interfaces/request";
import { calculateAmountMonthly } from "@helpers/funciones";

import { TerminosCondiciones } from "@components/TerminosCondiciones";
import { EMAIL } from "@utils/expreciones";
import { signOut } from "aws-amplify/auth";

import img from "@assets/img/right_2.svg";
import { UUID } from "crypto";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

const domainOptions = ["gmail.com", "outlook.com", "hotmail.com"];

interface FormValues {
  email: string;
  password: string;
}

export const CrearCuenta = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const application = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [saveLeadData] = useSaveLeadDataMutation();

  const [initialValues] = useState<FormValues>({
    email: accountUser.email || "",
    password: accountUser.password || "",
  });

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Campo requerido")
      .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
    password: yup
      .string()
      .required("Campo requerido")
      .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}$/, "Formato Incorrecto"),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const payloadSaveLeadData: PayloadSaveLeadData = {
        email: values.email,
        amount: application.loan_amount,
        product_id: application.product_id,
        tax_system_id: application.tax_system_id,
        term: application.loan_term,
      };
      await saveLeadData(payloadSaveLeadData).unwrap();
      await signOut();
      dispatch(
        setAccountUser({
          id: 0,
          email: values.email,
          password: values.password,
        }),
      );

      navigate("/producto/creditosimple/registro_datos_personales");
    } catch (error: any) {
      console.log("Ha ocurrido un error", error);
      if (error.status && error.data) {
        if (error.data.code === "001") {
          formik.setFieldError("email", "Este correo ya esta registrado, intenta con otro o inicia sesión");
          return;
        }
        if (error?.status === 500 && error?.status === 409) {
          dispatch(setModalErrosVisible({ open: true, type: "500" }));
          return;
        } else {
          dispatch(setModalErrosVisible({ open: true, type: "" }));
          return;
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const isLengthValid = formik.values.password.length >= 8 && formik.values.password.length <= 20;
  const hasNumber = /[0-9]/.test(formik.values.password);
  const hasSpecialChar = /[!@#$%^&*]/.test(formik.values.password);
  const hasUpperCase = /[A-Z]/.test(formik.values.password);

  const getDomainSuggestions = (value: string): string[] => {
    const parts = value.split("@");
    if (parts.length > 1) {
      const enteredDomain = parts[1].toLowerCase();
      return domainOptions
        .filter((option) => option.startsWith(enteredDomain))
        .map((domain) => `${parts[0]}@${domain}`);
    }
    return [];
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const getQueryParams = () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const tax_system_id = searchParams.get("tax_system_id") as UUID;
        const product_id = searchParams.get("product_id") as UUID;
        const personType = searchParams.get("person_type");
        const loanAmount = Number(searchParams.get("loan_amount"));
        const loanTerm = Number(searchParams.get("loan_term"));
        const amountMonthly = calculateAmountMonthly(loanAmount, loanTerm);

        if (!tax_system_id || !product_id || !personType || !loanAmount || !loanTerm) {
          navigate("/login");
          return;
        }

        dispatch(setTypePerson(personType));

        dispatch(
          setTaxProductId({
            tax_system_id,
            product_id,
          }),
        );

        dispatch(
          setConditionLoan({
            loanAmount,
            loanTerm,
            amountMonthly,
          }),
        );
      } catch (error) {
        console.log("error", error);
      }
    };

    getQueryParams();
  }, []);

  return (
    <Grid container height={"100%"}>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 4 : 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant={isMobileOrTablet ? "h4" : "h3"} fontWeight={500} textAlign="center">
            Te damos la bienvenida
          </Typography>

          <Typography sx={{ mt: 2 }} variant={isMobileOrTablet ? "body2" : "body1"}>
            Ingresa los datos para crear tu cuenta
          </Typography>

          <Container maxWidth="sm" sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div>
                <Autocomplete
                  freeSolo
                  options={getDomainSuggestions(formik.values.email)}
                  inputValue={formik.values.email}
                  onInputChange={(_e, newInputValue) => {
                    formik.setFieldValue("email", newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="email"
                      name="email"
                      label="Correo electrónico"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  )}
                  disabled={isLoading}
                />
                <TextField
                  id="password"
                  name="password"
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={isLoading}
                />
              </div>
              <Box sx={{ mt: 1, mb: 2 }}>
                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  fontWeight={500}
                  mt={isMobileOrTablet ? 2 : 4}
                >
                  Tu contraseña debe contener:
                </Typography>

                <Typography
                  mt={1}
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={isLengthValid ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {isLengthValid ? <CheckIcon /> : <CloseIcon />} &nbsp;8 y 20 caracteres
                </Typography>

                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={hasNumber ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {hasNumber ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter numérico
                </Typography>
                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={hasSpecialChar ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {hasSpecialChar ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter especial
                </Typography>
                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={hasUpperCase ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {hasUpperCase ? <CheckIcon /> : <CloseIcon />} &nbsp;Una mayúscula
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 3,
                  mb: 3,
                }}
              >
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={isLoading || !formik.isValid || !formik.dirty}
                >
                  Continuar
                </Button>
              </Box>
            </form>
            <Box sx={{ mt: 4, mb: 4 }}>
              <TerminosCondiciones />
            </Box>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
