/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useLazyGetByCpQuery } from "@api/postalCodes";

import { useLazyGetPersonByIdQuery, usePatchPersonDataMutation } from "@api/personasApi";
import { useLazyGetAddressByPersonQuery, usePatchSocialMediaMutation, usePostAddresMutation } from "@api/addressApi";
import {
  useGetAddressTypeQuery,
  useGetStatesQuery,
  useLazyGetCivilStatusQuery,
  useLazyGetMatrimonialRegimesQuery,
} from "@api/catalogs";

import img from "@assets/img/img_tofu/img_tofu_9.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { customSelect, customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { Address, CivilStatus, MatrimonialRegimesData } from "@interfaces/index";
import { CODIGO_POSTAL, EMAIL } from "@utils/expreciones";
import { Spinner } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";

const validationSchema = yup.object({
  telefono: yup
    .string()
    .required("Requerido")
    .matches(/^[0-9]{10}$/, "Debe ser un número de 10 dígitos"),
  email: yup
    .string()
    .required("Campo requerido")
    .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
  codigoPostal: yup.string().required("Campo requerido").matches(CODIGO_POSTAL, "Código Postal inválido"),
  calle: yup.string().required("Requerido"),
  noInterior: yup.string(),
  noExterior: yup.string().required("Requerido"),
  colonia: yup.string().required("Requerido"),
  ciudad: yup.string().required("Requerido"),
  municipio: yup.string().required("Requerido"),
  entidadFederativa: yup.string().required("Requerido"),
  sexo: yup.string().required("Requerido"),
  estadoCivil: yup.string().required("Requerido"),
  fechaNacimiento: yup.string().required("Requerido"),
  regimenMatrimonial: yup.string().when("estadoCivil", {
    is: "Casado",
    then: (schema) => schema.required("Requerido"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const DatosPropietarioRealPart1: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedDate, setSelectedDate] = useState<any>(null);

  const [getByCp] = useLazyGetByCpQuery();

  const { data: statesData } = useGetStatesQuery();

  const [getPersona, { data: dataPerson, isSuccess }] = useLazyGetPersonByIdQuery();
  const { data: addressesTypeData, isLoading: losdianA } = useGetAddressTypeQuery();
  const [addresType, setAddresType] = useState("");
  const [patchPersonData] = usePatchPersonDataMutation();
  const [postAddress] = usePostAddresMutation();
  const [getAddressByPerson, { data: addressData }] = useLazyGetAddressByPersonQuery();
  const [patchAddress] = usePatchSocialMediaMutation();
  const [estadoMatrimonial, setEstadoMatrimonial] = useState<MatrimonialRegimesData[]>([]);
  const [estadoCivil, setEstadoCivil] = useState<CivilStatus[]>([]);
  const [loading, setLoading] = useState(false);

  const [getEstadoMatrimonial, { data: listEstadoMatrimonial }] = useLazyGetMatrimonialRegimesQuery();
  const [getEstadoCivil, { data: listEstadoCivil, isLoading }] = useLazyGetCivilStatusQuery();
  const [showCasado, setShowCasado] = useState(false);

  const ownerId = useSelector((state: RootState) => state.bofu.digital_file.actual_owner.current_owner_id);
  const accountId = useSelector((state: RootState) => state.register.personal_data.id);

  const [colonias, setColonias] = useState<{ id: number; name: string }[]>([]);
  const [address, setAddress] = useState({
    state_id: 0,
    city_id: 0,
  });

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: "",
      lugarNacimiento: "",
      sexo: "",
      telefono: "",
      country_code: "+52",
      estadoCivil: "",
      codigoPostal: "",
      calle: "",
      noInterior: "",
      noExterior: "",
      colonia: "",
      ciudad: "",
      municipio: "",
      entidadFederativa: "",
      email: "",
      giro: "",
      descripcion: "",
      regimenMatrimonial: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await patchPersonData({
          id: String(ownerId),
          name: values.nombre,
          last_name: values.apellidoPaterno,
          last_name_2: values.apellidoMaterno,
          birth_date: values.fechaNacimiento,
          birth_state: 1,
          sex: values.sexo,
          phone_number: values.telefono,
          civil_status_id: values.estadoCivil,
          occupation: values.giro,
          code_phone_number: values.country_code,
          email: values.email,
        }).unwrap();

        const direccionPayload = {
          street: values.calle,
          no_ext: values.noExterior,
          no_int: values.noInterior,
          colonia_id: Number(values.colonia),
          state_id: address.state_id,
          city_id: address.city_id,
          country_id: 1,
          cp: values.codigoPostal,
          client_details_id: accountId,
          person_id: String(ownerId),
          address_type_id: addresType,
        };

        if (addressData && addressData.length > 0) {
          await patchAddress({
            direccion: addressData[0].id,
            data: direccionPayload,
          }).unwrap();
        } else {
          await postAddress(direccionPayload).unwrap();
        }
        navigate("/producto/creditosimple/datos_propietario_real_2");
      } catch (err) {
        console.error("Error al actualizar:", err);
      }
    },
  });

  const handleCp = async (value: string) => {
    try {
      const cpData = await getByCp(value);
      if (cpData) {
        const hasCities = cpData.data?.cities && cpData.data?.cities.length > 0;
        const hasStates = cpData.data?.states && cpData.data?.states.length > 0;
        const hasColonias = cpData.data?.colonias && cpData.data?.colonias.length > 0;

        if (!hasCities || !hasStates || !hasColonias) {
          formik.setFieldValue("ciudad", "");
          formik.setFieldValue("municipio", "");
          formik.setFieldValue("entidadFederativa", "");
          formik.setFieldValue("colonia", "");

          setColonias([]);
          setAddress({
            city_id: 0,
            state_id: 0,
          });
          return;
        }
        formik.setFieldValue("ciudad", cpData.data?.cities[0].name);
        formik.setFieldValue("municipio", cpData.data?.cities[0].name);
        formik.setFieldValue("entidadFederativa", cpData.data?.states[0].name);
        setColonias(cpData.data?.colonias);
        formik.setFieldValue("colonia", cpData.data?.colonias[0].id);
        setAddress({
          city_id: cpData.data?.cities[0].id,
          state_id: cpData.data?.states[0].id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (addressData?.length) {
      const addressId = addressData[0];

      handleCp(String(addressId?.cp));
      formik.setFieldValue("codigoPostal", addressId?.cp);
      formik.setFieldValue("calle", addressId?.street);
      formik.setFieldValue("noExterior", addressId?.no_ext);
      formik.setFieldValue("noInterior", addressId?.no_int);
      setTimeout(() => {
        formik.setFieldValue("colonia", addressId?.colonia_id);
      }, 500);
    }
  }, [addressData, ownerId]);

  useEffect(() => {
    if (ownerId) {
      getAddressByPerson(ownerId);
    }
  }, [ownerId]);

  useEffect(() => {
    if (ownerId) {
      getPersona(String(ownerId));
    }
  }, [ownerId]);

  useEffect(() => {
    if (dataPerson) {
      formik.setFieldValue("nombre", dataPerson.data.name || "");
      formik.setFieldValue("apellidoPaterno", dataPerson.data.last_name || "");
      formik.setFieldValue("apellidoMaterno", dataPerson.data.last_name_2 || "");

      formik.setFieldValue("sexo", dataPerson.data.name || "");
      formik.setFieldValue("telefono", dataPerson.data.phone_number || "");
      formik.setFieldValue("sexo", dataPerson.data.sex || "");
      formik.setFieldValue("email", dataPerson.data.email || "");
      if (estadoCivil && estadoMatrimonial) {
        formik.setFieldValue("estadoCivil", dataPerson.data.civil_status_id || "");
        formik.setFieldValue("regimenMatrimonial", dataPerson.data.matrimonial_regimes_id || "");
      }

      formik.setFieldValue("country_code", dataPerson.data.code_phone_number || "+52");

      if (dataPerson.data.birth_date) {
        const startDateConyuge = dayjs(dataPerson.data.birth_date);
        formik.setFieldValue("fechaNacimiento", startDateConyuge);
        setSelectedDate(startDateConyuge);
      }

      if (dataPerson.data.birth_state) {
        formik.setFieldValue("lugarNacimiento", String(dataPerson.data.birth_state));
      }
    }
  }, [dataPerson, isSuccess, estadoCivil, estadoMatrimonial]);

  useEffect(() => {
    if (listEstadoMatrimonial) {
      setEstadoMatrimonial(listEstadoMatrimonial);
    }
  }, [listEstadoMatrimonial]);

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      try {
        await getEstadoCivil();
        await getEstadoMatrimonial();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (listEstadoCivil) {
      setEstadoCivil(listEstadoCivil.data.data);
    }
  }, [listEstadoCivil]);

  useEffect(() => {
    const selectedItem = estadoCivil.find((item) => item.id === formik.values.estadoCivil);
    if (selectedItem && selectedItem.name === "Casado") {
      setShowCasado(true);
    } else {
      setShowCasado(false);
      formik.setFieldValue("matrimonial_regimes_id", "");
    }
  }, [formik.values.estadoCivil, estadoCivil]);

  useEffect(() => {
    if (addressesTypeData) {
      const filteredAddresses = addressesTypeData.filter((address: Address) => address.code === "CDPA");
      setAddresType(filteredAddresses[0].id);
    }
  }, [addressesTypeData, losdianA]);

  return (
    <Grid container>
      <Spinner open={loading} />
      <Grid item xs={12} md={8}>
      <InactivityHandler/>
        <Box mx={4}>
          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <Typography mt={4} mb={3} fontSize={"17px"} fontWeight={600} color={"#002652"} textAlign={"center"}>
              Información del Propietario Real
            </Typography>

            <Typography mt={4} mb={3} fontSize={"14px"} fontWeight={300} color={"#002652"} textAlign={"center"}>
              <strong>1</strong> de 2
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <TextField
                id="nombre"
                name="nombre"
                label="Nombre"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
                value={formik.values.nombre}
              />
              <TextField
                fullWidth
                id="apellidoPaterno"
                name="apellidoPaterno"
                label="Apellido Paterno"
                value={formik.values.apellidoPaterno}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={formik.touched.apellidoPaterno && Boolean(formik.errors.apellidoPaterno)}
                helperText={formik.touched.apellidoPaterno && formik.errors.apellidoPaterno}
                margin="normal"
              />
              <TextField
                fullWidth
                id="apellidoMaterno"
                name="apellidoMaterno"
                label="Apellido Materno"
                value={formik.values.apellidoMaterno}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.apellidoMaterno && Boolean(formik.errors.apellidoMaterno)}
                helperText={formik.touched.apellidoMaterno && formik.errors.apellidoMaterno}
                margin="normal"
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                  <DatePicker
                    label="Fecha de nacimiento"
                    value={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      formik.setFieldValue("fechaNacimiento", dayjs(date).format("YYYY-MM-DD"));
                    }}
                    slotProps={{
                      textField: {
                        name: "fechaNacimiento",
                        onBlur: formik.handleBlur,
                        error: formik.touched.fechaNacimiento && Boolean(formik.errors.fechaNacimiento),
                        helperText: formik.touched.fechaNacimiento ? formik.errors.fechaNacimiento : "",
                        required: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl
                fullWidth
                required
                sx={{
                  ...customStylesAsterisk,
                  ...customSelect,
                  mt: 2,
                }}
                error={formik.touched.lugarNacimiento && Boolean(formik.errors.lugarNacimiento)}
              >
                <InputLabel id="lugarNacimiento">Lugar de nacimiento</InputLabel>
                <Select
                  labelId="lugarNacimiento"
                  id="lugarNacimiento"
                  name="lugarNacimiento"
                  fullWidth
                  required
                  label="Lugar de nacimiento"
                  value={formik.values.lugarNacimiento}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.lugarNacimiento && Boolean(formik.errors.lugarNacimiento)}
                  disabled={isLoading}
                >
                  <MenuItem value={""}>Selecciona</MenuItem>
                  {statesData?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.estadoCivil && formik.errors.estadoCivil && (
                  <FormHelperText>{formik.errors.estadoCivil}</FormHelperText>
                )}
              </FormControl>

              <FormControl component="fieldset" sx={{ mt: 4 }}>
                <FormLabel component="legend" sx={{ color: "#002652", fontSize: "13px" }}>
                  Sexo como aparece en la INE:{" "}
                </FormLabel>
                <RadioGroup aria-label="sexo" name="sexo" value={formik.values.sexo} onChange={formik.handleChange} row>
                  <FormControlLabel
                    sx={{ color: "#002652", fontSize: "13px" }}
                    value="MASCULINO"
                    control={<Radio />}
                    label="Masculino"
                  />
                  <FormControlLabel
                    sx={{ color: "#002652", fontSize: "13px" }}
                    value="FEMENINO"
                    control={<Radio />}
                    label="Femenino"
                  />
                </RadioGroup>
              </FormControl>

              <Grid container spacing={1} mt={1}>
                <Grid item xs={4} sm={4} md={4}>
                  <CustomContryCode
                    label="Código"
                    name="country_code"
                    value={formik.values.country_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                  />
                </Grid>

                <Grid item xs={8} sm={8} md={8}>
                  <TextField
                    type="text"
                    name="telefono"
                    variant="outlined"
                    label="Teléfono"
                    fullWidth
                    required
                    value={formik.values.telefono}
                    onChange={(event) => {
                      formik.setFieldValue("telefono", event.target.value.replace(/[^0-9]/g, ""));
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                    helperText={formik.touched.telefono && formik.errors.telefono}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Grid>
              </Grid>

              <FormControl
                fullWidth
                required
                sx={{
                  ...customStylesAsterisk,
                  ...customSelect,
                  mt: 2,
                }}
                error={formik.touched.estadoCivil && Boolean(formik.errors.estadoCivil)}
              >
                <InputLabel id="estadoCivil">Estado civil</InputLabel>
                <Select
                  labelId="estadoCivil"
                  id="estadoCivil"
                  name="estadoCivil"
                  fullWidth
                  required
                  label="Estado civil"
                  value={formik.values.estadoCivil}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.estadoCivil && Boolean(formik.errors.estadoCivil)}
                  disabled={isLoading}
                >
                  <MenuItem value={""}>Selecciona</MenuItem>
                  {estadoCivil.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.estadoCivil && formik.errors.estadoCivil && (
                  <FormHelperText>{formik.errors.estadoCivil}</FormHelperText>
                )}
              </FormControl>

              {showCasado && (
                <>
                  <FormControl
                    fullWidth
                    required
                    sx={{
                      ...customStylesAsterisk,
                      ...customSelect,
                      mt: 2,
                    }}
                    error={formik.touched.regimenMatrimonial && Boolean(formik.errors.regimenMatrimonial)}
                  >
                    <InputLabel id="matrimonial_regimes_id">Régimen matrimonial</InputLabel>
                    <Select
                      labelId="matrimonial_regimes_id"
                      id="matrimonial_regimes_id"
                      name="regimenMatrimonial"
                      fullWidth
                      required
                      label="Régimen matrimonial *"
                      value={formik.values.regimenMatrimonial}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.regimenMatrimonial && Boolean(formik.errors.regimenMatrimonial)}
                    >
                      <MenuItem value={""}>Selecciona</MenuItem>
                      {estadoMatrimonial.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.regimenMatrimonial && formik.errors.regimenMatrimonial && (
                      <FormHelperText>{formik.errors.regimenMatrimonial}</FormHelperText>
                    )}
                  </FormControl>
                </>
              )}

              <FormControl fullWidth>
                <TextField
                  id="email"
                  name="email"
                  label="Correo electrónico"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                  inputProps={{ maxLength: 30 }}
                />
              </FormControl>

              <Box mt={4}>
                <Typography my={3} variant={isMobileOrTablet ? "body2" : "body1"} color={"#002652"} fontWeight={600}>
                  Domicilio operativo:
                </Typography>
                <Typography variant="body2" fontWeight={300} color="textSecondary" sx={{ fontSize: "12px" }}>
                  Tal como aparece en el comprobante de domicilio y deberá ser un domicilio dentro de la República
                  Mexicana
                </Typography>
              </Box>
              <TextField
                id="codigoPostal"
                name="codigoPostal"
                label="Código Postal"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  const cp = e.target.value.replace(/[^0-9]/g, "");
                  formik.setFieldValue("codigoPostal", cp);
                  if (cp.length === 5) {
                    handleCp(cp);
                  }
                }}
                error={formik.touched.codigoPostal && Boolean(formik.errors.codigoPostal)}
                helperText={formik.touched.codigoPostal && formik.errors.codigoPostal}
                value={formik.values.codigoPostal}
                inputProps={{ maxLength: 5 }}
              />

              <TextField
                id="calle"
                name="calle"
                label="Calle o avenida"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.calle && Boolean(formik.errors.calle)}
                helperText={formik.touched.calle && formik.errors.calle}
                value={formik.values.calle}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="noExterior"
                    name="noExterior"
                    label="No. Exterior"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.noExterior && Boolean(formik.errors.noExterior)}
                    helperText={formik.touched.noExterior && formik.errors.noExterior}
                    value={formik.values.noExterior}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="noInterior"
                    name="noInterior"
                    label="No. Interior"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.noInterior}
                  />
                </Grid>
              </Grid>

              <FormControl
                fullWidth
                required
                sx={{
                  ...customStylesAsterisk,
                  ...customSelect,
                  mt: 2,
                }}
                error={formik.touched.colonia && Boolean(formik.errors.colonia)}
              >
                <InputLabel id="colonia">Colonia</InputLabel>
                <Select
                  labelId="colonia"
                  id="colonia"
                  name="colonia"
                  fullWidth
                  required
                  label="Colonia"
                  value={formik.values.colonia}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.colonia && Boolean(formik.errors.colonia)}
                >
                  <MenuItem value={""}>Selecciona</MenuItem>
                  {colonias.map((colonia: { id: number; name: string }) => (
                    <MenuItem key={colonia.id} value={colonia.id}>
                      {colonia.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.colonia && formik.errors.colonia && (
                  <FormHelperText>{formik.errors.colonia}</FormHelperText>
                )}
              </FormControl>
              <TextField
                fullWidth
                id="ciudad"
                name="ciudad"
                label="Ciudad"
                value={formik.values.ciudad}
                onChange={formik.handleChange}
                error={formik.touched.ciudad && Boolean(formik.errors.ciudad)}
                helperText={formik.touched.ciudad ? String(formik.errors.ciudad) : ""}
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                id="municipio"
                name="municipio"
                label="Municipio"
                value={formik.values.municipio}
                onChange={formik.handleChange}
                error={formik.touched.municipio && Boolean(formik.errors.municipio)}
                helperText={formik.touched.municipio ? String(formik.errors.municipio) : ""}
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                id="entidadFederativa"
                name="entidadFederativa"
                label="Entidad federativa"
                value={formik.values.entidadFederativa}
                onChange={formik.handleChange}
                error={formik.touched.entidadFederativa && Boolean(formik.errors.entidadFederativa)}
                helperText={formik.touched.entidadFederativa ? String(formik.errors.entidadFederativa) : ""}
                margin="normal"
                disabled
              />
              <Grid container spacing={2} mt={4}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="blue_outlined"
                    sx={{ padding: "0", height: "100%" }}
                    onClick={() => navigate("../propietario_real_pm")}
                  >
                    Regresar al inicio
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button type="submit" variant="default" fullWidth color="primary" disabled={!formik.isValid}>
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
