import React, { useState } from "react";
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography,
    MenuItem,
    Select,
    FormControl,
    IconButton,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface Estado {
    mes: string;
    año: number;
}

const data: Estado[] = [
    { mes: "Septiembre", año: 2024 },
    { mes: "Octubre", año: 2024 },
    { mes: "Noviembre", año: 2024 },
    { mes: "Diciembre", año: 2024 },
    { mes: "Enero", año: 2025 },
    { mes: "Febrero", año: 2025 },
    { mes: "Marzo", año: 2025 },
    { mes: "Abril", año: 2025 },
];

export const EstadosDeCuenta: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleRowsPerPageChange = (event: any) => {
        setRowsPerPage(event.target.value as number);
        setCurrentPage(1);
    };

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = data.slice(startIndex, endIndex);

    return (
        <Box sx={{ width: "100%", paddingY: 4 }}>
            {/* Botón Regresar a Inicio */}
            <Typography
                color={"#F2704F"}
                fontSize={"16px"}
                fontWeight={600}
                mb={3}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                }}
            >
                <ArrowBackIosIcon sx={{ marginRight: "5px" }} />
                Regresar a inicio
            </Typography>

            {/* Título */}
            <Typography
                color={"#002652"}
                fontSize={"31px"}
                fontWeight={600}
                mt={2}
                mb={2}
            >
                Estados de cuenta
            </Typography>

            {/* Contenedor principal */}
            <Box
                sx={{
                    width: "70%",
                    margin: "0 auto",
                    backgroundColor: "#fff",
                    padding: 4,
                }}
            >
                {/* Filtros */}
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    marginBottom={4}
                >
                    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                        <Grid item>
                            <Typography color="#002652" sx={{ fontWeight: 'bold' }}>Consulta un período:</Typography>
                        </Grid>
                        <Grid item>
                            <Select variant="outlined" defaultValue="" sx={{ minWidth: 120, color: "#002652", fontWeight: 'bold' }}>
                                <MenuItem value="">Selecciona</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <Typography color="#002652" sx={{ fontWeight: 'bold' }}>a</Typography>
                        </Grid>
                        <Grid item>
                            <Select variant="outlined" defaultValue="" sx={{ minWidth: 120, color: "#002652" }}>
                                <MenuItem value="">Selecciona</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Box>

                {/* Tabla de datos */}
                <Box display="flex" justifyContent="center" marginBottom={4}>
                    <TableContainer component={Box} sx={{ overflow: 'hidden', boxShadow: 'none' }}>
                        <Table sx={{ borderSpacing: '0 15px', borderCollapse: 'separate' }}>
                            <TableHead>
                                <TableRow sx={{ border: 'none' }}>
                                    <TableCell sx={{ color: "#002652", fontWeight: "bold", padding: '16px', border: 'none' }}>Mes</TableCell>
                                    <TableCell sx={{ color: "#002652", fontWeight: "bold", padding: '16px', border: 'none' }}>Año</TableCell>
                                    <TableCell sx={{ color: "#002652", fontWeight: "bold", padding: '16px', border: 'none' }} align="center">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((row, index) => (
                                    <TableRow key={index} sx={{ border: 'none', backgroundColor: '#fff' }}>
                                        <TableCell
                                            sx={{
                                                color: "#002652",
                                                fontWeight: "bold",
                                                border: '1px solid #E0E0E0',
                                                padding: '16px',
                                                height: '100%',
                                                verticalAlign: 'middle',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {row.mes}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: "#002652",
                                                padding: '16px',
                                                fontWeight: "bold",
                                                border: '1px solid #E0E0E0',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {row.año}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                padding: '0px',
                                            }}
                                        >
                                            <Box display="flex" justifyContent="center" height="100%">
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#002652",
                                                        color: "#fff",
                                                        minWidth: '100px',
                                                        textTransform: 'none',
                                                        marginRight: 0,
                                                        width: '50%',
                                                        borderRadius: '7px 0px 0px 7px',
                                                        height: '100%',
                                                        margin: 0,
                                                        border: 'none'
                                                    }}
                                                >
                                                    Ver
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#6BA4E6",
                                                        color: "#fff",
                                                        minWidth: '100px',
                                                        textTransform: 'none',
                                                        marginRight: 0,
                                                        width: '50%',
                                                        borderRadius: '0px 7px 7px 0px',
                                                        height: '100%',
                                                        margin: 0,
                                                        border: 'none'
                                                    }}
                                                >
                                                    Descargar
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Paginación */}
                <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ marginTop: 2, paddingRight: 2 }}>
                    {/* Botón anterior */}
                    <Typography sx={{ color: "#6D7882", marginRight: 2 }}>Anterior</Typography>
                    <IconButton onClick={handlePreviousPage} disabled={currentPage === 1} sx={{ color: "#6D7882" }}>
                        <ArrowBackIosIcon />
                    </IconButton>

                    {/* Números de páginas */}
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                        <Button
                            key={page}
                            onClick={() => setCurrentPage(page)}
                            sx={{
                                backgroundColor: 'transparent',
                                color: '#6D7882',
                                border: 'none',
                                minWidth: '40px',
                                margin: '0 4px',
                                padding: '6px 12px',
                                textTransform: 'none'
                            }}
                        >
                            {page}
                        </Button>
                    ))}

                    <IconButton onClick={handleNextPage} disabled={currentPage === totalPages} sx={{ color: "#6D7882" }}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                    <Typography sx={{ color: "#6D7882", marginLeft: 2 }}>Siguiente</Typography>

                    <Box display="flex" alignItems="center" sx={{ marginLeft: 3 }}>
                        <Typography color={"#6D7882"} sx={{ marginRight: 1 }}>Ver</Typography>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            sx={{ minWidth: '60px', color: "#6D7882", borderColor: '#6D7882' }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                        </Select>
                        <Typography color={"#6D7882"} sx={{ marginLeft: 1 }}>Filas por página</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};