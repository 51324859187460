/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { TabStepBofuPm } from "@components/index";
import { LoanConditions } from "@components/LoanConditions";
import { useDispatch, useSelector } from "react-redux";
import { setModalQuotationVisible } from "@store/slices/appSlice";
import img from "@assets/img/img_tofu/img_tofu_2.svg";
import icon from "@assets/img/icon_validando_expediente.svg";
import { useEffect, useState } from "react";
import { useGetApplicationByApplicationIdQuery } from "@api/applications";
import { RootState } from "@store/store";
import { InactivityHandler } from "@components/InactivityHandler";

interface ValidandoExpedienteProps {
  isValidExpediente: boolean;
}

export const ValidandoExpediente: React.FC<ValidandoExpedienteProps> = ({ isValidExpediente }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(20);
  const [shouldFetch, setShouldFetch] = useState(false);

  const application = useSelector((state: RootState) => state.register.application);

  const handleOnClickShowQuotation = () => {
    dispatch(setModalQuotationVisible(true));
  };

  const { data: applicationData, isSuccess } = useGetApplicationByApplicationIdQuery(application.id, {
    skip: !shouldFetch,
  });

  useEffect(() => {
    if (isValidExpediente) {
      if (timeLeft > 0) {
        setShouldFetch(false);
        const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
        return () => clearTimeout(timerId);
      } else {
        setShouldFetch(true);
        setTimeLeft(20);
      }
    }
  }, [timeLeft, isValidExpediente]);

  useEffect(() => {
    if (applicationData) {
      if(applicationData.data[0].status_catalog?.url_actua !== "/producto/creditosimple/avance_expediente_digital"){
        navigate(applicationData.data[0].status_catalog?.url_actual);
      }
    }
  }, [applicationData, isSuccess]);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <Box textAlign={"center"} mx={4}>
          <InactivityHandler />
          {isValidExpediente ? (
            <>
              <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={"#002652"}>
                Expediente en revisión
              </Typography>

              <TabStepBofuPm />

              <Typography fontWeight={400} color={"#002652"} mt={3} fontSize={"16px"}>
                Concluiste el llenado de tu expediente digital
              </Typography>

              <Typography fontWeight={300} color={"#528CD6"} mt={3} fontSize={"16px"}>
                ¡Estamos validando tu expediente, volveremos contigo a la brevedad!
              </Typography>

              <Typography fontWeight={600} color={"#002652"} mt={3} fontSize={"16px"}>
                Revisa las condiciones del crédito que estas solicitando
              </Typography>

              <Box mt={3}>
                <LoanConditions />
              </Box>

              <Box sx={{ paddingTop: "50px" }}>
                <Button variant="blue_outlined" size="small" onClick={handleOnClickShowQuotation}>
                  Modifica tu cotización
                </Button>
              </Box>

              <Box sx={{ paddingTop: "2em" }}>
                <Typography fontWeight={300} color={"#002652"} mt={2} fontSize={"14px"}>
                  <span>
                    ¡Despreocúpate!
                    <br /> Puedes salir de esta pantalla <br /> <br />
                    Si existe la necesidad de modificar o adicionar algún documento o tan pronto el expediente se
                    encuentre validado podrás consultarlo aquí mismo y te lo notificaremos vía WhatsApp
                  </span>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={"#002652"}>
                Faltantes en Expediente
              </Typography>

              <TabStepBofuPm />

              <Box sx={{ paddingTop: "2em" }}>
                <Typography fontWeight={300} color={"#002652"} fontSize={"16px"}>
                  Encontramos faltantes dentro de la validación, por favor ve a la sección o secciones marcadas con
                  faltantes para resolverlos.
                </Typography>
              </Box>

              <Box sx={{ paddingTop: "3em" }}>
                <img src={icon} alt="icon" />
              </Box>

              <Box sx={{ paddingTop: "2em", paddingBottom: "1em" }}>
                <Typography fontWeight={300} color={"#002652"} fontSize={"16px"}>
                  Podría ser necesario el editar algunas secciones o volver a cargar algunos documentos.
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
            <img
              src={img}
              alt=""
              style={{
                height: "calc(100vh - 70px)",
                margin: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
