/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "@store/store";
import { setShareHolderIdAddress } from "@store/slices/registerSlice";

import { ProgressBar } from "@components/ProgressBar";

import img from "@assets/img/img_domicilio_right.svg";
import { CODIGO_POSTAL } from "@utils/expreciones";
import { useLazyGetByCpQuery } from "@api/postalCodes";
import { useLazyGetAddressByPersonQuery, usePatchSocialMediaMutation, usePostAddresMutation } from "@api/addressApi";
import { useGetAddressTypeQuery } from "@api/catalogs";
import { Address } from "@interfaces/index";
import { customStylesAsterisk, customSelect } from "@components/customStylesInputs/inputsStyles";
import { usePostValidateNextStageMutation } from "@api/applications";
import { setModalErrosVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";

const validationSchema = yup.object().shape({
  cp: yup.string().required("Campo requerido").matches(CODIGO_POSTAL, "Código Postal inválido"),
  calle: yup.string().required("Campo requerido"),
  num_ext: yup.string().required("Campo requerido"),
  colonia: yup.string().required("Campo requerido"),
  ciudad: yup.string().required("Campo requerido"),
  municipio: yup.string().required("Campo requerido"),
  entidad: yup.string().required("Campo requerido"),
});

export const DomicilioAccionista = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [getCp] = useLazyGetByCpQuery();
  const [postAddress] = usePostAddresMutation();
  const [patchAddress] = usePatchSocialMediaMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const { data: addressesData, isLoading: losdianA } = useGetAddressTypeQuery();
  const [getDireccionByPerson, { data: dataAddress }] = useLazyGetAddressByPersonQuery();

  const [patChAddresInfo, setPatChAddresInfo] = useState(false);
  const [listColonias, setListColonias] = useState([]);
  const [addresType, setAddresType] = useState("");
  const [cpError, setCpError] = useState("");
  const [address, setAddress] = useState({
    state_id: 0,
    city_id: 0,
  });

  const person = useSelector((state: RootState) => state.register.personal_data);
  const shareholder = useSelector((state: RootState) => state.register.shareholder);
  const applicationData = useSelector((state: RootState) => state.register.application);

  const initialValues = {
    cp: "",
    calle: "",
    num_ext: "",
    num_int: "",
    ciudad: "",
    municipio: "",
    entidad: "",
    colonia: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        const dataAddress = {
          street: values.calle,
          no_ext: values.num_ext,
          no_int: values.num_int,
          colonia_id: Number(values.colonia),
          state_id: address.state_id,
          city_id: address.city_id,
          country_id: 1,
          cp: values.cp,
          client_details_id: person.id,
          person_id: shareholder.id,
          address_type_id: addresType,
        };

        if (patChAddresInfo) {
          try {
            await patchAddress({ direccion: String(shareholder.addressId), data: dataAddress }).unwrap();
            navigate("/producto/creditosimple/consulta_buro");
          } catch (error: any) {
            if (error?.status === 500) {
              dispatch(setModalErrosVisible({ open: true, type: "500" }));
            } else {
              dispatch(setModalErrosVisible({ open: true, type: "" }));
            }
          }
        } else {
          try {
            const addres = await postAddress(dataAddress).unwrap();
            dispatch(setShareHolderIdAddress({ id_address: String(addres.data.id) }));

            await triggerPostNextStage({
              applicationId: applicationData.id,
              statusCode: "CSDAV",
            }).unwrap();
            navigate("/producto/creditosimple/consulta_buro");
          } catch (error: any) {
            if (error?.status === 500 || error?.status === 409) {
              dispatch(setModalErrosVisible({ open: true, type: "500" }));
            } else {
              dispatch(setModalErrosVisible({ open: true, type: "" }));
            }
          }
        }
      }
    },
  });

  const getAddres = async (value: string) => {
    try {
      await getDireccionByPerson(value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCp = async (value: string) => {
    try {
      const cpData = await getCp(value);
      if (cpData) {
        const hasCities = cpData.data?.cities && cpData.data?.cities.length > 0;
        const hasStates = cpData.data?.states && cpData.data?.states.length > 0;
        const hasColonias = cpData.data?.colonias && cpData.data?.colonias.length > 0;

        if (!hasCities || !hasStates || !hasColonias) {
          formik.setFieldValue("ciudad", "");
          formik.setFieldValue("municipio", "");
          formik.setFieldValue("entidad", "");
          formik.setFieldValue("colonia", "");
          setCpError(`No existe el código postal`);
          setListColonias([]);
          setAddress({
            city_id: 0,
            state_id: 0,
          });

          return;
        }
        setCpError("");
        formik.setFieldValue("ciudad", cpData.data?.cities[0].name);
        formik.setFieldValue("municipio", cpData.data?.cities[0].name);
        formik.setFieldValue("entidad", cpData.data?.states[0].name);
        setListColonias(cpData.data?.colonias);
        formik.setFieldValue("colonia", cpData.data?.colonias[0].id);
        setAddress({
          city_id: cpData.data?.cities[0].id,
          state_id: cpData.data?.states[0].id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (addressesData) {
      const filteredAddresses = addressesData.filter((address: Address) => address.code === "CDPA");
      setAddresType(filteredAddresses[0].id);
    }
  }, [addressesData, losdianA]);

  useEffect(() => {
    if (dataAddress?.length && shareholder.addressId) {
      const addressId = dataAddress.find((address) => address.id === shareholder.addressId);
      handleCp(String(addressId?.cp));
      formik.setFieldValue("cp", addressId?.cp);
      formik.setFieldValue("calle", addressId?.street);
      formik.setFieldValue("num_ext", addressId?.no_ext);
      formik.setFieldValue("num_int", addressId?.no_int);
      setTimeout(() => {
        formik.setFieldValue("colonia", addressId?.colonia_id);
      }, 500);
      setPatChAddresInfo(true);
    }
  }, [dataAddress, shareholder.addressId]);

  useEffect(() => {
    if (shareholder.id) {
      getAddres(shareholder.id);
    }
  }, [shareholder.id]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h4" fontWeight={"500"} align="center" sx={{ mt: 4, mb: 3 }} color={"#002652"}>
              Registro del accionista mayoritario
            </Typography>
            <ProgressBar progress={9} />

            <Typography variant="body2" align="center" sx={{ mt: 4, mb: 3 }} color={"#002652"}>
              Ingresa el domicilio del accionista mayoritario
            </Typography>
          </Container>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="cp"
                    name="cp"
                    label="Código Postal"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const cp = e.target.value.replace(/[^0-9]/g, "");
                      formik.setFieldValue("cp", cp);
                      if (cp.length === 5) {
                        handleCp(cp);
                      }
                    }}
                    error={!!cpError || !!formik.errors.cp}
                    helperText={cpError || (formik.touched.cp && formik.errors.cp)}
                    value={formik.values.cp}
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="calle"
                    name="calle"
                    label="Calle o avenida"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.calle && Boolean(formik.errors.calle)}
                    helperText={formik.touched.calle && formik.errors.calle}
                    value={formik.values.calle}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    required
                    sx={{
                      ...customStylesAsterisk,
                      ...customSelect,
                      mt: 2,
                    }}
                    error={formik.touched.colonia && Boolean(formik.errors.colonia)}
                  >
                    <InputLabel id="colonia">Colonia</InputLabel>
                    <Select
                      labelId="colonia"
                      id="colonia"
                      name="colonia"
                      fullWidth
                      required
                      label="Colonia"
                      value={formik.values.colonia}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.colonia && Boolean(formik.errors.colonia)}
                    >
                      <MenuItem value={""}>Selecciona</MenuItem>
                      {listColonias.map((colonia: { id: number; name: string }) => (
                        <MenuItem key={colonia.id} value={colonia.id}>
                          {colonia.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.colonia && formik.errors.colonia && (
                      <FormHelperText>{formik.errors.colonia}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <TextField
                    id="num_ext"
                    name="num_ext"
                    label="No. Exterior"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.num_ext && Boolean(formik.errors.num_ext)}
                    helperText={formik.touched.num_ext && formik.errors.num_ext}
                    value={formik.values.num_ext}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <TextField
                    id="num_int"
                    name="num_int"
                    label="No. Interior"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.num_int}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="ciudad"
                    name="ciudad"
                    label="Ciudad"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.ciudad && Boolean(formik.errors.ciudad)}
                    helperText={formik.touched.ciudad && formik.errors.ciudad}
                    value={formik.values.ciudad}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    id="municipio"
                    name="municipio"
                    label="Municipio"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.municipio && Boolean(formik.errors.municipio)}
                    helperText={formik.touched.municipio && formik.errors.municipio}
                    value={formik.values.municipio}
                    disabled
                  />
                </Grid>
              </Grid>

              <TextField
                id="entidad"
                name="entidad"
                label="Entidad federativa"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.entidad && Boolean(formik.errors.entidad)}
                helperText={formik.touched.entidad && formik.errors.entidad}
                value={formik.values.entidad}
                disabled
              />
              <Box display={"flex"} justifyContent={"center"} mt={3} mb={3}>
                <Button variant="default" type="submit" color="primary" disabled={!formik.dirty || !formik.isValid}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Grid>

        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#002652" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
