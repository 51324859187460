import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UploadModalError {
  visible: boolean;
  file_name: string;
}

export interface QuotationModal {
  visible: boolean;
}

export interface ErrosModal {
  visible: boolean;
  type: string;
}

export interface AppSlice {
  process_page: string;
  upload_modal_error: UploadModalError;
  modal_end_step_bofu: {
    visible: boolean;
  };
  modal_quotation: QuotationModal;
  modal_error: ErrosModal;
}

const initialState: AppSlice = {
  process_page: "",
  upload_modal_error: {
    visible: false,
    file_name: "",
  },
  modal_end_step_bofu: {
    visible: false,
  },
  modal_quotation: {
    visible: false,
  },
  modal_error: {
    visible: false,
    type: ""
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUploadModalErrorVisible: (state, action: PayloadAction<UploadModalError>) => {
      state.upload_modal_error = action.payload;
    },
    setModalEndStepBofuVisible: (state, action: PayloadAction<boolean>) => {
      state.modal_end_step_bofu.visible = action.payload;
    },
    setModalQuotationVisible: (state, action: PayloadAction<boolean>) => {
      state.modal_quotation.visible = action.payload;
    },
    setProcessPage: (state, action: PayloadAction<string>) => {
      state.process_page = action.payload;
    },
   
    setModalErrosVisible: (state, action: PayloadAction<{ open: boolean; type: string }>) => {
      state.modal_error.visible = action.payload.open;
      state.modal_error.type = action.payload.type;
    },
  },
});

export const {
  setUploadModalErrorVisible,
  setModalEndStepBofuVisible,
  setModalQuotationVisible,
  setProcessPage,
  setModalErrosVisible,
} = appSlice.actions;
export default appSlice.reducer;
