import { Box, BoxProps, styled, Tab, Tabs, TabsProps } from "@mui/material";
import { useState } from "react";

interface CustomTabsProps extends TabsProps {
  titleTabs: Array<string>;
  onTabChange: (tabNumber: number) => void;
}

interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  index?: number;
  active?: boolean;
}

const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  backgroundColor: "#A3D4E8",
  borderTopLeftRadius: "14px",
  borderTopRightRadius: "14px",
  "& .MuiButtonBase-root.MuiTab-root": {
    fontSize: "20px",
    fontWeight: 600,
    color: "#002652",
    borderRight: "2px solid #FFF",
    textTransform: "None",
  },
  "& .MuiButtonBase-root.MuiTab-root:has(+.Mui-selected)": {
    borderRight: "0px",
  },
  "& .MuiButtonBase-root.MuiTab-root:last-of-type": {
    borderRight: "0px",
  },
  "& .Mui-selected": {
    backgroundColor: "#002652",
    fontWeight: 600,
    fontSize: "20px",
    color: "#FFF !important",
    textTransform: "None",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    borderRight: "0px solid orange !important",
  },
}));

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, index, active, ...other } = props;

  return (
    <Box
      role={"tabpanel"}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      display={active ? "block" : "none"}
      height={"93%"}
      maxHeight={"93%"}
      {...other}
    >
      {children}
    </Box>
  );
};

export const CustomTabs = ({ titleTabs, onTabChange, children }: CustomTabsProps) => {
  const [value, setValue] = useState<number>(0);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleOnChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <>
      <StyledTabs value={value} variant="standard" onChange={handleOnChangeTab} aria-label="secondary tabs example">
        {titleTabs.map((title: string, index: number) => (
          <Tab key={index} label={title} {...a11yProps(index)} />
        ))}
      </StyledTabs>
      {children}
    </>
  );
};
