const countryCodes = [
//   { name: "Afghanistan", img: "af", code: "+93" },
//   { name: "Albania", img: "al", code: "+355" },
//   { name: "Algeria", img: "dz", code: "+213" },
//   { name: "Andorra", img: "ad", code: "+376" },
//   { name: "Angola", img: "ao", code: "+244" },
  { name: "Antigua and Barbuda", img: "ag", code: "+1268" },
  { name: "Argentina", img: "ar", code: "+54" },
//   { name: "Armenia", img: "am", code: "+374" },
//   { name: "Aruba", img: "aw", code: "+297" },
//   { name: "Australia", img: "au", code: "+61" },
//   { name: "Austria", img: "at", code: "+43" },
//   { name: "Azerbaijan", img: "az", code: "+994" },
//   { name: "Bahamas", img: "bs", code: "+1242" },
//   { name: "Bahrain", img: "bh", code: "+973" },
//   { name: "Bangladesh", img: "bd", code: "+880" },
  { name: "Barbados", img: "bb", code: "+1246" },
//   { name: "Belarus", img: "by", code: "+375" },
//   { name: "Belgium", img: "be", code: "+32" },
  { name: "Belize", img: "bz", code: "+501" },
//   { name: "Benin", img: "bj", code: "+229" },
//   { name: "Bhutan", img: "bt", code: "+975" },
  { name: "Bolivia", img: "bo", code: "+591" },
//   { name: "Bosnia and Herzegovina", img: "ba", code: "+387" },
//   { name: "Botswana", img: "bw", code: "+267" },
  { name: "Brazil", img: "br", code: "+55" },
//   { name: "British Indian Ocean Territory", img: "io", code: "+246" },
//   { name: "Brunei", img: "bn", code: "+673" },
//   { name: "Bulgaria", img: "bg", code: "+359" },
//   { name: "Burkina Faso", img: "bf", code: "+226" },
//   { name: "Burundi", img: "bi", code: "+257" },
//   { name: "Cambodia", img: "kh", code: "+855" },
//   { name: "Cameroon", img: "cm", code: "+237" },
//   { name: "Canada", img: "ca", code: "+1" },
//   { name: "Cape Verde", img: "cv", code: "+238" },
//   { name: "Caribbean Netherlands", img: "bq", code: "+599" },
//   { name: "Central African Republic", img: "cf", code: "+236" },
//   { name: "Chad", img: "td", code: "+235" },
//   { name: "Chile", img: "cl", code: "+56" },
//   { name: "China", img: "cn", code: "+86" },
  { name: "Colombia", img: "co", code: "+57" },
//   { name: "Comoros", img: "km", code: "+269" },
//   { name: "Congo", img: "cd", code: "+243" },
//   { name: "Congo", img: "cg", code: "+242" },
//   { name: "Costa Rica", img: "cr", code: "+506" },
//   { name: "Côte d’Ivoire", img: "ci", code: "+225" },
//   { name: "Croatia", img: "hr", code: "+385" },
  { name: "Cuba", img: "cu", code: "+53" },
//   { name: "Curaçao", img: "cw", code: "+599" },
//   { name: "Cyprus", img: "cy", code: "+357" },
//   { name: "Czech Republic", img: "cz", code: "+420" },
//   { name: "Denmark", img: "dk", code: "+45" },
//   { name: "Djibouti", img: "dj", code: "+253" },
//   { name: "Dominica", img: "dm", code: "+1767" },
  { name: "Dominican Republic", img: "do", code: "+1" },
  { name: "Ecuador", img: "ec", code: "+593" },
//   { name: "Egypt", img: "eg", code: "+20" },
  { name: "El Salvador", img: "sv", code: "+503" },
//   { name: "Equatorial Guinea", img: "gq", code: "+240" },
//   { name: "Eritrea", img: "er", code: "+291" },
//   { name: "Estonia", img: "ee", code: "+372" },
//   { name: "Ethiopia", img: "et", code: "+251" },
//   { name: "Fiji", img: "fj", code: "+679" },
//   { name: "Finland", img: "fi", code: "+358" },
  { name: "France", img: "fr", code: "+33" },
//   { name: "French Guiana", img: "gf", code: "+594" },
//   { name: "French Polynesia", img: "pf", code: "+689" },
//   { name: "Gabon", img: "ga", code: "+241" },
//   { name: "Gambia", img: "gm", code: "+220" },
//   { name: "Georgia", img: "ge", code: "+995" },
//   { name: "Germany", img: "de", code: "+49" },
//   { name: "Ghana", img: "gh", code: "+233" },
//   { name: "Greece", img: "gr", code: "+30" },
//   { name: "Grenada", img: "gd", code: "+1473" },
//   { name: "Guadeloupe", img: "gp", code: "+590" },
//   { name: "Guam", img: "gu", code: "+1671" },
  { name: "Guatemala", img: "gt", code: "+502" },
//   { name: "Guinea", img: "gn", code: "+224" },
//   { name: "Guinea-Bissau", img: "gw", code: "+245" },
//   { name: "Guyana", img: "gy", code: "+592" },
//   { name: "Haiti", img: "ht", code: "+509" },
  { name: "Honduras", img: "hn", code: "+504" },
//   { name: "Hong Kong", img: "hk", code: "+852" },
//   { name: "Hungary", img: "hu", code: "+36" },
//   { name: "Iceland", img: "is", code: "+354" },
//   { name: "India", img: "in", code: "+91" },
//   { name: "Indonesia", img: "id", code: "+62" },
//   { name: "Iran", img: "ir", code: "+98" },
//   { name: "Iraq", img: "iq", code: "+964" },
//   { name: "Ireland", img: "ie", code: "+353" },
//   { name: "Israel", img: "il", code: "+972" },
//   { name: "Italy", img: "it", code: "+39" },
  { name: "Jamaica", img: "jm", code: "+1876" },
//   { name: "Japan", img: "jp", code: "+81" },
//   { name: "Jordan", img: "jo", code: "+962" },
//   { name: "Kazakhstan", img: "kz", code: "+7" },
//   { name: "Kenya", img: "ke", code: "+254" },
//   { name: "Kiribati", img: "ki", code: "+686" },
//   { name: "Kosovo", img: "xk", code: "+383" },
//   { name: "Kuwait", img: "kw", code: "+965" },
//   { name: "Kyrgyzstan", img: "kg", code: "+996" },
//   { name: "Laos", img: "la", code: "+856" },
//   { name: "Latvia", img: "lv", code: "+371" },
//   { name: "Lebanon", img: "lb", code: "+961" },
//   { name: "Lesotho", img: "ls", code: "+266" },
//   { name: "Liberia", img: "lr", code: "+231" },
//   { name: "Libya", img: "ly", code: "+218" },
//   { name: "Liechtenstein", img: "li", code: "+423" },
//   { name: "Lithuania", img: "lt", code: "+370" },
//   { name: "Luxembourg", img: "lu", code: "+352" },
//   { name: "Macau", img: "mo", code: "+853" },
//   { name: "Macedonia", img: "mk", code: "+389" },
//   { name: "Madagascar", img: "mg", code: "+261" },
//   { name: "Malawi", img: "mw", code: "+265" },
//   { name: "Malaysia", img: "my", code: "+60" },
//   { name: "Maldives", img: "mv", code: "+960" },
//   { name: "Mali", img: "ml", code: "+223" },
//   { name: "Malta", img: "mt", code: "+356" },
//   { name: "Marshall Islands", img: "mh", code: "+692" },
//   { name: "Martinique", img: "mq", code: "+596" },
//   { name: "Mauritania", img: "mr", code: "+222" },
//   { name: "Mauritius", img: "mu", code: "+230" },
  { name: "Mexico", img: "mx", code: "+52" },
//   { name: "Micronesia", img: "fm", code: "+691" },
//   { name: "Moldova", img: "md", code: "+373" },
//   { name: "Monaco", img: "mc", code: "+377" },
//   { name: "Mongolia", img: "mn", code: "+976" },
//   { name: "Montenegro", img: "me", code: "+382" },
//   { name: "Morocco", img: "ma", code: "+212" },
//   { name: "Mozambique", img: "mz", code: "+258" },
//   { name: "Myanmar", img: "mm", code: "+95" },
//   { name: "Namibia", img: "na", code: "+264" },
//   { name: "Nauru", img: "nr", code: "+674" },
//   { name: "Nepal", img: "np", code: "+977" },
//   { name: "Netherlands", img: "nl", code: "+31" },
//   { name: "New Caledonia", img: "nc", code: "+687" },
//   { name: "New Zealand", img: "nz", code: "+64" },
  { name: "Nicaragua", img: "ni", code: "+505" },
//   { name: "Niger", img: "ne", code: "+227" },
//   { name: "Nigeria", img: "ng", code: "+234" },
//   { name: "North Korea", img: "kp", code: "+850" },
//   { name: "Norway", img: "no", code: "+47" },
//   { name: "Oman", img: "om", code: "+968" },
//   { name: "Pakistan", img: "pk", code: "+92" },
//   { name: "Palau", img: "pw", code: "+680" },
//   { name: "Palestine", img: "ps", code: "+970" },
//   { name: "Panama", img: "pa", code: "+507" },
//   { name: "Papua New Guinea", img: "pg", code: "+675" },
  { name: "Paraguay", img: "py", code: "+595" },
  { name: "Peru", img: "pe", code: "+51" },
//   { name: "Philippines", img: "ph", code: "+63" },
//   { name: "Poland", img: "pl", code: "+48" },
//   { name: "Portugal", img: "pt", code: "+351" },
  { name: "Puerto Rico", img: "pr", code: "+1" },
//   { name: "Qatar", img: "qa", code: "+974" },
//   { name: "Réunion", img: "re", code: "+262" },
//   { name: "Romania", img: "ro", code: "+40" },
//   { name: "Russia", img: "ru", code: "+7" },
//   { name: "Rwanda", img: "rw", code: "+250" },
//   { name: "Saint Kitts and Nevis", img: "kn", code: "+1869" },
//   { name: "Saint Lucia", img: "lc", code: "+1758" },
//   { name: "Saint Vincent and the Grenadines", img: "vc", code: "+1784" },
//   { name: "Samoa", img: "ws", code: "+685" },
//   { name: "San Marino", img: "sm", code: "+378" },
//   { name: "São Tomé and Príncipe", img: "st", code: "+239" },
//   { name: "Saudi Arabia", img: "sa", code: "+966" },
//   { name: "Senegal", img: "sn", code: "+221" },
//   { name: "Serbia", img: "rs", code: "+381" },
//   { name: "Seychelles", img: "sc", code: "+248" },
//   { name: "Sierra Leone", img: "sl", code: "+232" },
//   { name: "Singapore", img: "sg", code: "+65" },
//   { name: "Slovakia", img: "sk", code: "+421" },
//   { name: "Slovenia", img: "si", code: "+386" },
//   { name: "Solomon Islands", img: "sb", code: "+677" },
//   { name: "Somalia", img: "so", code: "+252" },
//   { name: "South Africa", img: "za", code: "+27" },
//   { name: "South Korea", img: "kr", code: "+82" },
//   { name: "South Sudan", img: "ss", code: "+211" },
  { name: "Spain", img: "es", code: "+34" },
//   { name: "Sri Lanka", img: "lk", code: "+94" },
//   { name: "Sudan", img: "sd", code: "+249" },
//   { name: "Suriname", img: "sr", code: "+597" },
//   { name: "Swaziland", img: "sz", code: "+268" },
//   { name: "Sweden", img: "se", code: "+46" },
//   { name: "Switzerland", img: "ch", code: "+41" },
//   { name: "Syria", img: "sy", code: "+963" },
//   { name: "Taiwan", img: "tw", code: "+886" },
//   { name: "Tajikistan", img: "tj", code: "+992" },
//   { name: "Tanzania", img: "tz", code: "+255" },
//   { name: "Thailand", img: "th", code: "+66" },
//   { name: "Timor-Leste", img: "tl", code: "+670" },
//   { name: "Togo", img: "tg", code: "+228" },
//   { name: "Tonga", img: "to", code: "+676" },
//   { name: "Trinidad and Tobago", img: "tt", code: "+1868" },
//   { name: "Tunisia", img: "tn", code: "+216" },
//   { name: "Turkey", img: "tr", code: "+90" },
//   { name: "Turkmenistan", img: "tm", code: "+993" },
//   { name: "Tuvalu", img: "tv", code: "+688" },
//   { name: "Uganda", img: "ug", code: "+256" },
//   { name: "Ukraine", img: "ua", code: "+380" },
  { name: "United Arab Emirates", img: "ae", code: "+971" },
  { name: "United Kingdom", img: "gb", code: "+44" },
  { name: "United States", img: "us", code: "+1" },
  { name: "Uruguay", img: "uy", code: "+598" },
//   { name: "Uzbekistan", img: "uz", code: "+998" },
//   { name: "Vanuatu", img: "vu", code: "+678" },
//   { name: "Vatican City", img: "va", code: "+39" },
  { name: "Venezuela", img: "ve", code: "+58" },
//   { name: "Vietnam", img: "vn", code: "+84" },
//   { name: "Yemen", img: "ye", code: "+967" },
//   { name: "Zambia", img: "zm", code: "+260" },
//   { name: "Zimbabwe", img: "zw", code: "+263" },
];

export default countryCodes;