import React, { useEffect, useState } from "react";
import { Box, Button, Menu, SxProps, Typography } from "@mui/material";
import icon_menu_arrow_down from "@assets/img/icon_menu_arrow_down.svg";
import dayjs from "dayjs";
dayjs.locale("es");

const monthList = [
  { id: 1, name: "Ene" },
  { id: 2, name: "Feb" },
  { id: 3, name: "Mar" },
  { id: 4, name: "Abr" },
  { id: 5, name: "May" },
  { id: 6, name: "Jun" },
  { id: 7, name: "Jul" },
  { id: 8, name: "Ago" },
  { id: 9, name: "Sep" },
  { id: 10, name: "Oct" },
  { id: 11, name: "Nov" },
  { id: 12, name: "Dic" },
];

const styleMenu: SxProps = {
  fontFamily: "Outfit",
  fontWeight: 300,
  fontSize: "16px",
  lineHeight: "19.5px",
  color: "#000000",
  border: "1px solid #A3D4E8",
  width: "160px",
  height: "45px",
};

const styleTitle: SxProps = {
  fontFamily: "Outfit",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "19.5px",
  textAlign: "center",
  color: "#528CD6",
};

const styleItem: SxProps = {
  fontFamily: "Outfit",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "19.5px",
  textAlign: "center",
  color: "#002652",
  cursor: "pointer",
};

const styleItemsContainer: SxProps = {
  display: "grid",
  gridTemplateColumns: "auto auto auto",
  gridTemplateRows: "auto auto auto",
  gap: "20px",
  width: "160px",
  minHeight: "100px",
  padding: "20px",
};

export interface YearMonth {
  year: number | null;
  month: number | null;
}

export interface FilterMonthYearProps {
  onChange: (year: number, month: number) => void;
  lastSelection?: YearMonth;
}

const FilterMonthYear = (props: FilterMonthYearProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [yearSelected, setYearSelected] = useState<number | null>(null);
  const [yearList, setYearList] = useState<number[]>([]);
  const [lastSelection, setLastSelection] = useState<YearMonth | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectYear = (year: number) => {
    setYearSelected(year);
  };

  const handleSelectMonth = (month: number) => {
    if (!yearSelected) {
      return;
    }

    setLastSelection({
      year: yearSelected,
      month: month,
    });
    setAnchorEl(null);
    setYearSelected(null);
    props.onChange(yearSelected, month);
  };

  const lastSelectionToText = () => {
    if (lastSelection) {
      if (lastSelection.year && lastSelection.month) {
        const month = monthList.find((m) => m.id === lastSelection.month);
        if (month) {
          return `${month.name} - ${lastSelection.year}`;
        }
      }
    }

    return "Selecciona";
  };

  useEffect(() => {
    (() => {
      const result: number[] = [];
      const today_date = dayjs();
      let end_year = +today_date.format("YYYY");
      let start_year = +today_date.subtract(5, "year").format("YYYY");

      if (start_year < 2024) {
        start_year = 2024;
      }

      for (let i = start_year; i <= end_year; i++) {
        result.push(i);
      }

      setYearList(result);
    })();
  }, []);

  useEffect(() => {
    if (props.lastSelection) {
      setLastSelection(props.lastSelection);
    } else {
      setLastSelection(null);
    }
  }, [props.lastSelection]);

  return (
    <Box>
      <Button
        id="positioned-button-contact"
        aria-controls={open ? "positioned-menu-contact" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={styleMenu}
      >
        <Box display="flex" flexDirection="row" gap={1}>
          {lastSelectionToText()}
          <img src={icon_menu_arrow_down} alt="menu-arrow-down" />
        </Box>
      </Button>
      <Menu
        id="positioned-menu-contact"
        aria-labelledby="positioned-button-contact"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!yearSelected && (
          <Box maxWidth={"200px"} paddingTop={"10px"}>
            <Typography sx={styleTitle}>Selecciona el año</Typography>
            <Box sx={styleItemsContainer}>
              {yearList.map((year) => (
                <Typography key={year} sx={styleItem} onClick={() => handleSelectYear(year)}>
                  {year}
                </Typography>
              ))}
            </Box>
          </Box>
        )}

        {yearSelected && (
          <Box maxWidth={"200px"} paddingTop={"10px"}>
            <Typography sx={styleTitle}>Selecciona el mes</Typography>
            <Box sx={styleItemsContainer}>
              {monthList.map((month) => (
                <Typography key={month.id} sx={styleItem} onClick={() => handleSelectMonth(month.id)}>
                  {month.name}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default FilterMonthYear;
