/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import { CustomUploadFile } from "@components/CustomUploadFile";

import { UploadedFile } from "@interfaces/store";
import { RootState, useAppDispatch } from "@store/store";

import { setModalErrosVisible } from "@store/slices/appSlice";
import { removeGuaranteeDocument, setCompleteInfoGuarantee, setGuarenteeDocument } from "@store/slices/bofuSlice";
import CloseIcon from "@mui/icons-material/Close";
import img from "@assets/img/img_tofu/img_tofu_9.svg";
import tol from "@assets/img/tooltip.svg";

import { UUID } from "crypto";
import {
  useLazyGetPersonByIdQuery,
  useLazyGetPersonByTypePersonIdQuery,
  usePatchPersonDataMutation,
} from "@api/personasApi";
import { useLazyGetDocumentPersonatIdQuery, usePostDocumentGenarateMutation } from "@api/documents";
import { Spinner } from "@components/Spinner";
import { TabStepBofuPm } from "@components/index";
import { PersonaStructure } from "@interfaces/index";
import { useGetPersonTypeQuery, useGetStatusQuery } from "@api/catalogs";
import { usePutApplicationUpdateStatusMutation } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";

interface PropsButtoms {
  isFilled: boolean;
  buttonLabel: string;
  backgroundColor: string;
  color: string;
}

export const DocsAval = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [validations, setValidations] = useState<yup.ObjectShape>({});

  const shareholder = useSelector((state: RootState) => state.register.shareholder);

  const applicationData = useSelector((state: RootState) => state.register.application);
  const guaranteeSection = useSelector((state: RootState) => state.bofu.digital_file?.guarantee);
  const person = useSelector((state: RootState) => state.register.personal_data);
  const aval = useSelector((state: RootState) => state.register.shareholder);

  const [getPersona, { data: dataPerson, isSuccess }] = useLazyGetPersonByIdQuery();

  const [getDocumentByPersonaId, { data: dataDocuments, isLoading: loadingDocumentData }] =
    useLazyGetDocumentPersonatIdQuery();

  const [getPropietario, { data: dataPropietario, isSuccess: isSuccessPropietario }] =
    useLazyGetPersonByTypePersonIdQuery();

  const { data: personTypeData, isSuccess: typePersonSucess } = useGetPersonTypeQuery();

  const [personTypeId, setPersonTypeId] = useState("");

  const [listPropietarios, setPropietarios] = useState<PersonaStructure[]>([]);
  const [hasPropietario, setHasPropietario] = useState(false);

  const [indetidadCompleted, setIndetidadCompleted] = useState<any>(null);
  const [bankCompleted, setBankCompleted] = useState<any>(null);
  const [dataAvalCompleted, setDataAvalCompleted] = useState<any>(null);
  const [documentValidate, setDocumentValidate] = useState<string | null>(null);
  const [patchPersonData] = usePatchPersonDataMutation();
  const [visible, setVisible] = useState(false);

  const [allAvalCompletedStatus, setAllAvalCompletedStatus] = useState("");

  const [dataAvalProps, setDataAvalProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: "#528CD6",
    color: "#FFFFFF",
  });
  const [identidadProps, setIdentidadProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: "#528CD6",
    color: "#FFFFFF",
  });
  const [bancariaProps, setBancariaProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: "#528CD6",
    color: "#FFFFFF",
  });

  const [open, setOpen] = useState(false);

  const [createDocument, { isLoading: loadingDocument }] = usePostDocumentGenarateMutation();
  const [statusValidacionId, setStatusValidacionId] = useState("");
  const { data: dataStatus, isSuccess: isSuccessStatus } = useGetStatusQuery();
  const [upDateStatus] = usePutApplicationUpdateStatusMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object().shape(validations),
    onSubmit: async () => {
      if (hasPropietario) {
        navigate("/producto/creditosimple/propietario_real_pm");
      } else {
        try {
          const data = {
            status_id: statusValidacionId,
          };
          await upDateStatus({ id_sol: applicationData.id, body: data }).unwrap();
          navigate("/producto/creditosimple/validando_expediente_pm");
        } catch (error: any) {
          if (error?.status === 500 || error?.status === 409) {
            dispatch(setModalErrosVisible({ open: true, type: "500" }));
            return;
          } else {
            dispatch(setModalErrosVisible({ open: true, type: "" }));
            return;
          }
        }
      }
    },
  });

  const registerFormik = (name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
  };

  useEffect(() => {
    if (personTypeData) {
      const resultPropietario = personTypeData.find((item) => item.code === "PTPR");
      setPersonTypeId(String(resultPropietario?.id));
    }
  }, [personTypeData, typePersonSucess]);

  useEffect(() => {
    if (personTypeId && applicationData) {
      getPropietario({ applicationId: applicationData.id, personId: personTypeId });
    }
  }, [personTypeId, applicationData]);

  useEffect(() => {
    if (dataPropietario) {
      setPropietarios(dataPropietario?.data?.data);
    }
  }, [dataPropietario, isSuccessPropietario]);

  useEffect(() => {
    if (listPropietarios.length !== 0) {
      setHasPropietario(true);
    }
  }, [listPropietarios]);

  useEffect(() => {
    if (dataStatus) {
      const statusExpediente = dataStatus.find((item) => item.code === "CSEDC");
      setStatusValidacionId(String(statusExpediente?.id));
    }
  }, [dataStatus, isSuccessStatus]);

  useEffect(() => {
    if (aval.id) {
      getPersona(aval.id);
    }
  }, [aval.id]);

  useEffect(() => {
    if (aval.id) {
      getDocumentByPersonaId({ loanApplicationId: applicationData.id, personaId: aval.id });
    }
  }, [aval.id]);

  useEffect(() => {
    if (dataPerson) {
      setIndetidadCompleted(dataPerson.data?.identity_validation_completed);
      setBankCompleted(dataPerson.data?.bank_references_completed);
      setDataAvalCompleted(dataPerson.data?.information_person_completed);
      setAllAvalCompletedStatus(`${dataPerson.data?.completed}`)

      if (
        dataPerson.data?.identity_validation_completed &&
        dataPerson.data?.bank_references_completed &&
        dataPerson.data?.information_person_completed &&
        dataPerson.data?.completed
      ) {
        formik.setFieldValue("isAllFilled", true);
        dispatch(setCompleteInfoGuarantee());
      }
    }
  }, [dataPerson, isSuccess]);

  const handleUpdateStore = (action: any, _: number, uploadedFile: UploadedFile | null, fileInternalId: UUID) => {
    if (action === "ADD" && uploadedFile !== null) {
      dispatch(setGuarenteeDocument(uploadedFile));
    } else if (action === "DEL") {
      dispatch(removeGuaranteeDocument(fileInternalId));
    }
  };

  useEffect(() => {
    if (!dataDocuments) return;

    const filteredFiles = dataDocuments.filter((file) => file.file_name.includes("anexo_1"));
    const validatedFile = filteredFiles.find((item) => item.status);

    if (filteredFiles.length === 0 || validatedFile === null || validatedFile === undefined) {
      setDocumentValidate("null");
    } else {
      setDocumentValidate(`${validatedFile?.validated}`);
    }

    const allCompleted = dataAvalCompleted && indetidadCompleted && bankCompleted;

    if (allCompleted) {
      if (filteredFiles.length === 0) {
        generateSolicitudDocument();
      }
    }
  }, [dataDocuments, dataAvalCompleted, indetidadCompleted, bankCompleted]);

  const getButtonProps = (status: boolean | any, document: string | any) => {
    if (document === "null") {
      if (status === null) {
        return {
          isFilled: false,
          buttonLabel: "Llenar",
          backgroundColor: "#528CD6",
          color: "#FFFFFF",
        };
      }

      if (!status) {
        return {
          isFilled: false,
          buttonLabel: "Editar",
          backgroundColor: "#F2704F",
          color: "#FFFFFF",
        };
      }

      if (status) {
        return {
          isFilled: true,
          buttonLabel: "Completo",
          backgroundColor: "#002652",
          color: "#A3D4E8",
        };
      }
    }

    if (document === "false") {
      if (status === null) {
        return {
          isFilled: false,
          buttonLabel: "Llenar",
          backgroundColor: "#528CD6",
          color: "#FFFFFF",
        };
      }

      if (!status) {
        return {
          isFilled: false,
          buttonLabel: "Editar",
          backgroundColor: "#F2704F",
          color: "#FFFFFF",
        };
      }

      if (status) {
        return {
          isFilled: true,
          buttonLabel: "Completo",
          backgroundColor: "#002652",
          color: "#A3D4E8",
        };
      }
    }

    if (document === "true" && status) {
      return {
        isFilled: true,
        buttonLabel: "Validado",
        backgroundColor: "#A3D4E8",
        color: "#528CD6",
      };
    }

    return { isFilled: false, buttonLabel: "Llenar", backgroundColor: "#528CD6", color: "#FFFFFF" };
  };

  useEffect(() => {
    if (dataPerson && !loadingDocumentData && isSuccess) {
      setDataAvalProps(getButtonProps(dataAvalCompleted, documentValidate));
      setBancariaProps(getButtonProps(bankCompleted, documentValidate));
      setIdentidadProps(getButtonProps(indetidadCompleted, documentValidate));
    }
  }, [
    dataAvalCompleted,
    bankCompleted,
    indetidadCompleted,
    documentValidate,
    dataPerson,
    loadingDocumentData,
    isSuccess,
  ]);

  useEffect(() => {
    if (!loadingDocumentData && isSuccess) {
      setVisible(true);
    }
  }, [loadingDocumentData, isSuccess]);

  const generateSolicitudDocument = async () => {
    try {
      const data = {
        loan_application_id: String(applicationData.id),
        document_type: "anexo_1",
        person_id: String(shareholder.id),
      };

      const documents: any = await createDocument(data);

      if (documents?.error?.status === 500 || documents?.error?.status === 404) {
        throw new Error(JSON.stringify({ status: 500 }));
      }

      if (documents?.error?.status === 400) {
        throw new Error(JSON.stringify({ status: 400 }));
      }
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  const updateStatusComplete = async () => {
    try {
      const data = {
        completed: null,
        id: String(aval.id),
      };

      await patchPersonData(data).unwrap();
    } catch (error: any) {
      const parsedError = JSON.parse(error.message || "{}");
      if (parsedError.status === 500) {
        dispatch(setModalErrosVisible({ open: true, type: "500" }));
        return;
      } else {
        dispatch(setModalErrosVisible({ open: true, type: "" }));
      }
    }
  };

  const [key, setKey] = useState(0);

  useEffect(() => {
    if (allAvalCompletedStatus === "false") {
      if (dataAvalCompleted && bankCompleted && indetidadCompleted) {
        updateStatusComplete();
        generateSolicitudDocument();
        setKey((prevKey) => prevKey + 1);
      }
    }
  }, [
    dataAvalCompleted,
    bankCompleted,
    indetidadCompleted,
    documentValidate,
    dataPerson,
    loadingDocumentData,
    isSuccess,
    allAvalCompletedStatus
  ]);

  return (
    <>
      <Grid container>
        <Spinner open={loadingDocument} />
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600}>
              Expediente digital
            </Typography>

            <TabStepBofuPm key={key} />

            <Typography fontSize={"15px"} fontWeight={600} color={"#002652"} align="center" mt={5} mb={4}>
              Ahora es momento de llenar los formatos relacionados al aval <img src={tol} alt="" onClick={handleOpen} />
            </Typography>

            <Typography fontSize={"14px"} fontWeight={400} color={"#528CD6"} align="center" mt={5} mb={4}>
              {`${shareholder.name} ${shareholder.first_lastname} ${shareholder.second_lastname}`}
            </Typography>

            <Container>
              {!visible ? (
                <>
                  <Skeleton variant="text" animation="wave" width={"100%"} />
                  <Skeleton variant="text" animation="wave" width={"100%"} />
                  <Skeleton variant="text" animation="wave" width={"100%"} />
                  <Skeleton variant="text" animation="wave" width={"100%"} />
                </>
              ) : (
                <Box>
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                      Información del aval
                    </Typography>
                    <Button
                      variant="default"
                      onClick={() => navigate("/producto/creditosimple/informacion_datos_aval")}
                      disabled={dataAvalProps?.isFilled}
                      sx={{
                        width: "160px",
                        fontSize: "15px",
                        height: "30px",
                        paddingTop: 1,
                        backgroundColor: dataAvalProps?.backgroundColor,
                        color: dataAvalProps?.color,
                        "&:hover": {
                          backgroundColor: dataAvalProps?.backgroundColor,
                        },
                        "&.Mui-disabled": {
                          backgroundColor: dataAvalProps?.backgroundColor,
                          color: dataAvalProps?.color,
                          opacity: 1,
                        },
                      }}
                    >
                      {dataAvalProps?.buttonLabel}
                    </Button>
                  </Box>

                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                      Validación de identidad
                    </Typography>
                    <Button
                      variant="default"
                      onClick={() => navigate("/producto/creditosimple/validacion_identidad_aval")}
                      disabled={identidadProps?.isFilled}
                      sx={{
                        width: "160px",
                        fontSize: "15px",
                        height: "30px",
                        paddingTop: 1,
                        backgroundColor: identidadProps?.backgroundColor,
                        color: identidadProps?.color,
                        "&:hover": {
                          backgroundColor: identidadProps?.backgroundColor,
                        },
                        "&.Mui-disabled": {
                          backgroundColor: identidadProps?.backgroundColor,
                          color: identidadProps?.color,
                          opacity: 1,
                        },
                      }}
                    >
                      {identidadProps?.buttonLabel}
                    </Button>
                  </Box>
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }}>
                      Información bancaria
                    </Typography>
                    <Button
                      variant="default"
                      onClick={() => navigate("/producto/creditosimple/info_bancaria_aval")}
                      disabled={bancariaProps?.isFilled}
                      sx={{
                        width: "160px",
                        fontSize: "15px",
                        height: "30px",
                        paddingTop: 1,
                        backgroundColor: bancariaProps?.backgroundColor,
                        color: bancariaProps?.color,
                        "&:hover": {
                          backgroundColor: bancariaProps?.backgroundColor,
                        },
                        "&.Mui-disabled": {
                          backgroundColor: bancariaProps?.backgroundColor,
                          color: bancariaProps?.color,
                          opacity: 1,
                        },
                      }}
                    >
                      {bancariaProps?.buttonLabel}
                    </Button>
                  </Box>
                </Box>
              )}

              <Typography fontSize={"15px"} fontWeight={600} color={"#002652"} align="center" mt={5} mb={4}>
                Ahora carga los documentos relacionados al aval
              </Typography>

              <form onSubmit={formik.handleSubmit} noValidate>
                <CustomUploadFile
                  py={2}
                  updateStore={handleUpdateStore}
                  uploadedFile={guaranteeSection.files?.find((uploadedFile) => uploadedFile.file_code == "DIO")}
                  optionalDescription="Vigente, puede ser INE, Pasaporte o MF2"
                  registerFormik={registerFormik}
                  setFieldValue={formik.setFieldValue}
                  errors={formik.errors}
                  metadata={{
                    taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
                    personCode: "PTACAV",
                    fileCode: "DIO",
                    uploadType: "person",
                    loan_application_id: applicationData.id,
                    regimen: applicationData.type,
                    person_id: shareholder.id,
                    clientDetailsId: person.id,
                    personType: "accionista_mayotiario",
                  }}
                />

                <CustomUploadFile
                  py={2}
                  updateStore={handleUpdateStore}
                  uploadedFile={guaranteeSection.files?.find((uploadedFile) => uploadedFile.file_code == "DCDF")}
                  optionalDescription="Deberá estar actualizada"
                  registerFormik={registerFormik}
                  setFieldValue={formik.setFieldValue}
                  errors={formik.errors}
                  metadata={{
                    taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
                    personCode: "PTACAV",
                    fileCode: "DCDF",
                    uploadType: "person",
                    loan_application_id: applicationData.id,
                    regimen: applicationData.type,
                    person_id: shareholder.id,
                    clientDetailsId: person.id,
                    personType: "accionista_mayotiario",
                  }}
                />

                <CustomUploadFile
                  py={2}
                  updateStore={handleUpdateStore}
                  uploadedFile={guaranteeSection.files?.find((uploadedFile) => uploadedFile.file_code === "DCD")}
                  optionalDescription="No deberá ser mayor a 3 meses"
                  registerFormik={registerFormik}
                  setFieldValue={formik.setFieldValue}
                  errors={formik.errors}
                  metadata={{
                    taxCode: import.meta.env.VITE_CODE_TAXS_CS_PM,
                    personCode: "PTACAV",
                    fileCode: "DCD",
                    uploadType: "person",
                    loan_application_id: applicationData.id,
                    regimen: applicationData.type,
                    person_id: shareholder.id,
                    clientDetailsId: person.id,
                    personType: "accionista_mayotiario",
                  }}
                />

                <Box display="flex" justifyContent="center" mt={2} mb={4}>
                  <Button
                    sx={{ width: "130px" }}
                    type="submit"
                    variant="default"
                    disabled={!formik.isValid || !(Object.keys(formik.errors).length === 0)}
                  >
                    Continuar
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <img
                src={img}
                alt=""
                style={{
                  height: "calc(100vh - 70px)",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "250px", sm: "300px", md: "60vw", lg: "40vw" }, // Ajusta el ancho en diferentes pantallas
            maxWidth: 500, // Ancho máximo
            bgcolor: "background.paper",
            border: "2px solid #002652",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#76A1FF",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography id="modal-title" variant="h6" component="h2" fontWeight={600} color="#002652">
            Aval
          </Typography>

          <Typography id="modal-description" sx={{ mt: 2, color: "#002652" }}>
            Es el accionista mayoritario de la empresa, que a su vez, será la persona encargada de asegurar que se
            saldará la deuda.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
