import { useRef } from "react";
// import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";

import Chart from "chart.js/auto";
import { CategoryScale, ChartData, ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

// import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";
// import { ContractProps } from "@interfaces/contract";
// import { formatCurrency, formatDateToLargeDate } from "@helpers/index";
// import { useLazyGetLoansQuery } from "@api/accountData";
// import FlowerImg from "@assets/img/flower.svg";
import { useEffect } from "react";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);
Chart.overrides.doughnut.plugins.legend.display = false;

export interface ContractChartProps {
  index: number;
  data: {
    months_paid: number;
    months_due: number;
  };
};

export const ContractChart = (props: ContractChartProps) => {
  const chartRef = useRef<Chart<"doughnut"> | null>(null);
  const data: ChartData<"doughnut"> = {
    labels: ["Mensualidades\npagadas", "Mensualidades\npendientes"],
    datasets: [
      {
        data: [1, 1],
        backgroundColor: ["#528CD6", "#E3F2F8"],
        hoverOffset: 4,
        borderColor: ["#528CD6", "#A3D4E8"],
        datalabels: {
          labels: {
            name: {
              anchor: "end",
              backgroundColor: "rgba(255, 255, 255, .8)",
              borderColor: "#f0f0f0",
              borderRadius: 8,
              borderWidth: 2,
              display: true,
              formatter: (_: unknown, ctx: any) => {
                return ctx.chart.data.labels[ctx.dataIndex];
              },
              padding: {
                top: 30,
                left: 10,
                right: 10,
                bottom: 5,
              },
              font: {
                family: "Outfit",
                size: 13,
              },
              textAlign: "center",
              align: "bottom",
              offset: -6,
            },
            value: {
              anchor: "end",
              color: "#528CD6",
              font: {
                family: "Outfit",
                size: 16,
                weight: "bold",
              },
              display: true,
            },
          },
        },
      },
    ],
  };
  const options: ChartOptions<"doughnut"> = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    layout: {
      padding: 55,
    },
    cutout: "80%",
    animation: false,
  };

  useEffect(() => {
    if (chartRef.current) {
      console.log("Actualizando gráfica", props.data);
      let copyData = { ...data };
      copyData.datasets[0].data = [props.data.months_paid, props.data.months_due];
      chartRef.current.data = copyData;
      chartRef.current.update();
    }
  }, [props.data]);

  return <Doughnut id={`grafica_numero_${props.index}`} ref={chartRef} data={data} options={options} />;
};
