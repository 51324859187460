import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
// import { RootState } from '../redux/store';

export const baseApiSatCore = createApi({
    reducerPath: 'satCore',
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_URL_API_SAT_CORE,
        // prepareHeaders: (headers:Headers, {getState}) => {
        //     const state = getState() as RootState;

        //     const token = state.auth.accessToken;
        //     headers.set("authorization", `Bearer ${token}`);

        //     return headers;
        // }
    }),
    endpoints: () => ({})
})